import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Evaluation } from '../models/evaluation';
import { AnswerEvaluation } from '../models/answerEvaluation';

@Injectable({
  providedIn: 'root',
})
export class DamageAssessmentService {
  constructor(private http: HttpClient) {}

  getEvaluation(tenantName: string, serverName: string, type: number) {
    return this.http.get<Evaluation>(
      `${environment.EvaluationV3.EvaluationUrl}next?tenant=${tenantName}&source=${serverName}&type=${type}`
    );
  }

  getPrevious(tenantName: string, serverName: string, type: number) {
    return this.http.get<Evaluation>(
      `${environment.EvaluationV3.EvaluationUrl}last?tenant=${tenantName}&source=${serverName}&type=${type}`
    );
  }

  sendEvaluation(answer: AnswerEvaluation) {
    return this.http.post<Evaluation>(
      `${environment.EvaluationV3.EvaluationUrl}evaluate`,
      answer
    );
  }
}
