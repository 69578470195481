import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { TenantService } from 'src/app/_services/tenant.service';
import { AnswerEvaluation } from '../../models/answerEvaluation';
import { Evaluation } from '../../models/evaluation';
import { Tenant } from 'src/app/_models/tenant';
import {
  Observable,
  Subject,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
} from 'rxjs';
import { Router } from '@angular/router';
import { TokenService } from '../../../_services/token.service';
import { RemediationMeasuresService } from '../../services/remediation-measures.service';
import { EvaluationCatalog } from '../../models/evaluationCatalog';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';

enum MeasurePage {
  Main = 'Main',
  Catalog = 'Catalog',
}

@Component({
  selector: 'app-remediation-measures',
  templateUrl: './remediation-measures.component.html',
  styleUrls: ['./remediation-measures.component.scss'],
})
export class RemediationMeasuresComponent implements OnInit, AfterViewInit {
  @ViewChild('zoomWrapper') zoomWrapper!: ElementRef;
  @ViewChildren('zoomWrapper') zoomWrapperList!: QueryList<any>;
  @ViewChild('zoomWrapperContainer') zoomWrapperContainer!: ElementRef;
  @ViewChild('damageScale') damageScale?: ElementRef;
  MeasurePage = MeasurePage;
  hoverScaleMobile = '';
  answer: AnswerEvaluation | undefined;
  evaluation?: Evaluation;
  tenant: Tenant | undefined;
  commentar = '';
  isPrevious = false;
  isLoading = true;
  isInvalid = false;
  isFinished = false;
  isNoData = false;
  symbolsRemains = 300;
  commentarValidation = new Subject<string>();
  selectedPage: MeasurePage | string = '';
  catalogItems: EvaluationCatalog[] = [];
  catalogItemsFavorite: EvaluationCatalog[] = [];
  catalogItemsOther: EvaluationCatalog[] = [];
  form: FormGroup;
  ratio = 4 / 3;

  constructor(
    private remediationMeasuresService: RemediationMeasuresService,
    private tenantService: TenantService,
    private router: Router,
    private tokenStorageService: TokenService,
    private formBuilder: FormBuilder
  ) {
    this.form = this.formBuilder.group({
      catalogFormArray: this.formBuilder.array([]),
    });
    this.commentarValidation
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((value) => {
        if (value.length > 280) {
          this.isInvalid = true;
          this.symbolsRemains = 300 - value.length;
        } else {
          this.isInvalid = false;
        }
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    if (this.zoomWrapper && this.ratio) {
      this.setUpZoomWrapperSize(this.ratio);
    }
  }

  ngOnInit(): void {
    this.tenantService
      .GetTenantById()
      .pipe(
        tap((data) => {
          const curTenant = data.find(
            (t) => t.tenantName == this.tokenStorageService.GetTenant()
          );
          this.tenant = curTenant;
        }),
        switchMap(() =>
          this.remediationMeasuresService.getAvailableMeasures(
            this.tenant!.tenantName,
            this.tenant!.serverName
          )
        )
      )
      .subscribe({
        next: (data) => {
          this.setData(data);
        },
        error: () => {
          this.isNoData = true;
          this.isLoading = false;
        },
      });
  }

  ngAfterViewInit() {
    this.zoomWrapperList.changes.subscribe(() => {
      if (this.zoomWrapper && this.evaluation) {
        this.getImageDimensions(this.evaluation.imageUrl);
      }
    });
  }

  get itemsFormArray(): FormArray {
    return this.form.get('catalogFormArray') as FormArray;
  }

  private setData(data: Evaluation): void {
    this.isNoData = false;
    if (data == null) {
      this.isFinished = true;
      this.isLoading = false;
      return;
    }
    if (data.availableMeasures && data.availableMeasures.length) {
      this.catalogItems = data.availableMeasures;
      this.getCatalogItemsForSelect();
    }
    this.evaluation = data;
    if (this.zoomWrapper && this.evaluation) {
      this.getImageDimensions(this.evaluation.imageUrl);
    }
    this.commentar = data.comment as string;
    this.initialAddItem();
    if (data.order == 1) {
      this.isPrevious = true;
    }
    this.isLoading = false;
  }

  private getImageDimensions(imageUrl: string): void {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      const imageWidth = img.width;
      const imageHeight = img.height;
      this.ratio = imageWidth / imageHeight;
      if (this.ratio) {
        this.setUpZoomWrapperSize(this.ratio);
      }
    };
  }

  private setUpZoomWrapperSize(imageRatio: number): void {
    const containerMaxHeight = 448; // 28rem
    const containerMaxWidth =
      this.zoomWrapperContainer.nativeElement.clientWidth;
    const posibleHeight = containerMaxWidth / imageRatio;
    if (posibleHeight <= containerMaxHeight) {
      this.setUpZoomWrapperHeight(posibleHeight);
      this.setUpZoomWrapperWidth(containerMaxWidth);
    } else {
      this.setUpZoomWrapperHeight(containerMaxHeight);
      this.setUpZoomWrapperWidth(containerMaxHeight * imageRatio);
    }
  }

  private setUpZoomWrapperWidth(n: number): void {
    const nativeElement = this.zoomWrapper.nativeElement;
    nativeElement.style.width = `${n.toString()}px`;
  }

  private setUpZoomWrapperHeight(n: number): void {
    const nativeElement = this.zoomWrapper.nativeElement;
    nativeElement.style.height = `${n.toString()}px`;
  }

  goPrevious(): void {
    this.remediationMeasuresService
      .getPrevious(this.tenant!.tenantName, this.tenant!.serverName)
      .pipe(
        switchMap((res) => {
          return this.getMeasure(res.id).pipe(map(() => res));
        })
      )
      .subscribe((data) => {
        this.setData(data);
        this.isPrevious = true;
        this.isInvalid = false;
      });
  }

  handleKeyPress(event: KeyboardEvent, item: any): void {
    const allowedKeyCodes = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57]; // ASCII codes for numbers 0-9
    const dotKeyCode = [46];

    const key = event.key || String.fromCharCode(event.keyCode);
    const isAllowDot =
      !!item.count && !item.count.includes('.') && item.unit !== 'Stk';
    const isAllowedKeyCode =
      allowedKeyCodes.includes(event.keyCode) ||
      (dotKeyCode.includes(event.keyCode) && isAllowDot);

    if (!isAllowedKeyCode) {
      event.preventDefault(); // Prevent entering any other characters
    }
  }

  onPaste(event: any): void {
    event.preventDefault();
  }

  initialAddItem(): void {
    this.form = this.formBuilder.group({
      catalogFormArray: this.formBuilder.array([]),
    });
    for (let index = 1; index <= 6; index++) {
      if (!(this.evaluation as any)[`measure${index}`]) {
        if (index < 6) {
          this.addItem();
        }
        break;
      }
      const catalogItem = this.catalogItems.find((catItem) => {
        return (
          catItem.kuerzel ===
          ((this.evaluation as any)[`measure${index}`] as string)
        );
      });
      if (catalogItem) {
        this.addItem(catalogItem, (this.evaluation as any)[`quantity${index}`]);
      }
    }
  }

  addItem(data?: EvaluationCatalog, count?: any): void {
    this.itemsFormArray.push(this.getCatalogGroup(data, count));
  }

  getCatalogGroup(data?: EvaluationCatalog, count?: any) {
    return this.formBuilder.group({
      kuerzel: [data?.kuerzel || ''],
      description: [data?.description || null],
      quantityRequired: [!!data?.quantityRequired],
      unit: [data?.unit || ''],
      typeObject: [data?.typeObject || ''],
      count: [{ value: count || '', disabled: !data?.quantityRequired }],
    });
  }

  removeItem(index: number): void {
    if (this.itemsFormArray.length !== 1) {
      const catalogItem = this.catalogItems.find(
        (catItem) =>
          this.getFormGroup(index).value['kuerzel'] === catItem.kuerzel
      );
      if (catalogItem) {
        catalogItem!.selected = false;
      }
      this.itemsFormArray.removeAt(index);
      this.getCatalogItemsForSelect();
    }
  }

  getCatalogItemsForSelect(): void {
    this.catalogItemsFavorite = this.catalogItems.filter(
      (item) => item.isFavorte
    );
    this.catalogItemsOther = this.catalogItems.filter(
      (item) => !item.isFavorte
    );
  }

  toggleFavorite(id: string): void {
    this.remediationMeasuresService
      .toggleFavoriteMeasure(id)
      .subscribe((res) => {
        let item = this.catalogItems.find((cat) => cat.id === id);
        if (item) {
          item.isFavorte = res;
          this.getCatalogItemsForSelect();
        }
      });
  }

  getObjectByShortCut(kuerzel: string): EvaluationCatalog {
    return (
      this.catalogItemsOther.find((item) => item.kuerzel === kuerzel) ||
      ({} as EvaluationCatalog)
    );
  }

  getFormGroup(index: number): FormGroup {
    return this.itemsFormArray.controls[index] as FormGroup;
  }

  makeSelect(kuerzel: string, index: number): void {
    const selectedObject = this.getObjectByShortCut(kuerzel);
    this.getFormGroup(index).patchValue(selectedObject);
    selectedObject.selected = true;

    this.getFormGroup(index).controls['count'].setValue('');
    if (this.getFormGroup(index).controls['quantityRequired'].value) {
      this.getFormGroup(index).controls['count'].enable();
      this.getFormGroup(index).controls['count'].addValidators([
        Validators.required,
        this.greaterThanZeroValidator(),
      ]);
    } else {
      this.getFormGroup(index).controls['count'].removeValidators([
        Validators.required,
        this.greaterThanZeroValidator(),
      ]);
      this.getFormGroup(index).controls['count'].disable();
    }
    this.getFormGroup(index).controls['count'].updateValueAndValidity();
    this.getCatalogItemsForSelect();
  }

  selectPage(req: MeasurePage): void {
    this.selectedPage = req;
  }

  goHome(): void {
    this.router.navigateByUrl('');
  }

  skip(): void {
    this.isLoading = true;
    this.remediationMeasuresService
      .sendEvaluation({
        Tenant: this.tenant!.tenantName,
        Source: this.tenant!.serverName,
        Id: this.evaluation!.id,
        Comment: this.evaluation?.comment,
      })
      .pipe(
        switchMap((res) => {
          return this.getMeasure(res.id).pipe(map(() => res));
        })
      )
      .subscribe({
        next: (data) => {
          this.setData(data);
          this.isPrevious = false;
        },
        error: () => {
          this.isNoData = true;
          this.isLoading = false;
        },
      });
  }

  save(): void {
    this.isLoading = true;
    const answer: any = {
      Tenant: this.tenant!.tenantName,
      Source: this.tenant!.serverName,
      Id: this.evaluation!.id,
      Comment: this.commentar,
    };
    let index = 1;
    this.form.getRawValue().catalogFormArray.forEach((element: any) => {
      if (element.kuerzel && (!element.quantityRequired || element.count)) {
        answer[`Measure${index}`] = element.kuerzel;
        answer[`Quantity${index}`] = element.count;
        index++;
      }
    });

    this.remediationMeasuresService
      .sendEvaluation(answer)
      .pipe(
        switchMap((res) => {
          return this.getMeasure(res.id).pipe(map(() => res));
        })
      )
      .subscribe({
        next: (data) => {
          this.setData(data);
          this.isPrevious = false;
        },
        error: () => {
          this.isNoData = true;
          this.isLoading = false;
        },
      });
  }

  private getMeasure(objectId: string): Observable<EvaluationCatalog[]> {
    return this.remediationMeasuresService
      .getMeasure(this.tenant!.tenantName, this.tenant!.serverName, objectId)
      .pipe(
        tap((data) => {
          this.catalogItems = data;
          this.getCatalogItemsForSelect();
        })
      );
  }

  private greaterThanZeroValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (!value || value <= 0) {
        return { greaterThanZero: true };
      }
      return null;
    };
  }
}
