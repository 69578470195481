<div class="card p-0" style="border-radius: 12px;">
  <img *ngIf="isLoading" class="loading-gif" src="./assets/Images/SkeletonPreloader.gif" />
  <div *ngIf="!isLoading">
  <div *ngIf="!announcements || announcements.length == 0" class="p-3 text-center" style="font-weight: bold">Hier können Sie die Ankündigungen sehen, die in naher Zukunft veröffentlicht werden</div>
  <carousel *ngIf="announcements && announcements.length > 0" [pauseOnFocus]="true" [isAnimated]="true" class="announcements">
    <slide *ngFor="let announcement of announcements">
        <div *ngIf="announcement.image" class="float-start mobile-size-100">
          <img [src]="announcement.image">
        </div>
        <div class="float-start detail-size" style="padding: 24px;">
          <h5>{{announcement.title}}</h5>
          <div style="min-height: 9.5rem">
            <div style="font-size: 14px;max-height: 4rem;overflow: hidden;"
                 class="position-relative description" [innerHTML]="announcement.description"></div>
            <a [href]="announcement.moreDetails" target=”_blank” class="btn btn-primary" style="padding: 8px; background: #262526; width: 125px; margin-top: 1.25rem;">Mehr Details</a>
          </div>
        </div>
    </slide>
  </carousel>
  </div>
</div>
