import { Component } from '@angular/core';
import {AnnouncementService} from "../../_admin/announcements/services/announcement.service";
import {AnnouncementDTO} from "../../_admin/announcements/models/AnnouncementDTO";

@Component({
  selector: 'app-announcement-widget',
  templateUrl: './announcement-widget.component.html',
  styleUrls: ['./announcement-widget.component.scss']
})
export class AnnouncementWidgetComponent {
  announcements: AnnouncementDTO[] | undefined;
  maxLengthWidget = 200;
  isLoading = true;
constructor(private announcementService: AnnouncementService) {
  this.announcementService.GetUserAnnouncements().subscribe( data => {
    this.announcements = data;
    this.isLoading = false;
  } );
}
}
