import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-round-chart',
  templateUrl: './round-chart.component.html',
  styleUrls: ['./round-chart.component.scss']
})
export class RoundChartComponent {
@Input() isBigSize = true;
@Input() cssClass: string = '';
@Input() isCount = true;
@Input() hasCaret = true;
@Input() curValue:number = 0;
@Input() mainValue:number = 0;
@Input() porcent:number = 0;
}
