import { Component } from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {environment} from "../../../../environments/environment";
import {AnonymousCredential, BlobServiceClient, newPipeline} from "@azure/storage-blob";
import {switchMap} from "rxjs";
import {TestProtocolService} from "../../services/test-protocol.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-upload-modal',
  templateUrl: './upload-modal.component.html',
  styleUrls: ['./upload-modal.component.scss']
})
export class UploadModalComponent {
  currentFile: File | undefined;
  isUploadProcess = false;
  uploadPersent: number = 0;
  label = '';

  constructor(public bsModalRef: BsModalRef, private service: TestProtocolService, private toastr: ToastrService) {
    (window as any).global = window;
    (window as any).process = require('process');
    (window as any).Buffer = require('buffer').Buffer;
  }

 async confirm() {
    this.isUploadProcess = true;
    //Upload to azure proccess
    // generate account sas token
    const accountName = environment.accountName;
    const key = environment.key;
    const start = new Date(new Date().getTime() - (15 * 60 * 1000));
    const end = new Date(new Date().getTime() + (30 * 60 * 1000));
    const signedpermissions = 'rwdlac';
    const signedservice = 'b';
    const signedresourcetype = 'sco';
    const signedexpiry = end.toISOString().substring(0, end.toISOString().lastIndexOf('.')) + 'Z';
    const signedProtocol = 'https';
    const signedversion = '2018-03-28';

    const StringToSign =
      accountName + '\n' +
      signedpermissions + '\n' +
      signedservice + '\n' +
      signedresourcetype + '\n' +
      '\n' +
      signedexpiry + '\n' +
      '\n' +
      signedProtocol + '\n' +
      signedversion + '\n';
    const crypto = require('crypto');

    const sig = crypto.createHmac('sha256', Buffer.from(key, 'base64')).update(StringToSign, 'utf8').digest('base64');
    const sasToken = `sv=${(signedversion)}&ss=${(signedservice)}&srt=${(signedresourcetype)}&sp=${(signedpermissions)}&se=${encodeURIComponent(signedexpiry)}&spr=${(signedProtocol)}&sig=${encodeURIComponent(sig)}`;
    const containerName = environment.TestProtocol.containerName;
    const pipeline = newPipeline(new AnonymousCredential(), {
      retryOptions: {maxTries: 4}, // Retry options
      userAgentOptions: {userAgentPrefix: "AdvancedSample V1.0.0"}, // Customized telemetry string
      keepAliveOptions: {
        // Keep alive is enabled by default, disable keep alive by setting false
        enable: false
      },
    });
    const blobServiceClient = new BlobServiceClient(`https://${accountName}.blob.core.windows.net?${sasToken}`,
      pipeline)
    const containerClient = blobServiceClient.getContainerClient(containerName)
    if (!containerClient.exists()) {
      console.log("the container does not exit")
      await containerClient.create()
    }
   //Create unic fileName
   const {v4: uuidv4} = require('uuid');
   let unicFileName = uuidv4();
   const client = containerClient.getBlockBlobClient(unicFileName + '/' + this.currentFile?.name);
   const response = await client.uploadBrowserData(this.currentFile!, {
     blockSize: 4 * 1024 * 1024, // 4MB block size
     concurrency: 20, // 20 concurrency
     onProgress: (ev) => {
       this.uploadPersent = Math.trunc(ev.loadedBytes * 100 / this.currentFile!.size);
     },
     blobHTTPHeaders: {blobContentType: this.currentFile!.type}
   });

   //Send file to API
   this.service.RegisterUploadedFile(this.label, unicFileName + '/' + this.currentFile?.name).pipe(
     switchMap((data: any) => {
       return this.service.RegisterFile(data.id);
     })
   ).subscribe( rs => {
     this.toastr.success("Die Isybau-Datei wurde erfolgreich hochgeladen", "Upload erfolgreich", { positionClass: 'toast-bottom-left'});
     this.isUploadProcess = false;
     this.bsModalRef?.hide();
   }, error => {
     this.toastr.info('Ihr Prüfkontingent ist zu niedrig um eine Datei hochzuladen. Setzen Sie sich über service@ing-plus.de mit uns in Verbindung oder warten Sie bis ihr Kontingent sich erneuert hat.');
     this.isUploadProcess = false;
     this.bsModalRef?.hide();
   });

  }

  decline(): void {
    this.bsModalRef?.hide();
  }

  onFileChange(event: any) {
    this.currentFile = event.target.files[0];
  }

  removeFile(){
    this.currentFile = undefined;
  }
  getSize(size: number){
    return Math.trunc(size * 0.001);
  }
}
