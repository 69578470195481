<div class="row mobile-margin-top max-limit" style="margin-top: 5.25rem; padding-left: 2rem;padding-right: 2rem;">
  <div class="col-12 col-lg-4">
    <small>{{currentDate }}</small>
    <h1 class="mt-3">Willkommen, {{userName}}!</h1>
    <p style="font-size: 14px; margin-top: 0.75rem;">Wir freuen uns sehr, Sie bei uns begrüßen zu dürfen! Die Anmeldung
      war der erste Schritt, um Ihre Infrastrukturverwaltung in ein smartes Assetmanagement zu verwandeln! Wir haben
      eine Vielzahl von Funktionen für Sie vorbereitet, um Ihren Arbeitsalltag so einfach wie möglich zu gestalten.
      Solltest Sie Anregungen oder Fragen haben, kontaktiere Sie uns jederzeit – wir stehen Ihnen gerne zur Seite und
      unterstützen Sie bei deiner Arbeit!</p>
    <p style="font-size: 14px;">Viele Grüße,</p>
    <p style="font-size: 14px;">Das Team von ing+</p>
    <app-notifications-dashboard></app-notifications-dashboard>
  </div>
  <div class="col-12 col-lg-8 mobile-margin-top">
    <!--Announcement widget-->
    <div class="row">
      <div class="col-12">
        <app-announcement-widget></app-announcement-widget>
      </div>
    </div>
    <div class="row" style="margin-top: 1.25rem">
      <!--User Event widget-->
      <div class="col-md-6 col-12 p-0">
        <div class="col-12 h-100">
          <app-events-widget></app-events-widget>
        </div>
      </div>
      <!--Weather widget-->
      <div class="col-md-6 col-12 p-0 mobile-margin-top">
        <div class="col-12">
          <app-weather-widget></app-weather-widget>
        </div>
      </div>
    </div>
    <!--Feedback widget-->
    <div class="row mb-3">
      <div class="col-12">
        <app-feedback-widget></app-feedback-widget>
      </div>
    </div>
  </div>
</div>