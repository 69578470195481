import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {SubscriptionDetails} from "../models/Subscription-details";
import {PaginationFiles} from "../models/PaginationFiles";
import {TokenService} from "../../_services/token.service";

@Injectable({
  providedIn: 'root'
})
export class TestProtocolService {
  constructor(private http: HttpClient, private tokenService: TokenService) { }

  GetSubscriptionDetails(){
    return this.http.get<SubscriptionDetails>(environment.TestProtocol.SubscriptionDetailsUrl);
  }

  GetFiles( page: number, pageSize: number = 10, search = '', filter = -1){
    return this.http.get<PaginationFiles>(environment.TestProtocol.FilesUrl + 'page=' + page + '&pageSize=' + pageSize + '&nameFilter=' + search + '&statusFilter=' + (filter == -1 ? '' : filter));
  }

  SendCorrectialRequest(url: string){
    return this.http.get(url);
  }
  EditLabelFile(label:string, uploadId: number){
    return this.http.post(environment.TestProtocol.ChangeLabelUrl, {
      UploadId: uploadId,
      Label: label
    });
  }

  RemoveFile(uploadId: number){
    return this.http.delete(environment.TestProtocol.RemoveFile + uploadId);
  }

  RegisterUploadedFile(label: string, filePath: string){
    return this.http.post(environment.upload.RegisterUploadedFile, {
      Label: label,
      Tenant: this.tokenService.GetTenant(),
      Source: this.tokenService.GetSource(),
      FilePath: filePath
    });
  }
  RegisterFile(uploadId: number){
    return this.http.post(environment.TestProtocol.RegisterFile, {
      UploadId: uploadId
    });
  }
}
