<div class="d-inline-block mr-4 mt-4">
  <div [class.progress-bar-big]="isBigSize"
       [class.progress-bar-small]="!isBigSize"
       [style.--progress-value]="porcent" [ngStyle]="{'to': porcent}"
        class="{{cssClass}}">
    <progress id="html" min="0" max="100" value="100"></progress>
    <span class="progressbar__text" [class.progressbar__text-secondary]="!isBigSize">
              <span [style.font-size]="isBigSize ? '28px' : '20px'" style="font-weight: 400;">{{isCount ? (curValue | number:'1.0-0': 'de-DE')  : (curValue | mToKm) }}</span>
              <span [style.font-size]="isBigSize ? '22px' : '20px'">{{isCount ? 'St' : 'km'}}</span>
              <div *ngIf="hasCaret" [style.font-size]="isBigSize ? '16px' : '13px'"
                   [style.color]="mainValue - curValue > 0 ? '#E00303' : '#009B51'"
              >{{isCount ? ( mainValue | number:'1.0-0': 'de-DE') : (mainValue | mToKm )}} <span>{{isCount ? 'St' : 'km'}}</span>
                <i class="caret-style" [style.background]="mainValue - curValue > 0 ? 'rgba(224, 3, 3, 0.07)' : 'rgba(0, 155, 81, 0.10)'"
                   [ngClass]="mainValue - curValue > 0 ? 'icon-IconCaret-Down' : 'icon-IconCaret-Up'"></i>
              </div>
            </span>
  </div>

</div>
