<div class="notifications border-top">
  <h5>{{totalUnRead}} ungelesene Nachricht{{totalUnRead !== 1 ? 'en' : ''}} warten auf dich:</h5>
  <ul class="notification-menu" *ngIf="notifications.length; else noData">
    <li *ngFor="let not of notifications" (click)="navigate(not)"
      class="notification d-flex border-bottom py-2 unread" [ngClass]="{'notification-tenant': isCurrentTenant(not.linkToUrl)}">
      <i [class]="notificationIconsType[not.type]"></i>
      <div class="unread-dot"></div>
      <div class="d-flex flex-column w-100">
        <div class="d-flex">
          <p class="title flex-grow-1">{{notificationType[not.type]}}</p>
          <p class="passed">{{not.date | timePast}}</p>
          <div class="read-notification" tooltip="Als gelesen markieren" (click)="readNotifications(not.id, $event)">
          </div>
        </div>
        <p *ngIf="isCurrentTenant(not.linkToUrl)" class="tenant">{{getTenantByLink(not.linkToUrl)}}</p>
        <p class="text">{{not.text}}</p>
      </div>
    </li>
  </ul>
  <ng-template #noData>
    <div class="no-data">
      <i class="icon-IconNotification"></i>
      <p>Es sind noch keine Benachrichtigungen eingegangen</p>
    </div>
  </ng-template>
</div>
