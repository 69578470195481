import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {AnnouncementDTO} from "../models/AnnouncementDTO";
import {PagedAnnouncementList} from "../models/PagedAnnouncementList";

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService {

  constructor(private http: HttpClient) { }

  CreateUpdateAnnouncement(announcement: AnnouncementDTO){
    return this.http.post<AnnouncementDTO>(environment.apiUrl + 'announcement', announcement);
  }

  GetAnnouncements( pageNumber: number, pageSize = 10, search: string){
    return this.http.get<PagedAnnouncementList>(environment.apiUrl + 'announcements?pageNumber=' + pageNumber +'&PageSize='
      + pageSize + '&Search=' + search);
  }

  RemoveAnnouncement(announcementId: string){
    return this.http.delete<any>(environment.apiUrl + 'announcement/' + announcementId);
  }

  GetAnnouncementById(announcementId: string){
    return this.http.get<AnnouncementDTO>(environment.apiUrl + 'announcement/' + announcementId);
  }

  GetUserAnnouncements(){
    return this.http.get<AnnouncementDTO[]>(environment.apiUrl + 'newestAnnouncements');
  }
}
