import { Component } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {AuthService} from "../../_services/auth.service";
import { Router} from "@angular/router";
import {SignUpDTO} from "../../_models/signUpDTO";
import {debounceTime, Subject, switchMap} from "rxjs";
import {passwordMatchingValidatior} from "../../_helpers/validators/PasswordMatch.validator";
import {PasswordValidators} from "../../_helpers/validators/PasswordSpecificCharacter.validator";
import {TenantService} from "../../_services/tenant.service";

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent {
  registerForm: FormGroup;
  isFormSend:boolean = false;
  isEmailExist: boolean = false;
  private readonly emailSubject = new Subject<string>();
  model: SignUpDTO = {
    email: '',
    password: '',
    passwordConfirm: '',
    firstName: '',
    lastName: ''
  };

  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router, private tenantService: TenantService) {
    this.registerForm = this.fb.group({
      email: [this.model.email, [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      firstName: [this.model.firstName, [Validators.required, Validators.pattern('^[^*|\\\\\:<>"/?]+$')]],
      lastName:[this.model.lastName,[Validators.required, Validators.pattern('^[^*|\\\\\:<>"/?]+$')]],
      password: [this.model.password, [Validators.required, Validators.minLength(10),  PasswordValidators.patternValidator(new RegExp("(?=.*[$@^!%*?&])"), {
        requiresSpecialChars: true
      })]],
      passwordConfirm: [this.model.passwordConfirm, Validators.required]
    }, { validators: passwordMatchingValidatior, PasswordValidators });
    this.emailSubject
       .pipe(
         debounceTime(300),
         switchMap((email) => this.authService.CheckEmail(email))
       )
       .subscribe((result) => this.isEmailExist = result);
  }

  public onSearchQueryInput(event: Event): void {
    const emailFiled = (event.target as HTMLInputElement).value;
    if(emailFiled.length != 0) {
      this.emailSubject.next(emailFiled.trim());
    }

  }

  onSubmit() {
    if (this.registerForm.valid && !this.isEmailExist) {
         this.model.email = this.registerForm.controls['email'].value;
      this.model.firstName = this.registerForm.controls['firstName'].value;
      this.model.lastName = this.registerForm.controls['lastName'].value;
           this.model.password = this.registerForm.controls['password'].value;
           this.authService.Register(this.model).subscribe();
      this.isFormSend = true;
    } else {
      console.log('invalid form');
    }
  }

  GoToLogin(){
    this.router.navigateByUrl('/login');
  }

  GoToForgotPassword() {
    this.router.navigateByUrl('/recovery-password');
  }
}
