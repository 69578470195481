import { Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  @Output() closeModalEvent = new EventEmitter<boolean>();
  title?: string;
  description?: string;
  icon?: string;
  denyButton?: string;
  acceptButton?: string;

  constructor(private bsModalRef: BsModalRef) {}

  onCancel(): void {
    this.closeModalEvent.emit(false);
    this.bsModalRef?.hide();
  }

  onConfirm(): void {
    this.closeModalEvent.emit(true);
    this.bsModalRef?.hide();
  }
}
