import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { SewerTinderService } from '../../services/sewer-tinder.service';
import { TenantService } from 'src/app/_services/tenant.service';
import { AnswerEvaluation } from '../../models/answerEvaluation';
import { Evaluation } from '../../models/evaluation';
import { Tenant } from 'src/app/_models/tenant';
import { Subject, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { Router } from '@angular/router';
import { TokenService } from '../../../_services/token.service';

@Component({
  selector: 'app-sewer-tinder',
  templateUrl: './sewer-tinder.component.html',
  styleUrls: ['./sewer-tinder.component.scss'],
})
export class SewerTinderComponent {
  @ViewChild('zoomWrapper') zoomWrapper!: ElementRef;
  @ViewChild('zoomWrapperContainer') zoomWrapperContainer!: ElementRef;
  answer: AnswerEvaluation | undefined;
  evaluation: Evaluation | undefined;
  tenant: Tenant | undefined;
  commentar = '';
  isPrevious = false;
  isLoading = true;
  isInvalid = false;
  isFinished = false;
  isNoData = false;
  symbolsRemains = 300;
  commentarValidation = new Subject<string>();
  ratio = 4 / 3;
  constructor(
    private sewerTinderService: SewerTinderService,
    private tenantService: TenantService,
    private router: Router,
    private tokenStorageService: TokenService
  ) {
    this.commentarValidation
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((value) => {
        if (value.length > 280) {
          this.isInvalid = true;
          this.symbolsRemains = 300 - value.length;
        } else {
          this.isInvalid = false;
        }
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    if (this.ratio) {
      this.setUpZoomWrapperSize(this.ratio);
    }
  }

  ngOnInit(): void {
    this.tenantService
      .GetTenantById()
      .pipe(
        switchMap((data) => {
          const curTenant = data.find(
            (t) => t.tenantName == this.tokenStorageService.GetTenant()
          );
          this.tenant = curTenant;
          return this.sewerTinderService.GetEvaluation(
            this.tenant!.tenantName,
            this.tenant!.serverName
          );
        })
      )
      .subscribe({
        next: (data) => {
          this.isNoData = false;
          if (data == null) {
            this.isFinished = true;
            this.isLoading = false;
            return;
          }
          this.evaluation = data;
          this.getImageDimensions(this.evaluation.imageUrl);
          this.commentar = data.comment as string;
          if (data.order == 1) {
            this.isPrevious = true;
          }
          this.isLoading = false;
        },
        error: () => {
          this.isNoData = true;
          this.isLoading = false;
        },
      });
  }

  private getImageDimensions(imageUrl: string): void {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      const imageWidth = img.width;
      const imageHeight = img.height;
      this.ratio = imageWidth / imageHeight;
      if (this.ratio) {
        this.setUpZoomWrapperSize(this.ratio);
      }
    };
  }

  private setUpZoomWrapperSize(imageRatio: number): void {
    const containerMaxHeight = 448; // 28rem
    const containerMaxWidth =
      this.zoomWrapperContainer.nativeElement.clientWidth;
    const posibleHeight = containerMaxWidth / imageRatio;
    if (posibleHeight <= containerMaxHeight) {
      this.setUpZoomWrapperHeight(posibleHeight);
      this.setUpZoomWrapperWidth(containerMaxWidth);
    } else {
      this.setUpZoomWrapperHeight(containerMaxHeight);
      this.setUpZoomWrapperWidth(containerMaxHeight * imageRatio);
    }
  }

  private setUpZoomWrapperWidth(n: number): void {
    const nativeElement = this.zoomWrapper.nativeElement;
    nativeElement.style.width = `${n.toString()}px`;
  }

  private setUpZoomWrapperHeight(n: number): void {
    const nativeElement = this.zoomWrapper.nativeElement;
    nativeElement.style.height = `${n.toString()}px`;
  }

  goPrevious() {
    this.sewerTinderService
      .GetPrevious(this.tenant!.tenantName, this.tenant!.serverName)
      .subscribe((data) => {
        this.evaluation = data;
        this.getImageDimensions(this.evaluation.imageUrl);
        this.commentar = data.comment as string;
        this.isPrevious = true;
        this.isInvalid = false;
      });
  }

  YesAnswer() {
    const answer: AnswerEvaluation = {
      id: this.evaluation?.id!,
      source: this.tenant?.serverName!,
      tenant: this.tenant?.tenantName!,
      comment: this.commentar,
    };
    this.sewerTinderService.SendYesEvaluation(answer).subscribe((data) => {
      if (data == null) {
        this.isFinished = true;
      }
      this.isPrevious = false;
      this.evaluation = data;
      this.getImageDimensions(this.evaluation.imageUrl);
      this.isInvalid = false;
      this.commentar = data.comment as string;
    });
  }

  NoAnswer() {
    const answer: AnswerEvaluation = {
      id: this.evaluation?.id!,
      source: this.tenant?.serverName!,
      tenant: this.tenant?.tenantName!,
      comment: this.commentar,
    };
    this.sewerTinderService.SendNoEvaluation(answer).subscribe((data) => {
      if (data == null) {
        this.isFinished = true;
      }
      this.isPrevious = false;
      this.evaluation = data;
      this.getImageDimensions(this.evaluation.imageUrl);
      this.isInvalid = false;
      this.commentar = data.comment as string;
    });
  }

  MaybeAnswer() {
    const answer: AnswerEvaluation = {
      id: this.evaluation?.id!,
      source: this.tenant?.serverName!,
      tenant: this.tenant?.tenantName!,
      comment: this.commentar,
    };
    this.sewerTinderService.SendNotClearEvaluation(answer).subscribe((data) => {
      if (data == null) {
        this.isFinished = true;
      }
      this.isPrevious = false;
      this.evaluation = data;
      this.getImageDimensions(this.evaluation.imageUrl);
      this.isInvalid = false;
      this.commentar = data.comment as string;
    });
  }

  GoHome() {
    this.router.navigateByUrl('');
  }
}
