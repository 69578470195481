import { Component } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../_services/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-recovery-password',
  templateUrl: './recovery-password.component.html',
  styleUrls: ['./recovery-password.component.scss']
})
export class RecoveryPasswordComponent {
  recoveryForm: FormGroup;
  isFormSend: boolean = false;
  email: string = '';
  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router) {
    this.recoveryForm = this.fb.group({
      email: [null, [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]]
    });
  }

  onSubmit() {
    if (this.recoveryForm.valid) {
        this.email = this.recoveryForm.controls['email'].value;
        this.authService.ForgotPassword(this.email).subscribe(_ => this.isFormSend = true
        , error => console.log(error));

    }
  }

  GoToLogin(){
    this.router.navigateByUrl('/login');
  }
}
