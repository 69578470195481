import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'iconByExtantion',
  standalone: true,
})
export class IconByExtantionPipe implements PipeTransform {
  transform(value: any): any {
    switch (value.toLowerCase()) {
      case 'pdf':
        return 'icon-IconPDF';
      case 'gp':
        return 'icon-Icon3gp';
      case 'avi':
        return 'icon-IconAvi';
      case 'bmp':
        return 'icon-IconBmp';
      case 'flv':
        return 'icon-IconFlv';
      case 'jpg':
        return 'icon-IconJpg';
      case 'm4v':
        return 'icon-IconM4v';
      case 'mkv':
        return 'icon-IconMkv';
      case 'mov':
        return 'icon-IconMov';
      case 'mp4':
        return 'icon-IconMp4';
      case 'mpg':
        return 'icon-IconMpg';
      case 'ogv':
        return 'icon-IconOgv';
      case 'png':
        return 'icon-IconPng';
      case 'svg':
        return 'icon-IconSvg';
      case 'tif':
        return 'icon-IconTif';
      default:
        return 'icon-IconFile';
    }
  }
}
