import { Component } from '@angular/core';
import {AuthService} from "../../_services/auth.service";
import {ActivatedRoute, Params, Router} from "@angular/router";

@Component({
  selector: 'app-complite-registration',
  templateUrl: './complite-registration.component.html',
  styleUrls: ['./complite-registration.component.scss']
})
export class CompliteRegistrationComponent {
  constructor(private authService: AuthService, private router: Router,  private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    let code;
    let userId;
    this.route.queryParams.subscribe((params: Params) => {
      code = params['code'];
      userId = params['userId'];
    });
    if(code != null && userId != null){
        this.authService.ConfirmRegistration(code, userId).subscribe();
    }
  }

  GoToLogin(){
    this.router.navigateByUrl('/login');
  }
}
