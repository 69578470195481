import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Weather} from "../_models/ForecastDay";

@Injectable({
  providedIn: 'root'
})
export class WeatherService {
  header = {
    headers: new HttpHeaders()
      .set("Access-Control-Allow-Origin", "*")
  }
  constructor(private http: HttpClient) { }

  GetWeather(query: string = 'Berlin'){
    return this.http.get<Weather>(environment.Wheather.ApiCall + environment.Wheather.Key + '&q=' + query + '&days=4' );
  }
}
