import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {PagedUserManagement} from "../models/PagedUserManagement";
import {Role} from "../models/Role";

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  constructor(private http: HttpClient) { }

  GetUsers( pageNumber: number, pageSize = 9, search: string, filter: string){
    return this.http.get<PagedUserManagement>(environment.apiUrl + 'UsersWithRolesAndTenants?pageNumber=' + pageNumber +'&PageSize='
      + pageSize + '&Search=' + search + '&FilterBy=' + filter);
  }

  GetRoles(){
    return this.http.get<Role[]>(environment.apiUrl + 'Roles');
  }

  UpdateRoles(userId: string, roles: string[]){
    return this.http.post(environment.apiUrl + 'AssignRole', {
      UserId: userId,
      Roles: roles
    })
  }
}
