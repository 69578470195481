export const ADMIN_ROLE = 'Admin';
export const USER_ROLE = 'User';
export const UPLOAD_ROLE = 'Uploads';
export const SEWER_TINDER_ROLE = 'SewerTinder';
export const SEWER_DASHBOARD_ROLE = 'SewerDashboard';
export const QUESTIONNAIRE_ROLE = 'Questionnaire';
export const MAPS_ROLE = 'Maps';
export const DATA_EXPORT_ROLE = 'DataExport';
export const SEWER_TINDER_v2_ROLE = 'SewerTinderV2';
export const SEWER_TINDER_v3_ROLE = 'SewerTinderV3';
export const WASTE_WATER_GROUP = 'Waster Water';
export const MAPS_GROUP = 'Maps';
export const CONSTRUCTION_SITE_ROLE = 'ConstructionSite';
export const MaxMobileSize = 700;
export const MaxTabletSize = 1400;
export const CurrentNumberWasteWaterGroup = 8;
