import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import {
  take,
  tap,
  switchMap,
  of,
  filter,
  BehaviorSubject,
  Subject,
  ReplaySubject,
} from 'rxjs';
import { Tenant } from '../_models/tenant';
import {
  CURRENT_SESSION_TENANT,
  URL_AFTER_RELOAD,
} from '../pages/home/home.component';
import { ModalComponent } from '../shared/components/modal/modal.component';
import { UploadFileService } from '../upload/_services/upload-file.service';
import { TenantService } from './tenant.service';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class TenantInnerService {
  tenants$: BehaviorSubject<Tenant[]> = new BehaviorSubject<Tenant[]>([]);
  curTenant$: ReplaySubject<Tenant> = new ReplaySubject<Tenant>(1);
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private tenants: Tenant[] = [];
  private curTenant!: Tenant;

  constructor(
    private tokenStorageService: TokenService,
    public router: Router,
    private tenantService: TenantService,
    private uploadFileService: UploadFileService,
    private modalService: BsModalService
  ) {}

  getCurTenant(): Tenant {
    return this.curTenant;
  }

  setUserTenant() {
    this.tenantService.GetTenantById().subscribe((data) => {
      this.tenants = data.sort( (a, b) => {
        const displayNameA = a.displayName.toUpperCase();
        const displayNameB = b.displayName.toUpperCase();
        if (displayNameA < displayNameB) {
          return -1;
        }
        if (displayNameA > displayNameB) {
          return 1;
        }
        return 0;
      });
      this.tenants$.next(data);
      if (this.tenants.length == 0) {
        this.assignDefaultTenant();
        return;
      }
      const sessionTenant = window.sessionStorage.getItem(
        CURRENT_SESSION_TENANT
      );
      const savedTenant = this.tokenStorageService.GetTenant();
      if (
        sessionTenant != null &&
        data.some((t) => t.tenantName == sessionTenant)
      ) {
        const curTenant = data.find((t) => t.tenantName == sessionTenant);
        this.setCurTenant(curTenant);
        this.tokenStorageService.SetTenant(
          this.curTenant!.tenantName,
          this.curTenant!.serverName,
          this.curTenant!.location,
          this.curTenant!.displayName
        );
      } else {
        if (savedTenant == null) {
          this.setCurTenant(data[0]);
          this.tokenStorageService.SetTenant(
            this.curTenant.tenantName,
            data[0].serverName,
            data[0].location,
            data[0].displayName
          );
        } else {
          if (data.some((t) => t.tenantName == savedTenant)) {
            this.setCurTenant(data.find((t) => t.tenantName == savedTenant));
            this.tokenStorageService.SetTenant(
              this.curTenant!.tenantName,
              this.curTenant!.serverName,
              this.curTenant!.location,
              this.curTenant!.displayName
            );
          } else {
            this.setCurTenant(data[0]);
            this.tokenStorageService.SetTenant(
              this.curTenant.tenantName,
              data[0].serverName,
              data[0].location,
              data[0].displayName
            );
          }
        }
      }
      this.isLoading$.next(false);
    });
  }

  switchTenant(tenant: Tenant, urlAfterReload?: string) {
    if (this.uploadFileService.isFilesUploading$.value) {
      const initialState = {
        title: 'Hochladen von Dateien abbrechen?',
        description: `Sind Sie sicher, dass Sie das Hochladen von Daten beenden und den Mandanten wechseln möchten?`,
        icon: 'icon-IconCancel-O',
        denyButton: 'Nein',
        acceptButton: 'Ja',
      };
      const modalRef = this.modalService.show(ModalComponent, {
        class: 'modal-md',
        initialState,
      });
      modalRef.content
        ?.closeModalEvent!.pipe(
          take(1),
          tap((isApproved) => {
            modalRef?.hide();
            if (isApproved) {
              this.uploadFileService.stopUploading$.next(true);
            }
          }),
          switchMap((isApproved: boolean) => {
            return !isApproved
              ? of(false)
              : this.uploadFileService.stopUploading$.pipe(
                  filter((val: boolean) => !val),
                  take(1)
                );
          })
        )
        .subscribe(() => this.setTenant(tenant, urlAfterReload));
    } else {
      this.setTenant(tenant, urlAfterReload);
    }
  }

  setTenant(tenant: Tenant, urlAfterReload?: string): void {
    this.setCurTenant(tenant);
    const result = this.tenants.find((t) => t.tenantName == tenant.tenantName);
    this.tokenStorageService.SetTenant(
      this.curTenant.tenantName,
      result!.serverName,
      result!.location,
      result!.displayName
    );
    window.sessionStorage.setItem(
      CURRENT_SESSION_TENANT,
      this.curTenant.tenantName
    );
    if (urlAfterReload) {
      sessionStorage.setItem(URL_AFTER_RELOAD, urlAfterReload);
    }
    window.location.reload();
  }

  assignDefaultTenant(): void {
    this.tenantService.AssignDemoTenant().subscribe(() => this.setUserTenant());
  }

  private setCurTenant(tenant: Tenant | undefined): void {
    this.curTenant = tenant!;
    this.curTenant$.next(tenant!);
  }

  switchTenantByName(tenantName: string, source: string, urlAfterReload: string): void {
    const tenant = this.tenants.find((t) => t.tenantName === tenantName && t.serverName == source);
    if (tenant) {
      this.switchTenant(tenant, urlAfterReload);
    }
  }
}
