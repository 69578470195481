import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UploadFileService } from 'src/app/upload/_services/upload-file.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import {
  NotificationIconsType,
  NotificationNavigateType,
} from 'src/app/_models/notification.enum';
import { SignalRService } from '../../_services/signalR.service';
import { TenantInnerService } from 'src/app/_services/tenant-inner.service';
import {TokenService} from "../../_services/token.service";

enum NotificationType {
  Datenupload,
  Fragen,
  Datenprüfung,
  Sofortmaßnahmen,
}

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent {
  @ViewChild('pop') pop!: PopoverDirective;
  public notificationType = NotificationType;
  public notificationIconsType = NotificationIconsType;
  isLoading = true;
  totalUnRead = 0;
  totalCount = 0;
  notifications: any[] = [];
  params = {
    skip: 0,
    size: 10,
    showOnlyUnread: false,
  };

  constructor(
    public router: Router,
    public uploadFileService: UploadFileService,
    private notificationService: NotificationService,
    private signalRService: SignalRService,
    private tenantInnerService: TenantInnerService,
    private tokenStorageService: TokenService
  ) {
    this.getNotifications();
    this.signalRService.notification.subscribe((data) => {
      this.notifications.unshift(data);
      this.totalUnRead = this.totalUnRead + 1;
    });
  }

  openSettings(): void {
    this.pop?.hide();
    this.router.navigate(['./settings/notifications']);
  }

  goToUpload(): void {
    this.pop?.hide();
    this.router.navigate(['./wastewater/upload-content/upload']);
  }

  toggleSettingsNotifications(): void {
    this.params.showOnlyUnread = !this.params.showOnlyUnread;
    this.getNotifications();
  }

  getNotifications(isNextPage = false): void {
    this.isLoading = true;
    this.params.skip = isNextPage ? this.params.skip + this.params.size : 0;
    this.notificationService
      .getNotifications(
        this.params.skip,
        this.params.size,
        this.params.showOnlyUnread
      )
      .subscribe((res) => {
        this.totalUnRead = res.totalUnreadCount;
        this.totalCount = res.totalCount;
        if (isNextPage) {
          this.notifications = [...this.notifications, ...res.notifications];
        } else {
          this.notifications = res.notifications;
        }
        this.isLoading = false;
      });
  }

  readNotifications(id: string, all: boolean, e?: MouseEvent): void {
    this.notificationService.readNotifications(id, all).subscribe();
    if (all) {
      this.notifications.forEach((not) => (not.isRead = true));
    }
    if (id) {
      this.notifications.forEach((not) => {
        if (id === not.id) {
          not.isRead = true;
        }
      });
    }
    e?.stopPropagation();
  }

  deleteNotifications(id: string, all: boolean, e?: MouseEvent): void {
    this.notificationService
      .deleteNotifications(id, all)
      .subscribe(() => this.getNotifications());
    e?.stopPropagation();
  }

  navigate(notification: any): void {
    this.readNotifications(notification.id, false);
    this.pop?.hide();
    if (notification.linkToUrl != null && notification.linkToUrl != "") {
      const sourceAndTenant = notification.linkToUrl.split(' - ');
      this.tenantInnerService.switchTenantByName(
        sourceAndTenant[1],
        sourceAndTenant[0],
        NotificationNavigateType[notification.type]
      );
    } else {
      this.router.navigate([NotificationNavigateType[notification.type]]);
    }
  }

  onScroll(event: any): void {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
        event.target.scrollHeight &&
      this.totalCount > this.notifications.length
    ) {
      this.getNotifications(true);
    }
  }

  isCurrentTenant(link: string){
    if(link == null || link == ''){
      return false;
    }
    const sourceAndTenant = link.split(' - ');
    const curTenant = this.tokenStorageService.GetTenant();
    const curServer = this.tokenStorageService.GetSource();
    return !(sourceAndTenant[0] === curServer && sourceAndTenant[1] === curTenant);
  }

  getTenantByLink(link: string){
    const sourceAndTenant = link.split(' - ');
    return sourceAndTenant[1];
  }
}
