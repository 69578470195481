<button class="btn btn-default btn-secondary float-end notifications" [popover]="notificationsTemplate"
  placement="bottom" #pop="bs-popover" [outsideClick]="true" (click)="getNotifications()">
  <i class="icon-IconNotification"></i>
  <div *ngIf="!!totalUnRead" class="unread-dot"></div>
</button>

<ng-template #notificationsTemplate>
  <div class="d-flex justify-content-between notifications-title">
    <h5>Benachrichtigungen</h5>
    <!-- <p class="details" (click)="openSettings()">Einstellungen</p> -->
  </div>
  <div class="d-flex justify-content-between notifications-control">
    <label class="form-check form-switch">
      <input class="form-check-input" type="checkbox" role="switch" (change)="toggleSettingsNotifications()"
        [checked]="params.showOnlyUnread">
      <div class="form-check-label d-flex">
        <p class="form-check-label">Nur ungelesen anzeigen</p>
        <p class="unread-count">{{totalUnRead}}</p>
      </div>
    </label>
    <div class="d-flex" *ngIf="notifications.length">
      <i class="icon-IconDelete active-icon" (click)="deleteNotifications('', true, $event)"
        tooltip="Alle Benachrichtigungen löschen"></i>
      <i class="icon-IconCheck active-icon" (click)="readNotifications('', true, $event)"
        tooltip="Alle als gelesen markieren"></i>
    </div>
  </div>
  <ul class="notification-menu" (scroll)="onScroll($event)"
    *ngIf="notifications.length || (uploadFileService.isFilesUploading$ | async); else noData">
    <li *ngIf="uploadFileService.isFilesUploading$ | async" class="notification upload d-flex border-top"
      (click)="goToUpload()">
      <i class="icon-IconUpload"></i>
      <div class="unread-dot"></div>
      <div class="d-flex flex-column w-100">
        <div class="d-flex">
          <p class="title flex-grow-1">{{uploadFileService.filesAlreadyUpload$ | async | fileSize}} von
            {{uploadFileService.filesShouldUpload$ | async | fileSize}} hochgeladen</p>
          <p class="passed">now</p>
          <div class="read-notification"></div>
        </div>
        <div class="progress mt-2">
          <div class="progress-bar" role="progressbar"
            [style.width.%]="(((uploadFileService.filesAlreadyUpload$ | async) ?? 0) / ((uploadFileService.filesShouldUpload$ | async) ?? 0) * 100 ) | number:'1.0-0'"
            aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
          </div>
        </div>
      </div>
    </li>
    <li *ngFor="let not of notifications" (click)="navigate(not)"
      class="notification d-flex border-top py-2" [ngClass]="{'unread': !not.isRead, 'notification-tenant': isCurrentTenant(not.linkToUrl)}">
      <i [class]="notificationIconsType[not.type]"></i>
      <div *ngIf="!not.isRead" class="unread-dot"></div>
      <div class="d-flex flex-column w-100">
        <div class="d-flex">
          <p class="title flex-grow-1">{{notificationType[not.type]}}</p>
          <p class="passed">{{not.date | timePast}}</p>
          <div *ngIf="!not.isRead" class="read-notification" tooltip="Als gelesen markieren"
            (click)="readNotifications(not.id, false, $event)"></div>
          <div *ngIf="not.isRead" class="read-notification read"></div>
        </div>
        <p *ngIf="isCurrentTenant(not.linkToUrl)" class="tenant">{{getTenantByLink(not.linkToUrl)}}</p>
        <p class="text">{{not.text}}</p>
      </div>
    </li>
  </ul>
  <ng-template #noData>
    <div class="no-data">
      <i class="icon-IconNotification"></i>
      <p>Es sind noch keine Benachrichtigungen eingegangen</p>
    </div>
  </ng-template>
</ng-template>
