import { UploadFile } from "./FileOfUpload";
import {User} from "../../_models/User";

export interface  Upload {
    id?: string,
    name?: string,
    contractName?: string,
    comment?: string,
    type: UploadFilesType,
    files?: UploadFile[],
    userId?: string,
    isUserApprove?: boolean,
    uploadDate?: Date,
    filesTryToUpload?: String[],
    uploadedPathId?: string,
    tenant?: string,
    source?: string
    user?: User
}

export enum UploadFilesType {
    GeneralInspection, Presurvey, FollowUp, AcceptanceOfConstruction, AcceptanceOfRenovation
}
