import { Component } from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {ContactUsService} from "../../_services/contact-us.service";
import {TokenService} from "../../_services/token.service";
import jwt_decode from "jwt-decode";

@Component({
  selector: 'app-feedback-widget',
  templateUrl: './feedback-widget.component.html',
  styleUrls: ['./feedback-widget.component.scss']
})
export class FeedbackWidgetComponent {
  feedbackText: string = '';
  isProcessed = false;
constructor( private toastr: ToastrService,
             private feedbackService: ContactUsService,
             private tokenService: TokenService) {}
  sendFeedback(){
    this.isProcessed = true;
    const decoded: any = jwt_decode(this.tokenService.getToken()!);
    this.feedbackService.SendFeedback(decoded.id, this.feedbackText).subscribe( _ => {
      this.toastr.success("Vielen Dank, dass Sie sich die Zeit genommen haben, uns Ihr Feedback mitzuteilen!", "", { positionClass: 'toast-bottom-left'});
      this.feedbackText = '';
      this.isProcessed = false;
    });
  }
}
