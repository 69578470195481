import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timePast'
})
export class TimePastPipe implements PipeTransform {
  yearInDays = 365;
  monthInDays = 30;
  weekInDays = 7;
  dayInHours = 24;
  HourInMinutes = 60;
  MinuteInMilliseconds = 60000;

  transform(value: string): any {
    const currentTimestamp = new Date().getTime();
    const requestTimestamp = new Date(`${value}z`).getTime();
    const diff = currentTimestamp - requestTimestamp;
    if (diff > this.yearInDays * this.dayInHours * this.HourInMinutes * this.MinuteInMilliseconds) {
      const years = diff / (this.yearInDays * this.dayInHours * this.HourInMinutes * this.MinuteInMilliseconds);
      return years < 2 ? '1 Jahr' : `${Math.ceil(years)} Jahre`;
    } else if (diff > this.monthInDays * this.dayInHours * this.HourInMinutes * this.MinuteInMilliseconds) {
      const months = diff / (this.monthInDays * this.dayInHours * this.HourInMinutes * this.MinuteInMilliseconds);
      return months < 2 ? '1 Monat' : `${Math.ceil(months)} Monate`;
    } else if (diff > this.weekInDays * this.dayInHours * this.HourInMinutes * this.MinuteInMilliseconds) {
      const weeks = diff / (this.weekInDays * this.dayInHours * this.HourInMinutes * this.MinuteInMilliseconds);
      return weeks < 2 ? '1 Woche' : `${Math.ceil(weeks)} Wochen`;
    } else if (diff > this.dayInHours * this.HourInMinutes * this.MinuteInMilliseconds) {
      const days = diff / (this.dayInHours * this.HourInMinutes * this.MinuteInMilliseconds);
      return days < 2 ? '1 Tag' : `${Math.ceil(days)} Tage`;
    } else if (diff > this.HourInMinutes * this.MinuteInMilliseconds) {
      const hours = diff / (this.HourInMinutes * this.MinuteInMilliseconds);
      return hours < 2 ? '1 Stunde' : `${Math.ceil(hours)} Stunden`;
    } else if (diff > this.MinuteInMilliseconds) {
      const minutes = diff / (this.MinuteInMilliseconds);
      return minutes < 2 ? '1 Minute' : `${Math.ceil(minutes)} Minuten`;
    } else {
      return 'Jetzt'
    }
  }

}
