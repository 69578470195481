import {Component, EventEmitter, TemplateRef} from '@angular/core';
import {TestProtocolService} from "../../services/test-protocol.service";
import {PaginationFiles} from "../../models/PaginationFiles";
import {FAILED_FILE_STATUS, UPDATE_INTERVAL} from "../../Utils/Constants";
import {FileStatus} from "../../models/FileStatus";
import {ErrorFixingStatus} from "../../models/ErrorFixingStatus";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {debounceTime, distinctUntilChanged, Subject, Subscription, switchMap, tap, timer} from "rxjs";
import {FileCorrection} from "../../models/FileCorrection";
import {LevelErrorEnum} from "../../models/LevelErrorEnum";
import {UploadModalComponent} from "../../components/upload-modal/upload-modal.component";
import {FileOverviewRequestStatusFilter} from "../../models/FileOverviewRequestStatusFilter";
import {ToastrService} from "ngx-toastr";

const POPUP_SHOWN = 'POPUP';
@Component({
  selector: 'app-test-protocol-page',
  templateUrl: './test-protocol-page.component.html',
  styleUrls: ['./test-protocol-page.component.scss']
})
export class TestProtocolPageComponent {
  isLoading = true;
  popupShow = true;
    files: PaginationFiles | undefined;
    failedFileStatus = FAILED_FILE_STATUS;
    ReportStatus = FileStatus;
    ErrorFixingStatus = ErrorFixingStatus;
    LevelError = LevelErrorEnum;
    pagination: number = 1;
    allItems: number = 0;
    modalRef?: BsModalRef;
  search = new Subject<string>();
  searchField = '';
    public onClose: Subject<boolean> = new Subject();
    correctionModel = {
        errors: 0,
        costs:0,
        time:0
    }
    label = '';
  filterType = -1;
  parentEmitter = new EventEmitter<string>();

  subscription: Subscription = new Subscription();

  filterOptions = [
    { label: 'Alle', value: -1 },
    { label: 'Ungültige Dateien', value: FileOverviewRequestStatusFilter.FileConditionError },
    { label: 'Korrektur verfügbar', value: FileOverviewRequestStatusFilter.FixAvailable },
    { label: 'Korrigiert', value: FileOverviewRequestStatusFilter.FixComplete },
    { label: 'Fehlerfrei', value: FileOverviewRequestStatusFilter.NoFixRequired }
  ];

  constructor(private service: TestProtocolService, private modalService: BsModalService, private toastr: ToastrService) {}

  ngOnInit(): void {
    const isPopupClosed = window.sessionStorage.getItem(POPUP_SHOWN);
    if(isPopupClosed){
      this.popupShow = false;
    }
    this.search.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      tap((value) => this.searchField = value),
      switchMap(() => this.service.GetFiles(1, undefined, this.searchField, this.filterType) )
    ).subscribe(data => {
      this.pagination = 1;
      this.files = data!;
      this.allItems = data!.totalCount;
    });
    const source = timer(0, UPDATE_INTERVAL);
    this.subscription.add(source.subscribe(_ => {
      const req =  this.service.GetFiles(this.pagination, undefined, this.searchField, this.filterType).subscribe(data => {
        this.files = data;
        this.allItems = data.totalCount;
        this.isLoading = false;
        req.unsubscribe();
      });

    }));
  }

  fetchData(){
    this.service.GetFiles(this.pagination, undefined, this.searchField, this.filterType).subscribe(data => {
      this.files = data;
      this.allItems = data.totalCount;
      this.isLoading = false;
    });
  }

  filterByType(type: FileOverviewRequestStatusFilter){
    this.filterType = type;
    this.pagination = 1;
    this.fetchData();
  }

  renderPage(event: number) {
    this.pagination = event;
    this.fetchData();
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  public onConfirm(): void {
    this.onClose.next(true);
  }

  public onCancel(): void {
    this.onClose.next(false);
  }

  sendManualCorrection(template: TemplateRef<any>, file: FileCorrection){
      this.correctionModel.errors = file.manaualFix.errorCount!;
      this.correctionModel.costs = file.manaualFix.price!;
    this.modalRef = this.modalService.show(template, {class: 'modal-lg'});
    const req =  this.onClose.subscribe((result: boolean) => {
      if(result){
        this.service.SendCorrectialRequest(file.manaualFix.acceptOfferLink!).subscribe( data => {
          this.fetchData();
          this.parentEmitter.emit('update');
          this.toastr.success("Vielen Dank für Ihren Auftrag!", "");
        }, error => {
          this.toastr.error("Das Konto verfügt nicht über genügend Guthaben, um diese Transaktion durchzuführen.", "");
        });
        req.unsubscribe();
        this.modalRef?.hide();
      } else{
        req.unsubscribe();
        this.modalRef?.hide();
      }
    });
  }

  sendAutoCorrection(template: TemplateRef<any>, file: FileCorrection){
    this.correctionModel.errors = file.automaticFix.errorCount!;
    this.correctionModel.costs = file.automaticFix.price!;
    this.modalRef = this.modalService.show(template, {class: 'modal-lg'});
    const req = this.onClose.subscribe((result: boolean) => {
      if(result){
       this.service.SendCorrectialRequest(file.automaticFix.acceptOfferLink!).subscribe( data => {
          this.fetchData();
          this.parentEmitter.emit('update');
          this.toastr.success("Vielen Dank für Ihren Auftrag!", "");
        }, error => {
         this.toastr.error("Das Konto verfügt nicht über genügend Guthaben, um diese Transaktion durchzuführen.", "");
       });
        req.unsubscribe();
        this.modalRef?.hide();
      } else{
        req.unsubscribe();
        this.modalRef?.hide();
      }
    });
  }

  changeLabel(template: TemplateRef<any>, file: FileCorrection){
    this.label = file.label!;
    this.modalRef = this.modalService.show(template, {class: 'modal-lg'});
   const req = this.onClose.subscribe((result: boolean) => {
      if(result){
        this.service.EditLabelFile(this.label, file.uploadId).subscribe( data => {
          file.label = this.label;
        });
        req.unsubscribe();
        this.modalRef?.hide();
      } else{
        req.unsubscribe();
        this.modalRef?.hide();
      }
    });

  }
  removeFile(template: TemplateRef<any>, file: FileCorrection){
    this.modalRef = this.modalService.show(template, {class: 'modal-md'});
    const req = this.onClose.subscribe((result: boolean) => {
      if(result){
      this.service.RemoveFile(file.uploadId).pipe(
          switchMap(_ => {
            return this.service.GetFiles(this.pagination, undefined, this.searchField, this.filterType);
          })
        ).subscribe( data => {
          this.files = data;
          this.allItems = data.totalCount;
        });
        req.unsubscribe();
        this.modalRef?.hide();
      } else{
        req.unsubscribe();
        this.modalRef?.hide();
      }
    });
  }

  getErrorLevelName(value: LevelErrorEnum){
      switch (value){
        case LevelErrorEnum.SehrGeringeFehler:
          return 'SEHR GERINGE FEHLER';
          break;
        case LevelErrorEnum.SehrGering:
          return 'Sehr Gering';
          break;
        case LevelErrorEnum.Gering:
          return 'Gering';
          break;
        case LevelErrorEnum.Mittel:
          return 'Mittel';
          break;
        case LevelErrorEnum.Hoch:
          return 'Hoch';
          break;
        case LevelErrorEnum.SehrHoch:
          return 'Sehr Hoch'
        break;
      }
  }

  getErrorLevelClass(value: LevelErrorEnum){
    switch (value){
      case LevelErrorEnum.SehrGeringeFehler:
        return 'status-keine-fehle';
        break;
      case LevelErrorEnum.SehrGering:
        return 'status-gering';
        break;
      case LevelErrorEnum.Gering:
        return 'status-gering';
        break;
      case LevelErrorEnum.Mittel:
        return 'status-mittel';
        break;
      case LevelErrorEnum.Hoch:
        return 'status-hoch';
        break;
      case LevelErrorEnum.SehrHoch:
        return 'status-hoch'
        break;
    }
  }
  createNewUpload(){
    this.modalService.show(UploadModalComponent, {class: 'modal-lg'}).onHide!.subscribe( x => {
      this.fetchData();
        this.parentEmitter.emit('Update');
      }
    );
  }

  ngOnDestroy(){
      this.subscription.unsubscribe();
  }

  closePopup(){
    this.popupShow = false;
   window.sessionStorage.setItem(POPUP_SHOWN, 'true');
  }
}
