import { Component, TemplateRef } from '@angular/core';
import { StatisticService } from '../../services/statistic.service';
import { Statistic } from '../../models/statistic';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-statistic-page',
  templateUrl: './statistic-page.component.html',
  styleUrls: ['./statistic-page.component.scss'],
})
export class StatisticPageComponent {
  isLoading = true;
  isNoData = true;
  statistic: Statistic | undefined;
  modalRef?: BsModalRef;
  date = new Date();
  fitContainer: boolean = false;

  // options for the chart
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = false;
  showXAxisLabel = false;
  showYAxisLabel = false;
  timeline = true;
  doughnut = true;
  colorScheme: any = {
    domain: ['#005ECC', '#009B51', '#85D077', '#FDD07A', '#F68769', '#E63535'],
  };
  public mainPipes: any[] = [];
  public connectionPipes: any[] = [];
  public manholesPipes: any[] = [];
  constructor(
    private service: StatisticService,
    private modalService: BsModalService
  ) {
    this.getStatistic();
  }

  private getStatistic(): void {
    this.service.GetStatistic().subscribe({
      next: (data) => {
        this.isNoData = false;
        this.statistic = data;
        this.isLoading = false;
        this.statistic.conditionMainPipes.forEach((value, i) => {
          if (i < 6)
            this.mainPipes.push({
              name: i,
              value: value,
            });
        });
        this.statistic.conditionConnectionPipes.forEach((value, i) => {
          if (i < 6)
            this.connectionPipes.push({
              name: i,
              value: value,
            });
        });
        console.log(61, this.statistic.conditionManholes);
        this.statistic.conditionManholes.forEach((value, i) => {
          if (i < 6)
            this.manholesPipes.push({
              name: i,
              value: value,
            });
        });
      },
      error: () => {
        this.isNoData = true;
        this.isLoading = false;
      },
    });
  }

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template);
  }

  dateTickFormatting(val: any): number {
    return val.toLocaleString('de-DE');
  }

  yAxisTickFormattingM(value: number): string {
    if (value >= 1000) {
      const parts = value.toFixed(0).toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      const val = parts.join('.');
      return `${val} m`;
    }
    return `${value.toString()} m`;
  }

  yAxisTickFormattingSt(value: number): string {
    if (value >= 1000) {
      const parts = value.toFixed(0).toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      const val = parts.join('.');
      return `${val} St`;
    }
    return `${value.toString()} St`;
  }
}
