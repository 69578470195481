import { Pipe, PipeTransform } from '@angular/core';
import {
  ADMIN_ROLE, DATA_EXPORT_ROLE, MAPS_GROUP, MAPS_ROLE,
  QUESTIONNAIRE_ROLE,
  SEWER_DASHBOARD_ROLE,
  SEWER_TINDER_ROLE, SEWER_TINDER_v2_ROLE, SEWER_TINDER_v3_ROLE,
  UPLOAD_ROLE, WASTE_WATER_GROUP
} from "../../../_helpers/Constants";

@Pipe({
  name: 'roleName'
})
export class RoleNamePipe implements PipeTransform {

  transform(value: string): any {
    switch (value){
      case ADMIN_ROLE:
        return {'Role':'Admin', 'Group': 'Administration'} ;
        break;
      case UPLOAD_ROLE:
        return {'Role':'DataUpload', 'Group': WASTE_WATER_GROUP} ;
        break;
      case SEWER_TINDER_ROLE:
        return {'Role':'ImmediateActions', 'Group': WASTE_WATER_GROUP} ;
        break;
      case SEWER_DASHBOARD_ROLE:
        return {'Role':'Dashboard', 'Group': WASTE_WATER_GROUP} ;
        break;
      case QUESTIONNAIRE_ROLE:
        return {'Role':'Questions', 'Group': WASTE_WATER_GROUP} ;
        break;
      case MAPS_ROLE:
        return {'Role':'Maps', 'Group': MAPS_GROUP} ;
        break;
      case DATA_EXPORT_ROLE:
        return {'Role':'DataExport', 'Group': WASTE_WATER_GROUP} ;
        break;
      case SEWER_TINDER_v2_ROLE:
        return {'Role':'RenovationMeasures', 'Group': WASTE_WATER_GROUP} ;
        break;
      case SEWER_TINDER_v3_ROLE:
        return {'Role':'DamageEvaluation', 'Group': WASTE_WATER_GROUP} ;
        break;
      default:
        return {'Role':'User', 'Group': 'Administration'} ;
    }
  }

}
