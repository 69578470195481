import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-confirmmodal',
  templateUrl: './confirmmodal.component.html',
  styleUrls: ['./confirmmodal.component.scss']
})
export class ConfirmmodalComponent {
  public onClose: Subject<boolean> = new Subject();
  constructor(public bsModalRef: BsModalRef) {}
  
  confirm(): void {
    this.onClose.next(true);
    this.bsModalRef?.hide();
  }
 
  decline(): void {
    this.onClose.next(false);
    this.bsModalRef?.hide();
  }
}
