<div class="text-center">
  <img src="./assets/Images/Logo.png">
</div>
<div class="mobile-response">
  <div class="card col-lg-3 col-md-11 col-11 mx-auto">
    <div class="card-body">
<h4>Anmeldung</h4>
      <div *ngIf="isError" style="margin-top: 1.75rem" class="card validation-card">
        <i class="icon-IconExclamation-Circle position-absolute"></i> <span style="margin-left:3rem; color:red;">Falsche Email oder falsches Passwort</span>
      </div>
      <form [formGroup]="loginForm" style="margin-top: 1.75rem" (ngSubmit)="onSubmit()">
        <label class="form-label w-100">
          Email
          <input type="text" formControlName="email" class="form-control" placeholder="Ihre Email"
                 [ngClass]="isError || loginForm.get('email')!.hasError('required') && loginForm.get('email')!.touched ? 'invalid' : ''"
                 [style]="loginForm.get('email')!.hasError('required') && loginForm.get('email')!.touched ? 'padding-left: 2.25rem;' : '' "
          >
          <i *ngIf="loginForm.get('email')!.hasError('required') && loginForm.get('email')!.touched" class="icon-IconCancel"></i>
          <span *ngIf="loginForm.get('email')!.hasError('required') && loginForm.get('email')!.touched" style="color: red; font-size: 12px;" class="mb-3">Dieses Feld ist erforderlich.</span>
        </label>
        <label class="form-label w-100 mt-3">
          Passwort
          <input type="password" formControlName="password" class="form-control" placeholder="*****"
                 [ngClass]="isError || loginForm.get('password')!.hasError('required') && loginForm.get('password')!.touched ? 'invalid' : ''"
                 [style]="loginForm.get('password')!.hasError('required') && loginForm.get('password')!.touched ? 'padding-left: 2.25rem;' : '' ">
          <i *ngIf="loginForm.get('password')!.hasError('required') && loginForm.get('password')!.touched" class="icon-IconCancel"></i>
          <span *ngIf="loginForm.get('password')!.hasError('required') && loginForm.get('password')!.touched" style="color: red; font-size: 12px;" class="mb-3">Dieses Feld ist erforderlich.</span>
        </label>
        <button type="submit" class="btn btn-primary w-100" style="margin-top: 2rem; height: 2.25rem;" [disabled]="loginForm.invalid">Anmeldung</button>
      </form>
    </div>
  </div>
<div class="text-center mt-md-4 link">
  <span class="link-btn" (click)="GoToForgotPassword()">Passwort vergessen </span> |
  <span class="link-btn" (click)="GoToRegister()"> Registrieren</span>
</div>
</div>
<footer>
  <a [href]="'https://www.ing-plus.de/datenschutz'" target="_blank" style="text-decoration: none" class="link-btn">Datenschutzerklärung   </a> |
  <a [href]="'https://www.ing-plus.de/impressum'" target="_blank" style="text-decoration: none"  class="link-btn">   Impressum </a>
</footer>

