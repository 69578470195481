<div class="modal-body">
  <i class="icon-IconClose" (click)="decline()"></i>
  <h5>{{name}}</h5>
  <div class="row" style="margin-top: 1.25rem;">
    <div class="col-lg-9 col-12">
      <div style="font-size: 14px;" [innerHTML]="description"></div>
    </div>
    <div class="col-lg-3 col-12">
      <img *ngIf="image" [src]="image">
      <div class="date">
        <i class="icon-IconCalendar"></i>
        <span class="ms-2">{{startDate| date: 'd MMMM, y'}}</span>
      </div>
      <div *ngIf="withTime" class="date" style="margin-top: 0.75rem">
        <i class="icon-IconTime"></i>
        <span class="ms-2">
          <span>{{startDate| date: 'shortTime'}} </span>
          <span *ngIf="isOneDay">- {{ endDate | date: 'shortTime'}} </span>
        </span>
      </div>

    </div>
  </div>

</div>
