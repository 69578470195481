import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthInfo } from '../_models/AuthInfo';
import { ILoginByCode } from '../_models/loginByCode';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthUploadUserService {
  constructor(private http: HttpClient) {}

  checkCode(model: ILoginByCode): Observable<boolean> {
    return this.http.post<boolean>(`${environment.apiUrl}CheckCode`, model);
  }

  loginByCode(model: ILoginByCode): Observable<string> {
    return this.http.post<string>(`${environment.apiUrl}LoginByCode`, model);
  }
}
