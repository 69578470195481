<div class="card h-100" [style]=" isLoading ? null : 'padding: 20px; border-radius: 12px;'">
  <img *ngIf="isLoading" class="loading-gif" src="./assets/Images/SkeletonPreloader.gif" />
  <div *ngIf="!isLoading">
  <h5 style="margin-bottom: 0.75rem;">Kommende Veranstaltungen</h5>
  <span *ngIf="userEvents && userEvents.length == 0" class="m-auto">Hier haben Sie die Möglichkeit, die Ereignisse zu beobachten.</span>
  <div *ngFor="let userEvent of userEvents" class="mt-2" style="cursor: pointer;" (click)="PreviewEvent(userEvent)">
    <div class="row">
      <div class="col-lg-2 col-md-3 col-sm-2 col-3">
        <div class="calendar-event">
          <div style="font-size: 24px;line-height: 20px;">{{userEvent.startDate | date: 'd'}}</div>
          <div style="font-size: 12px;line-height: 13px;">{{userEvent.startDate | date: 'MMM'}}</div>
        </div>
      </div>
      <div class="col-lg-10 col-md-9 col-sm-10 col-9" style="overflow: hidden; text-overflow: ellipsis;">
        <span style="font-size: 14px; font-weight: bold; white-space: nowrap">{{userEvent.name}}</span>
        <div *ngIf="checkDate(userEvent.startDate, userEvent.endDate)"
             style="font-size: 13px;">{{userEvent.startDate | date: 'HH:mm'}} - {{userEvent.endDate | date: 'HH:mm'}}</div>
      </div>
    </div>
  </div>
  </div>
</div>
