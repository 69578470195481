import jwt_decode from 'jwt-decode';
import { environment } from '../../environments/environment';
import { ADMIN_ROLE, QUESTIONNAIRE_ROLE } from './Constants';
import { TokenService } from '../_services/token.service';
import { inject } from '@angular/core';
import { Router } from '@angular/router';

export const questionnaireGuard = () => {
  const service = inject(TokenService);
  const router = inject(Router);
  const token = service.getToken();
  if (token != null) {
    const decoded: any = jwt_decode(token);
    if (
      decoded[environment.roleKey].includes(ADMIN_ROLE) ||
      decoded[environment.roleKey].includes(QUESTIONNAIRE_ROLE)
    ) {
      return true;
    }
  }
  router.navigateByUrl('/');
  return false;
};
