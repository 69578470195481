export const environment = {
  production: false,
  accountName: 'ingfiles',
  containerName: 'filesstage',
  key: 'F+ZK4Iq/NbuZQjLtPaGhVKpuzWgFmGHFEbVgjaOHR+q3eKqn4x+SJvvxVJcs97xPyiTvgRRhdAU6+AStjUS+WQ==',
  sasGeneratorUrl: 'https://ingfiles.blob.core.windows.net',
  roleKey: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role',
  usernameKey: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
  apiUrl: 'https://test.ing-plus.de:81/api/',
  apiSignalRUrl: 'https://test.ing-plus.de:81/NotificationHub?userId=',
  apiUrlGISService: 'https://app.ing-plus.de/GISServiceStaging/api/',
  upload: {
    FileDetailUrl:
      'https://app.ing-plus.de/IsybauManagementStaging/api/webapp/filedetails/',
    RegisterUploadedFile:
      'https://app.ing-plus.de/IsybauManagementStaging/api/webapp/registeruploadsisybauxmlfile',
    AIUrl:
      'https://app.ing-plus.de/IsybauManagementStaging/api/webapp/registerupload',
  },
  Evaluation: {
    TenantUrl:
      'https://app.ing-plus.de/dashboardapiStaging/api/webapp/usertenants',
    EvaluationUrl:
      'https://app.ing-plus.de/GISServiceStaging/api/sofortmassnahmen/next?tenant=',
    EvaluationPreviousUrl:
      'https://app.ing-plus.de/GISServiceStaging/api/sofortmassnahmen/last?tenant=',
    PositiveEvaluation:
      'https://app.ing-plus.de/GISServiceStaging/api/sofortmassnahmen/evaluatePositive',
    NegativeEvaluation:
      'https://app.ing-plus.de/GISServiceStaging/api/sofortmassnahmen/evaluateNegative',
    UnclearEvaluation:
      'https://app.ing-plus.de/GISServiceStaging/api/sofortmassnahmen/evaluateUnclear',
  },
  EvaluationV2: {
    EvaluationUrl: 'https://app.ing-plus.de/GISServiceStaging/api/ingkuerzel/',
  },
  EvaluationV3: {
    EvaluationUrl:
      'https://app.ing-plus.de/GISServiceStaging/api/einzelfallbetrachtung/',
  },
  TestProtocol: {
    SubscriptionDetailsUrl:
      'https://app.ing-plus.de/isybauxmlfixerStaging/api/WebApp/SubscriptionOverview',
    FilesUrl:
      'https://app.ing-plus.de/isybauxmlfixerStaging/api/WebApp/FileOverview?',
    containerName: 'testprotocol',
    RegisterFile:
      'https://app.ing-plus.de/isybauxmlfixerStaging/api/WebApp/Register',
    ChangeLabelUrl:
      'https://app.ing-plus.de/isybauxmlfixerStaging/api/WebApp/Rename',
    RemoveFile:
      'https://app.ing-plus.de/isybauxmlfixerStaging/api/WebApp/DeleteFile?UploadId=',
  },
  Tenant: {
    ApiKey: 'F347F6E4-2A8B-40BF-AF10-81D99E0D38BA',
    AllTenantsUrl:
      'https://app.ing-plus.de/dashboardapiStaging/api/adminpanel/tenants',
    AssignTenantUrl:
      'https://app.ing-plus.de/dashboardapiStaging/api/adminpanel/settenants',
  },
  Wheather: {
    ApiCall: 'https://api.weatherapi.com/v1/forecast.json?key=',
    Key: 'e85a54ae767748bb8d6154924231907',
  },
  Intercom: {
    Api: 'https://api-iam.intercom.io',
    ApiId: 'gaf8dkxj',
  },
  StatisticUrl:
    'https://app.ing-plus.de/GISServiceStaging/api/dashboard/overview?',
  DemoTenant: {
    Name: 'Demos_IngPlus',
    Source: 'IngPlusDev',
    DisplayName: 'Demo',
    AssignDemoTenantUrl:
      'https://app.ing-plus.de/dashboardapiStaging/api/webapp/assigndemotenant',
  },
  Maps: {
    ApiKey: 'FC94B2B5-DE21-4A94-B84A-363A8B374E45',
  },
  DataExport: {
    ListGroupsUrl:
      'https://app.ing-plus.de/maeimportstaging/api/webapp/overview?tenant=',
    RequestExport:
      'https://app.ing-plus.de/maeimportstaging/api/webapp/createandstart',
    ExportList: 'https://app.ing-plus.de/maeimportstaging/api/webapp/exportlist'
  },
};
