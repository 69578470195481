import { Component, TemplateRef } from '@angular/core';
import {
  debounceTime,
  distinctUntilChanged,
  Subject,
  switchMap,
  tap,
} from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UserManagementService } from '../../services/user-management.service';
import { PagedUserManagement } from '../../models/PagedUserManagement';
import { TenantService } from '../../../../_services/tenant.service';
import { Tenant } from '../../../../_models/tenant';
import { UserManagementDTO } from '../../models/UserManagementDTO';
import { Role } from '../../models/Role';
import { AuthService } from '../../../../_services/auth.service';
import { AssignTenantDTO } from '../../../../_models/AssignTenantDTO';
import {
  ADMIN_ROLE,
  CONSTRUCTION_SITE_ROLE,
  CurrentNumberWasteWaterGroup,
  DATA_EXPORT_ROLE,
  MAPS_GROUP,
  MAPS_ROLE,
  QUESTIONNAIRE_ROLE,
  SEWER_DASHBOARD_ROLE,
  SEWER_TINDER_ROLE,
  SEWER_TINDER_v2_ROLE,
  SEWER_TINDER_v3_ROLE,
  UPLOAD_ROLE,
  USER_ROLE,
  WASTE_WATER_GROUP,
} from '../../../../_helpers/Constants';

@Component({
  selector: 'app-user-managment-page',
  templateUrl: './user-management-page.component.html',
  styleUrls: ['./user-management-page.component.scss'],
})
export class UserManagementPageComponent {
  isLoading = true;
  search = new Subject<string>();
  searchField = '';
  pagination: number = 1;
  allItems: number = 0;
  modalRef?: BsModalRef;
  users: PagedUserManagement | undefined;
  public onClose: Subject<boolean> = new Subject();
  tenants: Tenant[] = [];
  roles: Role[] = [];
  selectedUser: UserManagementDTO | undefined;
  filteredRole = '';
  popHide: any;
  groupWasteWater = WASTE_WATER_GROUP;
  groupMaps = MAPS_GROUP;
  rolesForView: any[] = [];

  filterRoles = [
    { label: 'Admin', value: ADMIN_ROLE },
    { label: 'User', value: USER_ROLE },
    { label: 'DataUpload', value: UPLOAD_ROLE },
    { label: 'ImmediateActions', value: SEWER_TINDER_ROLE },
    { label: 'Dashboard', value: SEWER_DASHBOARD_ROLE },
    { label: 'Questions', value: QUESTIONNAIRE_ROLE },
    { label: 'Maps', value: MAPS_ROLE },
    { label: 'DataExport', value: DATA_EXPORT_ROLE },
    { label: 'RenovationMeasures', value: SEWER_TINDER_v2_ROLE },
    { label: 'DamageEvaluation', value: SEWER_TINDER_v3_ROLE },
    { label: 'All', value: 'All' },
  ];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: UserManagementService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private tenantService: TenantService,
    private authService: AuthService
  ) {
    this.fetchData();
    this.search
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        tap((value) => (this.searchField = value)),
        switchMap(() =>
          this.service.GetUsers(
            1,
            undefined,
            this.searchField,
            this.filteredRole
          )
        )
      )
      .subscribe((data) => {
        this.pagination = 1;
        this.users = data!;
        this.allItems = data!.totalCount;
      });
    this.tenantService.GetAllTenants().subscribe((data) => {
      this.tenants = data;
    });
    this.service.GetRoles().subscribe((data) => {
      this.roles = data;
      this.setRoleLabel();
    });
  }

  fetchData() {
    this.service
      .GetUsers(this.pagination, undefined, this.searchField, this.filteredRole)
      .subscribe((data) => {
        this.users = data;
        this.allItems = data.totalCount;
        this.isLoading = false;
      });
  }

  renderPage(event: number) {
    this.pagination = event;
    this.fetchData();
  }

  public onConfirm(): void {
    this.onClose.next(true);
  }

  public onCancel(): void {
    this.onClose.next(false);
  }

  filterByType(type: string) {
    this.filteredRole = type == 'All' ? '' : type;
    this.pagination = 1;
    this.fetchData();
  }

  selectTenant(template: TemplateRef<any>) {
    this.popHide.hide();
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
    const req = this.onClose.subscribe((result: boolean) => {
      if (result) {
        const assignTenants: AssignTenantDTO[] = [];
        this.selectedUser?.userTenants.forEach((ut) => {
          assignTenants.push({
            Name: ut.tenantName,
            Source: ut.source == null ? ut.serverName : ut.source,
          });
        });
        this.tenantService
          .AssignTenants(this.selectedUser?.userId!, assignTenants)
          .subscribe((x) => console.log(x));
        this.modalRef?.hide();
      } else {
        req.unsubscribe();
        this.modalRef?.hide();
        this.fetchData();
      }
    });
  }

  selectRole(template: TemplateRef<any>) {
    this.popHide.hide();
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
    const req = this.onClose.subscribe((result: boolean) => {
      if (result) {
        this.service
          .UpdateRoles(this.selectedUser?.userId!, this.selectedUser?.roles!)
          .subscribe((_) => {
            this.fetchData();
          });
        req.unsubscribe();
        this.modalRef?.hide();
      } else {
        req.unsubscribe();
        this.modalRef?.hide();
        this.fetchData();
      }
    });
  }

  resetPassword(template: TemplateRef<any>) {
    this.popHide.hide();
    this.modalRef = this.modalService.show(template, { class: 'modal-md' });
    const req = this.onClose.subscribe((result: boolean) => {
      if (result) {
        this.authService
          .ResetPasswordByAdmin(this.selectedUser?.userId!)
          .subscribe();
        req.unsubscribe();
        this.modalRef?.hide();
      } else {
        req.unsubscribe();
        this.modalRef?.hide();
      }
    });
  }

  populateDropdownList(pop: any, user: UserManagementDTO) {
    this.selectedUser = user;
    this.popHide = pop;
  }

  checkUserTenant(tenantName: string) {
    return this.selectedUser?.userTenants.find(
      (us) => us.tenantName == tenantName
    );
  }

  checkRole(role: string) {
    return this.selectedUser?.roles.find((r) => r == role);
  }
  checkMapsRoles() {
    return this.selectedUser?.roles.find((r) => r == MAPS_ROLE);
  }
  checkWasteWaterRoles() {
    let checkRoles = this.selectedUser?.roles.filter(
      (r) =>
        r == UPLOAD_ROLE ||
        r == SEWER_TINDER_ROLE ||
        r == SEWER_DASHBOARD_ROLE ||
        r == QUESTIONNAIRE_ROLE ||
        r == DATA_EXPORT_ROLE ||
        r == SEWER_TINDER_v2_ROLE ||
        r == SEWER_TINDER_v3_ROLE ||
        r == CONSTRUCTION_SITE_ROLE
    );
    return checkRoles!.length == CurrentNumberWasteWaterGroup;
  }

  checkSelectionTenant(e: any, tenant: Tenant) {
    if (e.checked) {
      this.selectedUser?.userTenants.push(tenant);
    } else {
      this.selectedUser!.userTenants = this.selectedUser!.userTenants.filter(
        (t) => t.tenantName !== tenant.tenantName
      );
    }
  }
  checkSelectionRole(e: any, role: string) {
    if (e.checked) {
      this.selectedUser?.roles.push(role);
    } else {
      this.selectedUser!.roles = this.selectedUser!.roles.filter(
        (r) => r !== role
      );
    }
  }

  checkSelectionWasteWaterRoles(e: any) {
    if (e.checked) {
      this.selectedUser!.roles = this.selectedUser!.roles.filter(
        (r) =>
          r != UPLOAD_ROLE &&
          r != SEWER_TINDER_ROLE &&
          r != SEWER_DASHBOARD_ROLE &&
          r != QUESTIONNAIRE_ROLE &&
          r != DATA_EXPORT_ROLE &&
          r != SEWER_TINDER_v2_ROLE &&
          r != SEWER_TINDER_v3_ROLE &&
          r != CONSTRUCTION_SITE_ROLE
      );
      for (const element of this.roles) {
        if (
          element.name != ADMIN_ROLE &&
          element.name != USER_ROLE &&
          element.name != MAPS_ROLE
        )
          this.selectedUser?.roles.push(element.name);
      }
    } else {
      this.selectedUser!.roles = this.selectedUser!.roles.filter(
        (r) =>
          r != UPLOAD_ROLE &&
          r != SEWER_TINDER_ROLE &&
          r != SEWER_DASHBOARD_ROLE &&
          r != QUESTIONNAIRE_ROLE &&
          r != DATA_EXPORT_ROLE &&
          r != SEWER_TINDER_v2_ROLE &&
          r != SEWER_TINDER_v3_ROLE &&
          r != CONSTRUCTION_SITE_ROLE
      );
    }
  }

  checkSelectionMapRole(e: any) {
    if (e.checked) {
      this.selectedUser?.roles.push(MAPS_ROLE);
    } else {
      this.selectedUser!.roles = this.selectedUser!.roles.filter(
        (r) => r !== MAPS_ROLE
      );
    }
  }

  private setRoleLabel() {
    this.roles.forEach((r) => {
      switch (r.name) {
        case ADMIN_ROLE:
          this.rolesForView.push({
            label: 'Admin',
            group: 'Administration',
            value: r.name,
          });
          break;
        case UPLOAD_ROLE:
          this.rolesForView.push({
            label: 'DataUpload',
            group: WASTE_WATER_GROUP,
            value: r.name,
          });
          break;
        case SEWER_TINDER_ROLE:
          this.rolesForView.push({
            label: 'ImmediateActions',
            group: WASTE_WATER_GROUP,
            value: r.name,
          });
          break;
        case SEWER_DASHBOARD_ROLE:
          this.rolesForView.push({
            label: 'Dashboard',
            group: WASTE_WATER_GROUP,
            value: r.name,
          });
          break;
        case QUESTIONNAIRE_ROLE:
          this.rolesForView.push({
            label: 'Questions',
            group: WASTE_WATER_GROUP,
            value: r.name,
          });
          break;
        case MAPS_ROLE:
          this.rolesForView.push({
            label: 'Maps',
            group: MAPS_GROUP,
            value: r.name,
          });
          break;
        case DATA_EXPORT_ROLE:
          this.rolesForView.push({
            label: 'DataExport',
            group: WASTE_WATER_GROUP,
            value: r.name,
          });
          break;
        case SEWER_TINDER_v2_ROLE:
          this.rolesForView.push({
            label: 'RenovationMeasures',
            group: WASTE_WATER_GROUP,
            value: r.name,
          });
          break;
        case SEWER_TINDER_v3_ROLE:
          this.rolesForView.push({
            label: 'DamageEvaluation',
            group: WASTE_WATER_GROUP,
            value: r.name,
          });
          break;
        case CONSTRUCTION_SITE_ROLE:
          this.rolesForView.push({
            label: 'ConstructionSite',
            group: WASTE_WATER_GROUP,
            value: r.name,
          });
          break;
        default:
          this.rolesForView.push({
            label: 'User',
            group: 'Administration',
            value: r.name,
          });
      }
    });
  }
}
