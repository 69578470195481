export enum NotificationType {
  Datenupload,
  Fragen,
  Datenprüfung,
  Sofortmaßnahmen
}

export enum NotificationIconsType {
  'icon-IconUpload',
  'icon-IconQuestionary',
  'icon-IconTest',
  'icon-IconSewer'
}

export enum NotificationNavigateType {
  './wastewater/upload-content',
  './questionnaire-user',
  './',
  './wastewater/immediate-actions'
}