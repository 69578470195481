import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginDTO } from '../../_models/Login';
import { AuthService } from '../../_services/auth.service';
import { Router } from '@angular/router';
import { TokenService } from '../../_services/token.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  loginForm: FormGroup;
  isError: boolean = false;
  model: LoginDTO = {
    email: '',
    password: '',
  };

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private tokenStorage: TokenService
  ) {
    this.loginForm = this.fb.group({
      email: [this.model.email, Validators.required],
      password: [this.model.password, Validators.required],
    });
  }

  onSubmit() {
    if (this.loginForm.valid) {
      this.model.email = this.loginForm.controls['email'].value;
      localStorage.setItem('userEmail', this.loginForm.controls['email'].value);
      this.model.password = this.loginForm.controls['password'].value;
      this.authService.Login(this.model).subscribe((data) => {
        if (!data.success) {
          this.isError = true;
        } else {
          this.tokenStorage.saveToken(data.accessToken);
          this.tokenStorage.saveRefreshToken(data.refreshToken);
          this.tokenStorage.saveUser(
            data!.user.id,
            data!.user.firstName == null || ''
              ? data!.user.email
              : data!.user.firstName + ' ' + data!.user.lastName
          );
          this.router.navigateByUrl('/home').then(() => {
            window.location.reload();
          });
        }
      });
    }
  }

  GoToRegister() {
    this.router.navigateByUrl('/signup');
  }

  GoToForgotPassword() {
    this.router.navigateByUrl('/recovery-password');
  }
}
