<div class="modal-body">
    <h5>Prüfbericht erstellen</h5>
  <i class="icon-IconClose" (click)="decline()"></i>
  <label class="form-label w-100 mt-4" style="font-size: 12px;">
    Projektname (optional)
    <input type="text" [(ngModel)]="label"  class="form-control" placeholder="Namen eingeben" maxlength="128">
  </label>
  <div style="display: flex;align-items: baseline;" class="position-relative">
  <div class="file btn btn-lg btn-outline-light mt-3">
    Dateien auswählen
    <input type="file" accept="text/xml" [disabled]="isUploadProcess" name="file" (change)="onFileChange($event)"/>
  </div>
    <span *ngIf="currentFile" class="ms-3 w-100" style="font-size: 14px;"> <span class="file-name">{{currentFile.name}} </span> {{getSize(currentFile.size!)}}KB</span>
  <i *ngIf="currentFile" class="icon-IconDelete" (click)="removeFile()"></i>
  </div>
  <div class="progress mt-3">
    <div class="progress-bar" role="progressbar" [style.width.%]="uploadPersent" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
  </div>
  <div class="float-end mt-4" style="padding-bottom: 40px;">
    <button type="button" class="btn btn-primary" style="padding: 8px 24px;" [disabled]="isUploadProcess || !currentFile" (click)="confirm()">Hochladen</button>
  </div>
</div>
