<app-sidebar [isExpanded]="sidebarExpanded" (toggleSidebar)="sidebarExpanded = !sidebarExpanded"></app-sidebar>
<div [ngClass]="{'mobile': isMobile, 'desktop': !isMobile, 'dashboard-color': isDashboard}">
<header class="position-absolute w-100 mt-4">
  <div style="right: 2rem; z-index: 99" class="position-relative mt-1">
    <span class="float-end username"><span>{{username}}</span></span>
    <button type="button" class="btn btn-default btn-secondary float-end logout" [popover]="hearderTemplate"
            placement="bottom" #pop="bs-popover" [outsideClick]="true" containerClass="profile-popover-class">
      <i class="icon-IconUser-Profile"></i>
    </button>
    <app-notifications *ngIf="!router.url.includes('/admin')" class="notificaions-wrapper"></app-notifications>
  <button *ngIf="(tenantInnerService.tenants$ | async)!.length > 1 && !router.url.includes('/admin') && !(tenantInnerService.isLoading$ | async) && !isTablet" type="button"
    class="btn float-end tenant" [popover]="tenantTemplate" placement="bottom" #pop="bs-popover" [outsideClick]="true">
   <span>{{(tenantInnerService.curTenant$ | async)?.displayName}}</span>
    <i class="icon-IconArrow-Down" style="position: relative;top: -0.2rem;"></i>
  </button>
    <i class="icon-IconMarker icon-menu tenant-tablet-menu"
       *ngIf="(tenantInnerService.tenants$ | async)!.length > 1 && !router.url.includes('/admin') && isTablet"
       [popover]="tenantTemplate" placement="bottom" #pop="bs-popover" [outsideClick]="true">
      <i class="icon-IconArrow-Down"></i>
    </i>
  <span style="margin-top: 0.5rem" class="float-end tenant mx-3"
    *ngIf="(tenantInnerService.tenants$ | async)!.length <= 1 && !router.url.includes('/admin') && !(tenantInnerService.isLoading$ | async) && !isTablet">{{(tenantInnerService.curTenant$ | async)?.displayName}}</span>
  </div>
</header>
<div class="content position-relative" [ngClass]="{ 'sidebarExpanded': sidebarExpanded }">
  <div class="content-height" style="min-height: 88vh;">
    <router-outlet></router-outlet>
  </div>
  <footer class="mobile-display-none">
    <span>Ing Plus AG | © 2024 </span>
    <a target="_blank" href="https://ing-plus.de">ing-plus.de</a>
  </footer>
</div>
</div>
<video #pseudoVideo loop controls class="d-none">
  <source src="../../../assets/Video/video.mp4" type="video/mp4" />
</video>
<ng-template #hearderTemplate>
  <div class="profile-template">
    <p class="user-name m-0">{{username}}</p>
    <p class="border-bottom m-0 pb-4">{{userEmail}}</p>
    <ul class="m-0">
      <li (click)="manageAccount();pop.hide()"><i class="icon-IconSettings mr-2"></i>Konto verwalten</li>
      <li (click)="logout()"><i class="icon-IconUpload mr-2"></i> Logout</li>
    </ul>
  </div>
</ng-template>
<ng-template #tenantTemplate>
  <ul class="header-menu ps-0 w-100">
    <li *ngFor="let item of tenantInnerService.tenants$ | async" class="my-1 px-3 tenant-item"
      [ngClass]="item.tenantName == (tenantInnerService.curTenant$ | async)?.tenantName ? 'selected-tenant': ''">
      <span (click)="switchTenant(item);pop.hide()">{{item.displayName}}</span>
    </li>
  </ul>
</ng-template>
