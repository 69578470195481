import { Component } from '@angular/core';
import {TokenService} from "../../_services/token.service";
import {WeatherService} from "../../_services/weather.service";
import {Weather} from "../../_models/ForecastDay";

@Component({
  selector: 'app-weather-widget',
  templateUrl: './weather-widget.component.html',
  styleUrls: ['./weather-widget.component.scss']
})
export class WeatherWidgetComponent {
  weather: Weather | undefined;
constructor(private tokenService: TokenService, private weatherService: WeatherService) {
  const location = this.tokenService.GetTenantLocation();
  this.weatherService.GetWeather(location == 'null' ? 'Berlin' : location).subscribe(data => {
    this.weather = data;
  });
}

  getDayName(date: string) {
    const toDate = new Date(date);
    return toDate.toLocaleDateString('de-DE', {weekday: 'long'});
  }
}
