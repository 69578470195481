import { Component } from '@angular/core';
import {AuthService} from "../../_services/auth.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PasswordValidators} from "../../_helpers/validators/PasswordSpecificCharacter.validator";
import {passwordMatchingValidatior} from "../../_helpers/validators/PasswordMatch.validator";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {

  changePasswordForm: FormGroup;
  code: string = '';
  userId: string = '';
  isFormSend: boolean = false;
  constructor(private authService: AuthService, private router: Router,  private route: ActivatedRoute, private fb: FormBuilder) {
    this.changePasswordForm = this.fb.group({
      password: [null , [Validators.required, Validators.minLength(10),  PasswordValidators.patternValidator(new RegExp("(?=.*[$@^!%*?&])"), {
        requiresSpecialChars: true
      })]],
      passwordConfirm: [null, Validators.required]
    }, { validators: passwordMatchingValidatior, PasswordValidators });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: Params) => {
      this.code = params['code'];
      this.userId = params['userId'];
    });
  }

  onSubmit() {
    if (this.changePasswordForm.valid) {
      const password = this.changePasswordForm.controls['password'].value;
      this.authService.ChangePassword(this.code, this.userId, password).subscribe(_ => this.isFormSend = true
        , error => console.log(error));
    }
  }

  GoToLogin(){
    this.router.navigateByUrl('/login');
  }
}
