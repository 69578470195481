import { Injectable } from '@angular/core';
import { Upload } from '../models/Upload';
import {
  Status as FileOfUploadStatus,
  UploadFile,
} from '../models/FileOfUpload';
import { BehaviorSubject, Subject, take, tap } from 'rxjs';
import { UploadService } from './upload.service';
import { FormGroup } from '@angular/forms';
import { WakeLockService } from 'src/app/_services/wake-lock.service';

export enum Status {
  Open,
  Partially,
  Completed,
  Error,
  Uploading,
}

interface IUploadFile {
  file: File;
  uploadFilePath: any;
  containerClient: any;
}

@Injectable({
  providedIn: 'root',
})
export class UploadFileService {
  private maxFilesPerLoad = 20;
  private newFilesForUpload: UploadFile[] = [];
  abortController?: AbortController;
  signal?: AbortSignal;
  isPartialUploadLoading = false;
  filesAlreadyUpload$ = new BehaviorSubject<number>(0);
  filesShouldUpload$ = new BehaviorSubject<number>(0);
  xmlFileUpload$ = new Subject<Upload>();
  isFilesUploading$ = new BehaviorSubject<boolean>(false);
  xmlFilesUploaded: Upload[] = [];
  stopUploading$ = new BehaviorSubject<boolean>(false);
  uploadedFiles$ = new BehaviorSubject<string[]>([]);

  constructor(
    private uploadService: UploadService,
    private wakeLockService: WakeLockService
  ) {}

  private async uploadBrowserData(
    file: File,
    uploadFilePath: any,
    containerClient: any,
    isAdditionalFiles: boolean
  ): Promise<void> {
    console.log('Start upload');
    const client = containerClient.getBlockBlobClient(
      uploadFilePath +
        '/' +
        (file.webkitRelativePath == '' ? file.name : file.webkitRelativePath)
    );
    if (isAdditionalFiles) {
      await client.uploadBrowserData(file, {
        blockSize: 4 * 1024 * 1024, // 4MB block size
        concurrency: 20, // 20 concurrency
        blobHTTPHeaders: { blobContentType: file.type },
        abortSignal: this.signal,
      });
    } else {
      await client.uploadBrowserData(file, {
        blockSize: 4 * 1024 * 1024, // 4MB block size
        concurrency: 20, // 20 concurrency
        blobHTTPHeaders: { blobContentType: file.type },
      });
    }
  }

  private getNewFile(
    file: File,
    uploadFilePath: any,
    status: Status
  ): UploadFile {
    return {
      name: file.name,
      extension: file.name.split('.').pop(),
      status,
      id: undefined,
      sizeOfFile: file.size,
      uploadedPathId: uploadFilePath,
      filePath: file.webkitRelativePath,
    };
  }

  private async uploadAdditionalFile(
    file: File,
    uploadFilePath: any,
    containerClient: any
  ): Promise<void> {
    await this.uploadBrowserData(
      file,
      uploadFilePath,
      containerClient,
      true
    ).then(
      () => {
        let newFile = this.getNewFile(file, uploadFilePath, Status.Open);
        this.newFilesForUpload.push(newFile);
        console.log('Upload add file');
      },
      (error) => console.log('Azure error' + error)
    );
  }

  async uploadAdditionalFiles(
    files: File[],
    uploadFilePath: any,
    containerClient: any,
    uploadedCount: number
  ): Promise<void> {
    this.isFilesUploading$.next(true);
    this.wakeLockService.requestWakeLock();
    const arrayAdditionalFiles = files.sort((a, b) => a.size - b.size);
    let promisesArray: IUploadFile[] = [];
    let promisesArrayOfArray = [];
    this.newFilesForUpload = [];
    for (const file of arrayAdditionalFiles) {
      promisesArray.push({ file, uploadFilePath, containerClient });
    }
    const constantValue = 3221225472; // Constant value in MB
    const maxSizePerPart = 50; // Maximum number of files per part
    let currentPart: any[] = [];

    let currentPartSize = 0;

    for (const file of promisesArray) {
      if (
        currentPartSize + file.file.size <= constantValue &&
        currentPart.length < maxSizePerPart
      ) {
        currentPart.push(file);
        currentPartSize += file.file.size;
      } else {
        promisesArrayOfArray.push(currentPart);
        currentPart = [file];
        currentPartSize = file.file.size;
      }
    }

    // Push the last part if it's not empty
    if (currentPart.length > 0) {
      promisesArrayOfArray.push(currentPart);
    }

    let saveUploadFilesIndexes = [];
    for (let i = 1; i <= 10; i++) {
      saveUploadFilesIndexes.push(
        Math.ceil((promisesArrayOfArray.length / 10) * i)
      );
    }
    saveUploadFilesIndexes = [...new Set(saveUploadFilesIndexes)];
    this.abortController = new AbortController();
    this.signal = this.abortController.signal;
    for (let [index, ar] of promisesArrayOfArray.entries()) {
      if (this.stopUploading$.value) {
        break;
      }
      await Promise.all(
        ar.map((item) =>
          this.uploadAdditionalFile(
            item.file,
            item.uploadFilePath,
            item.containerClient
          )
        )
      ).catch(() => console.log('Upload gestoppt'));
      if (!this.stopUploading$.value) {
        const filesSize = ar.reduce(
          (partialSum, a) => partialSum + a.file.size,
          0
        );
        this.filesAlreadyUpload$.next(
          this.filesAlreadyUpload$.getValue() + filesSize
        );
        if (saveUploadFilesIndexes.includes(index)) {
          await this.uploadService
            .UploadFiles(this.newFilesForUpload)
            .pipe(
              take(1),
              tap(() => (this.newFilesForUpload = []))
            )
            .toPromise();
        }
        const uploadedFiles = this.uploadedFiles$.getValue();
        const newUploadedFiles = ar.map(
          (item) => item.file.webkitRelativePath as string
        );
        uploadedFiles.push(...newUploadedFiles);
        this.uploadedFiles$.next(uploadedFiles);
      }
    }
    if (this.newFilesForUpload.length) {
      await this.uploadService
        .UploadFiles(this.newFilesForUpload)
        .pipe(take(1))
        .toPromise();
    }
    this.wakeLockService.releaseWakeLock();
    this.uploadedFiles$.next([]);
  }

  async uploadMainFiles(
    xmlFiles: any[],
    containerClient: any,
    uploadFilePath: any,
    partCount: number,
    array: any[],
    uploadForm: FormGroup,
    uploadDate: Date,
    alreadyUploadedXml: Upload[],
    oldFiles?: any[],
    uploadModel?: Upload
  ): Promise<Upload> {
    this.isFilesUploading$.next(true);
    for (let i = 0; i < xmlFiles.length; i++) {
      const file = xmlFiles[i];
      await this.uploadBrowserData(
        file,
        uploadFilePath,
        containerClient,
        false
      ).catch((x) => console.log('Azure error' + x));
      this.filesAlreadyUpload$.next(
        this.filesAlreadyUpload$.getValue() + file.size
      );

      let currentPart = 0;
      if (partCount == 0) {
        currentPart = i + 1;
      }
      if (partCount == 1) {
        uploadModel!.name = uploadModel?.name + ' Teil1';
        uploadModel!.files?.forEach(
          (file) => (file.status = FileOfUploadStatus.Uploading)
        );
        await this.uploadService
          .CreateUpdateUpload(uploadModel!)
          .pipe(take(1))
          .toPromise();
      }
      if (partCount > 1) {
        currentPart = partCount + i;
      }
      const { v4: uuidv4 } = require('uuid');
      //Create model for upload and save upload
      uploadModel = {
        name:
          partCount >= 2 || xmlFiles.length >= 2
            ? uploadForm.controls['name'].value.split(' Teil')[0] +
              ' Teil' +
              currentPart
            : uploadForm.controls['name'].value,
        comment: uploadForm.controls['comment'].value,
        contractName: uploadForm.controls['contractName'].value,
        type: Number(uploadForm.controls['type'].value),
        uploadDate: uploadDate,
        filesTryToUpload: array.map((x) =>
          x.webkitRelativePath == '' ? x.name : x.webkitRelativePath
        ),
        id: uuidv4(),
        uploadedPathId: uploadFilePath,
      };
      let newFile = this.getNewFile(
        file,
        uploadFilePath,
        array.length == 0 ? Status.Open : Status.Partially
      );
      console.log(
        partCount >= 2 || xmlFiles.length >= 2
          ? uploadForm.controls['name'].value.split(' Teil')[0] +
              ' Teil' +
              currentPart
          : uploadForm.controls['name'].value
      );
      if (uploadModel.files == undefined || uploadModel.files!.length <= 0) {
        uploadModel.files = [];
      }
      uploadModel.files!.push(newFile);
      if (oldFiles != undefined && oldFiles!.length > 0) {
        oldFiles.forEach((f) => (f.id = undefined));
        uploadModel.files = uploadModel.files.concat(oldFiles);
      }
      uploadModel!.files?.forEach(
        (file) => (file.status = array.length > 0 ? FileOfUploadStatus.Uploading : FileOfUploadStatus.Open)
      );
      await this.uploadService
        .CreateUpdateUpload(uploadModel)
        .pipe(take(1))
        .toPromise()
        .then(async (data) => {
          this.xmlFileUpload$.next(data!);
          await this.uploadService
            .SendFileToAI(data?.files?.[0].id!)
            .pipe(take(1))
            .toPromise();
          alreadyUploadedXml.push(data as Upload);
        });
      console.log('Create upload');
    }
    return uploadModel!;
  }

  clearData(): void {
    this.newFilesForUpload = [];
    this.isFilesUploading$.next(false);
    this.filesAlreadyUpload$.next(0);
    this.filesShouldUpload$.next(0);
  }
}
