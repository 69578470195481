<div class="text-center">
  <img src="./assets/Images/Logo.png">
</div>
<div class="mobile-responsive">
<div *ngIf="!isFormSend" class="card col-lg-3 col-11 mx-auto">
  <div class="card-body">
    <h4>Passwort ändern</h4>
    <p>Geben Sie Ihr neues Passwort ein. Achten Sie darauf, dass es mindestens 10 Zeichen lang ist und mindestens 1 Sonderzeichen (?! ….) enthält.
      Bestätigen Sie Ihr neues Passwort, indem Sie es erneut eingeben.</p>
    <form [formGroup]="changePasswordForm" style="margin-top: 1.75rem" (ngSubmit)="onSubmit()">
      <label class="form-label w-100 mt-3">
        Neues Passwort
        <input type="password" formControlName="password" class="form-control" placeholder="*****"
               [style]="((changePasswordForm.errors?.['passwordMatching'] && changePasswordForm.get('passwordConfirm')!.touched ) || changePasswordForm.get('password')!.hasError('required')
                || changePasswordForm.get('password')!.hasError('minlength') || changePasswordForm.get('password')!.hasError('requiresSpecialChars'))
               && changePasswordForm.get('password')!.touched ? 'padding-left: 2.25rem;' : '' "
               [ngClass]="((changePasswordForm.errors?.['passwordMatching'] && changePasswordForm.get('passwordConfirm')!.touched) || changePasswordForm.get('password')!.hasError('required')
                || changePasswordForm.get('password')!.hasError('minlength') || changePasswordForm.get('password')!.hasError('requiresSpecialChars'))
               && changePasswordForm.get('password')!.touched ? 'invalid' : '' " minlength="10">
        <i *ngIf="((changePasswordForm.errors?.['passwordMatching'] && changePasswordForm.get('passwordConfirm')!.touched) || changePasswordForm.get('password')!.hasError('required')
                || changePasswordForm.get('password')!.hasError('minlength') || changePasswordForm.get('password')!.hasError('requiresSpecialChars'))
               && (changePasswordForm.get('password')!.touched || changePasswordForm.get('passwordConfirm')!.touched)" class="icon-IconCancel"></i>
        <span *ngIf="changePasswordForm.errors?.['passwordMatching'] && changePasswordForm.get('passwordConfirm')!.touched
         && !changePasswordForm.get('password')!.hasError('minlength') && !changePasswordForm.get('password')!.hasError('minlength')
        && !changePasswordForm.get('password')!.hasError('requiresSpecialChars')" style="color: red; font-size: 12px;font-weight: 600;">Die Passwörter sollten gleich sein</span>
        <span *ngIf="changePasswordForm.get('password')!.hasError('required') && changePasswordForm.get('password')!.touched" style="color: red; font-size: 12px; font-weight: 600;">Dieses Feld ist erforderlich.</span>
        <span *ngIf="changePasswordForm.get('password')!.hasError('minlength') && changePasswordForm.get('password')!.touched" style="color: red; font-size: 12px; font-weight: 600;">Ein Passwort muss aus mindestens zehn Zeichen bestehen.</span>
        <span *ngIf="changePasswordForm.get('password')!.hasError('requiresSpecialChars') && changePasswordForm.get('password')!.touched && !changePasswordForm.get('password')!.hasError('minlength')" style="color: red; font-size: 12px;" class="mb-3">Das Passwort sollte mindestens ein Sonderzeichen enthalten.</span>
      </label>
      <label class="form-label w-100 mt-3">
        Passwort bestätigen
        <input type="password" formControlName="passwordConfirm" class="form-control" placeholder="*****"
               [style]=" changePasswordForm.errors?.['passwordMatching'] && changePasswordForm.get('passwordConfirm')!.touched ? 'padding-left: 2.25rem;' : '' "
               [ngClass]="changePasswordForm.errors?.['passwordMatching'] && changePasswordForm.get('passwordConfirm')!.touched ? 'invalid' : '' ">
        <i *ngIf="changePasswordForm.errors?.['passwordMatching'] && changePasswordForm.get('passwordConfirm')!.touched" class="icon-IconCancel"></i>
        <span *ngIf="changePasswordForm.errors?.['passwordMatching'] && changePasswordForm.get('passwordConfirm')!.touched" style="color: red; font-size: 12px; font-weight: 600;">Die Passwörter sollten gleich sein</span>
      </label>
      <button type="submit" class="btn btn-primary w-100" style="margin-top: 2rem; height: 2.25rem;" [disabled]="changePasswordForm.invalid">Neues Passwort speichern</button>
    </form>
  </div>
</div>
  <div *ngIf="isFormSend" style="margin-top: 8rem;" class="card col-lg-3 col-11 mx-auto">
    <div class="card-body">
      <div class="text-center">
        <img class="done-img" src="./assets/Images/Done.png">
        <h4 style="margin-top: 2.5rem;">Passwort wurde erfolgreich geändert</h4>
        <small>
          Ihr Passwort wurde erfolgreich geändert. Sie können sich nun mit dem neuen Passwort und Ihrer Emailadresse anmelden.
        </small>
      </div>
      <button type="button" class="btn btn-primary w-100" style="margin-top: 2rem; height: 2.25rem;" (click)="GoToLogin()">Login</button>
    </div>
</div>
<div *ngIf="!isFormSend" class="text-center mt-md-4 link">
  <span class="link-btn" (click)="GoToLogin()">Zurück zum Login</span>
</div>
</div>
<footer>
  <a [href]="'https://www.ing-plus.de/datenschutz'" target="_blank" style="text-decoration: none" class="link-btn">Datenschutzerklärung   </a> |
  <a [href]="'https://www.ing-plus.de/impressum'" target="_blank" style="text-decoration: none"  class="link-btn">   Impressum </a>
</footer>

