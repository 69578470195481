<div [ngClass]="isMobile ? 'mobile' : 'desktop'">
<div class="toggle menu-button"[ngClass]="{ 'menu-hidden': isExpanded }"
  (click)="$event.stopPropagation();handleSidebarToggle()">
  <span class="icon-IconMenu"></span>
</div>
<img class="mobile-logo" src="./assets/Images/Logo.png">
<i class="icon-IconMarker icon-menu"
  *ngIf="tenants.length > 1 && !router.url.includes('/admin') && !isLoading" style="margin-right: 6.5rem;"
  [popover]="tenantTemplate" placement="bottom" #pop="bs-popover" [outsideClick]="true">
  <i class="icon-IconArrow-Down"></i>
</i>
<app-notifications *ngIf="!router.url.includes('/admin')" class="notificaions-wrapper mobile-view" ></app-notifications>
<i class="icon-IconUser-Profile icon-menu" [popover]="headerTemplate" containerClass="profile-popover-class" placement="bottom" #pop="bs-popover"
  [outsideClick]="true"></i>
<div class="sidebar" (clickOutside)="closeMenu()" [ngClass]="{ 'expanded': isExpanded }">
  <div class="header">
    <img (click)="goHome()" [src]="isExpanded ? './assets/Images/Logo.png' : './assets/Images/ING Logo.png'"
      [class]="isExpanded ? 'logo-expanded' : 'logo-collapsed'">
    <div class="toggle" [ngClass]="isTablet ? 'tablet-none' : ''" (click)="handleSidebarToggle()">
      <span class="chevron"></span>
    </div>
    <div class="toggle position-absolute close-mobile-icon" style="right: 1.25rem;" (click)="handleSidebarToggle()">
      <span class="icon-IconClose"></span>
    </div>
  </div>
  <div class="content menu">
    <div class="link">
      <div class="content" [routerLink]="isAdminView ? ['/admin'] : ['/']"
        [ngClass]="router.url === '/' || router.url === '/admin' ? 'active': ''">
        <div class="icon">
          <span class="icon-IconHome"></span>
        </div>
        <div class="title">Startseite</div>
      </div>
    </div>
    <!--User view-->
    <div class="link" *ngIf="!isAdminView && (isMapsRole || isAdminRole)">
      <div class="content" [routerLink]="['/maps']" [routerLinkActive]="'active'">
        <div class="icon">
          <span class="icon-IconMaps"></span>
        </div>
        <div class="title">Karten</div>
      </div>
    </div>
    <div *ngIf="!isAdminView && (isQuestionnairedRole || isAdminRole)" class="link">
      <div class="content" [routerLink]="['/questionnaire-user']" [routerLinkActive]="'active'">
        <div class="icon">
          <span class="icon-IconQuestionary"></span>
        </div>
        <div class="title">Fragen</div>
      </div>
    </div>
    <div *ngIf="!isAdminView" class="dropdown link" dropdown [autoClose]="!isExpanded" container="body">
      <div dropdownToggle class="dropdown-toggle content" [ngClass]="router.url.includes('/wastewater') ? 'active': ''">
        <div class="icon">
          <span class="icon-IconManhole"></span>
        </div>
        <div class="title">Abwasser</div>
        <i class="icon-IconPlus"></i>
      </div>
      <ul *dropdownMenu class="dropdown-menu pt-0" [style]="isExpanded ? '' : 'top:-3.25rem'" role="menu">
        <div *ngIf="!isExpanded" class="dropdown-header">Abwasser <i class="icon-IconPlus" dropdownToggle
            style="margin-left: 2rem; cursor: pointer"></i></div>
        <li *ngIf="isConstructiondRole || isAdminRole" role="menuitem"><a class="dropdown-item"
            [routerLink]="['/construction']" [routerLinkActive]="'active'">Baustellen</a></li>
        <li *ngIf="isSewerDashboardRole || isAdminRole" role="menuitem"><a class="dropdown-item"
            [routerLink]="['/wastewater/dashboard']" [routerLinkActive]="'active'">Dashboard</a></li>
        <li *ngIf="isUploadsRole || isAdminRole" role="menuitem"><a class="dropdown-item"
            [routerLink]="['/wastewater/upload-content']" [routerLinkActive]="'active'">Datenupload</a></li>
        <li *ngIf="isDataExportRole || isAdminRole" role="menuitem"><a class="dropdown-item"
            [routerLink]="['/wastewater/data-export']" [routerLinkActive]="'active'">Datenexport</a></li>
        <li role="menuitem"><a class="dropdown-item" [routerLink]="['/wastewater/data-check']"
            [routerLinkActive]="'active'">Datenprüfung</a></li>
        <li *ngIf="isSewerTinderRole || isAdminRole" role="menuitem"><a class="dropdown-item"
            [routerLink]="['/wastewater/immediate-actions']" [routerLinkActive]="'active'">Sofortmaßnahmen</a></li>
        <li *ngIf="isSewerTinderV2Role || isAdminRole" role="menuitem"><a class="dropdown-item"
            [routerLink]="['/wastewater/remediation-measures']" [routerLinkActive]="'active'">Sanierungsmaßnahmen</a></li>
        <li *ngIf="isSewerTinderV3Role || isAdminRole" role="menuitem"><a class="dropdown-item"
            [routerLink]="['/wastewater/damage-assessment']" [routerLinkActive]="'active'">Schadensbewertung</a></li>
      </ul>
    </div>
    <!--Admin view-->
    <div *ngIf="isAdminView" class="link">
      <div class="content" [routerLink]="['/admin/user-management']" [routerLinkActive]="'active'">
        <div class="icon">
          <span class="icon-Iconusers"></span>
        </div>
        <div class="title">Users</div>
      </div>
    </div>
    <div *ngIf="isAdminView" class="link">
      <div class="content" [routerLink]="['/admin/subscriptions']" [routerLinkActive]="'active'">
        <div class="icon">
          <span class="icon-Iconusers"></span>
        </div>
        <div class="title">Subscriptions</div>
      </div>
    </div>
    <div class="link" *ngIf="isAdminView">
      <div class="content" [routerLink]="['/admin/maps']" [routerLinkActive]="'active'">
        <div class="icon">
          <span class="icon-IconMaps"></span>
        </div>
        <div class="title">Maps</div>
      </div>
    </div>
    <div *ngIf="isAdminView" class="link">
      <div class="content" [routerLink]="['/admin/apps/questionnaires']" [routerLinkActive]="'active'">
        <div class="icon">
          <span class="icon-IconQuestionary"></span>
        </div>
        <div class="title">Questions</div>
      </div>
    </div>
    <div *ngIf="isAdminView" class="dropdown link" dropdown [autoClose]="!isExpanded" container="body">
      <div dropdownToggle class="dropdown-toggle content" [ngClass]="router.url.includes('/marketing') ? 'active': ''">
        <div class="icon">
          <span class="icon-IconTool"></span>
        </div>
        <div class="title">Marketing</div>
        <i class="icon-IconPlus"></i>
      </div>
      <ul *dropdownMenu class="dropdown-menu pt-0" [style]="isExpanded ? '' : 'top:-3.25rem'" role="menu">
        <div *ngIf="!isExpanded" class="dropdown-header">Marketing <i class="icon-IconPlus" dropdownToggle
            style="margin-left: 2rem; cursor: pointer"></i></div>
        <li role="menuitem"><a class="dropdown-item" [routerLink]="['/admin/marketing/announcements']"
            [routerLinkActive]="'active'">Announcements</a></li>
        <li role="menuitem"><a class="dropdown-item" [routerLink]="['/admin/marketing/events']"
            [routerLinkActive]="'active'">Events</a></li>
      </ul>
    </div>
    <div class="footer">
      <div *ngIf="isAdminRole" class="link">
        <div class="content" (click)="GoToAdminDashboard()">
          <div class="icon">
            <span class="icon-IconRefresh"></span>
          </div>
          <div class="title">{{isAdminView ? 'To User View' : 'To Admin View'}}</div>
        </div>
      </div>
      <div *ngIf="!isAdminView" class="link">
        <div class="content" [routerLink]="['/settings']" [routerLinkActive]="'active'">
          <div class="icon">
            <span class="icon-IconSettings"></span>
          </div>
          <div class="title">Einstellungen</div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<ng-template #tenantTemplate>
  <ul class="header-menu ps-0 w-100">
    <li *ngFor="let item of tenants" class="my-1 px-3 tenant-item"
      [ngClass]="item.tenantName == curTenant!.tenantName ? 'selected-tenant': ''">
      <span (click)="SetTenant(item);pop.hide()">{{item.displayName}}</span>
    </li>
  </ul>
</ng-template>
<ng-template #headerTemplate>
  <div class="profile-template">
    <p class="user-name m-0">{{userName}}</p>
    <p class="border-bottom m-0 pb-4">{{userEmail}}</p>
    <ul class="m-0">
      <li (click)="manageAccount();pop.hide()"><i class="icon-IconSettings mr-2"></i>Konto verwalten</li>
      <li (click)="logout()"><i class="icon-IconUpload mr-2"></i> Logout</li>
    </ul>
  </div>
</ng-template>
