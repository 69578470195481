<h1 class="mt-4 mb-0 mobile-display-none" style="margin-left:2rem;">Dashboard</h1>
<div *ngIf="isLoading" class="loader-position">
  <span class="loader"></span>
</div>
<ng-container *ngIf="!isLoading">
  <ng-container *ngIf="!isNoData; else noData">
    <!--1 row-->
    <div class="card" style="margin-left:2rem;margin-right: 2rem;margin-top: 1.75rem;">
      <div class="card-body">
        <h5>Gesamtnetz</h5>
        <div class="row mt-3">
          <div class="col-8">
            <div class="row h-100">
              <div class="col-4 border-right">
                <span class="category-name">Hauptkanal</span>
                <div class="mt-3" style="font-size: 2rem; font-weight: 400; width: fit-content; display: inline-block;">
                  {{statistic?.mainPipeLength! | mToKm}}</div>
                <span style="font-weight: 400; font-size: 22px;" class="ms-1">km</span>
                <div style="font-size: 20px; font-weight: 400;">{{statistic?.mainPipeCount | number:'1.0-0' : 'de-DE' }}
                </div>
              </div>
              <div class="col-4 border-right" style="padding-left: 36px">
                <span class="category-name">Anschlussleitungen</span>
                <div class="mt-3" style="font-size: 2rem; font-weight: 400; width: fit-content; display: inline-block;">
                  {{statistic?.connectionPipeLengthPublic! | mToKm}}</div>
                <span style="font-weight: 400; font-size: 22px;" class="ms-1">km</span>
                <div style="font-size: 20px; font-weight: 400;">{{statistic?.connectionPipeCountAll | number:'1.0-0':
                  'de-DE'}}</div>
              </div>
              <div class="col-4 border-right" style="padding-left: 36px">
                <span class="category-name">Schächte</span>
                <div class="mt-3" style="font-size: 2rem; font-weight: 400; width: fit-content; display: inline-block;">
                  {{statistic?.manholeCount | number:'1.0-0': 'de-DE' }}</div>
              </div>
            </div>
          </div>
          <div class="col-4" style="padding-left: 36px">
            <div class="row">
              <div class="col-4">
                <span class="category-name">Bauwerke</span>
                <div class="mt-3" style="font-size: 2rem; font-weight: 400; width: fit-content; display: inline-block;">
                  {{statistic?.buildingCount | number:'1.0-0': 'de-DE'}}</div>
              </div>
              <div class="col-8">
                <ul>
                  <li>
                    <span class="category">Pumpwerke</span>
                    <span class="float-end" style="font-weight: bold;">{{statistic?.pumpingStationCount |
                      number:'1.0-0':
                      'de-DE'}}</span>
                  </li>
                  <li>
                    <span class="category">Becken</span>
                    <span class="float-end" style="font-weight: bold;">{{statistic?.tankCount | number:'1.0-0':
                      'de-DE'}}</span>
                  </li>
                  <li>
                    <span class="category">Behandlungsanlagen</span>
                    <span class="float-end" style="font-weight: bold;">{{statistic?.treatmentFacilityCount |
                      number:'1.0-0':
                      'de-DE'}}</span>
                  </li>
                  <li>
                    <span class="category">Kläranlagen</span>
                    <span class="float-end" style="font-weight: bold;">{{statistic?.wastewaterTreatmentPlantCount |
                      number:'1.0-0': 'de-DE'}}</span>
                  </li>
                  <li>
                    <span class="category">Auslaufbauwerke</span>
                    <span class="float-end" style="font-weight: bold;">{{statistic?.outletStructureCount |
                      number:'1.0-0':
                      'de-DE'}}</span>
                  </li>
                </ul>
                <button type="button" class="btn btn-link btn-text-blue" (click)="openModal(template)">Alle
                  anzeigen</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--2 row-->
    <div class="card" style="margin-left:2rem;margin-right: 2rem;margin-top: 1.25rem;">
      <div class="card-body">
        <h5>Inspiziertes Abwassernetz im aktuellen Jahr und letztem Jahr</h5>
        <div class="row mt-3">
          <!--   Main pipe  -->
          <div class="col-4 border-right">
            <span class="category-name">Haltungen</span>
            <app-round-chart [curValue]="statistic?.inspMainPipeLengthCurr!" [hasCaret]="true" [isBigSize]="true" [isCount]="false"
                             [cssClass]="statistic?.inspMainPipeLengthLastYear! - statistic?.inspMainPipeLengthCurr! > 0 ? 'red-orientation' : 'green-orientation'"
                             [mainValue]="statistic?.inspMainPipeLengthLastYear!"
              [porcent]="(statistic?.inspMainPipeLengthCurr! * 100) / statistic?.inspMainPipeLengthLastYear!"></app-round-chart>
            <app-round-chart [curValue]="statistic?.inspMainPipeCountCurr!" [hasCaret]="true" [isBigSize]="false"
              [isCount]="true" [mainValue]="statistic?.inspMainPipeCountLastYear!"
                             [cssClass]="statistic?.inspMainPipeCountLastYear! - statistic?.inspMainPipeCountCurr! > 0 ? 'red-orientation' : 'green-orientation'"
              [porcent]="(statistic?.inspMainPipeCountCurr! * 100) / statistic?.inspMainPipeCountLastYear!"></app-round-chart>
            <div style="margin-top: 2rem;">
              <i class="caret-style icon-IconCaret-Up"></i>
              <span class="ms-2" style="font-size: 13px; color: #8D8E90;">Mehr als letztes Jahr</span>
              <i class="caret-style icon-IconCaret-Down ms-4"></i>
              <span class="ms-2" style="font-size: 13px; color: #8D8E90;">Weniger als letztes Jahr</span>
            </div>
          </div>
          <!-- Connection pipe  -->
          <div class="col-4 border-right" style="padding-left: 36px">
            <span class="category-name">Anschlussleitungen</span>
            <app-round-chart [curValue]="statistic?.inspConnectionPipeLengthCurrPublic!" [hasCaret]="true"
              [isBigSize]="true" [isCount]="false"
                             [cssClass]="statistic?.inspConnectionPipeLengthLastYearPublic! - statistic?.inspConnectionPipeLengthCurrPublic! > 0 ? 'red-orientation' : 'green-orientation'"
              [mainValue]="statistic?.inspConnectionPipeLengthLastYearPublic!"
              [porcent]="(statistic?.inspConnectionPipeLengthCurrPublic! * 100) / statistic?.inspConnectionPipeLengthLastYearPublic!"></app-round-chart>
            <app-round-chart [curValue]="statistic?.inspConnectionPipeCountCurr!" [hasCaret]="true" [isBigSize]="false"
              [isCount]="true" [mainValue]="statistic?.inspConnectionPipeCountLastYear!"
                             [cssClass]="statistic?.inspConnectionPipeCountLastYear! - statistic?.inspConnectionPipeCountCurr! > 0 ? 'red-orientation' : 'green-orientation'"
              [porcent]="(statistic?.inspConnectionPipeCountCurr! * 100) / statistic?.inspConnectionPipeCountLastYear!"></app-round-chart>
          </div>
          <!-- Manholes pipe  -->
          <div class="col-4" style="padding-left: 36px">
            <span class="category-name">Schächte</span>
            <app-round-chart [curValue]="statistic?.inspManholeCountCurr!" [hasCaret]="true" [isBigSize]="true"
              [isCount]="true" [mainValue]="statistic?.inspManholeCountLastYear!"
                             [cssClass]="statistic?.inspManholeCountLastYear! - statistic?.inspManholeCountCurr ! > 0 ? 'red-orientation' : 'green-orientation'"
              [porcent]="(statistic?.inspManholeCountCurr! * 100) / statistic?.inspManholeCountLastYear!"></app-round-chart>
          </div>
        </div>
      </div>
    </div>
    <!--3 row-->
    <div class="card" style="margin-left:2rem;margin-right: 2rem;margin-top: 1.25rem;">
      <div class="card-body">
        <h5>Inspiziertes Abwassernetz innerhalb der EKVO</h5>
        <div class="row mt-3">
          <!--   Main pipe  -->
          <div class="col-4 border-right">
            <span class="category-name">Haltungen</span>
            <app-round-chart [curValue]="statistic?.inspMainPipeLengthEKVO!" [hasCaret]="false" [isBigSize]="true"
              [isCount]="false" [cssClass]="'blue-orientation'" [mainValue]="statistic?.mainPipeLength!"
              [porcent]="(statistic?.inspMainPipeLengthEKVO! * 100) / statistic?.mainPipeLength!"></app-round-chart>
            <app-round-chart [curValue]="statistic?.inspMainPipeCountEKVO!" [hasCaret]="false" [isBigSize]="false"
              [isCount]="true" [cssClass]="'blue-orientation'" [mainValue]="statistic?.mainPipeCount!"
              [porcent]="(statistic?.inspMainPipeCountEKVO! * 100) / statistic?.mainPipeCount!"></app-round-chart>
          </div>
          <!-- Connection pipe  -->
          <div class="col-4 border-right" style="padding-left: 36px">
            <span class="category-name">Anschlussleitungen</span>
            <app-round-chart [curValue]="statistic?.inspConnectionPipeLengthEKVO!" [hasCaret]="false" [isBigSize]="true"
              [isCount]="false" [cssClass]="'blue-orientation'" [mainValue]="statistic?.connectionPipeLengthPublic!"
              [porcent]="(statistic?.inspConnectionPipeLengthEKVO! * 100) / statistic?.connectionPipeLengthPublic!"></app-round-chart>
            <app-round-chart [curValue]="statistic?.inspConnectionPipeCountEKVO!" [hasCaret]="false" [isBigSize]="false"
              [isCount]="true" [cssClass]="'blue-orientation'" [mainValue]="statistic?.connectionPipeCountAll!"
              [porcent]="(statistic?.inspConnectionPipeCountEKVO! * 100) / statistic?.connectionPipeCountAll!"></app-round-chart>
          </div>
          <!-- Manholes pipe  -->
          <div class="col-4" style="padding-left: 36px">
            <span class="category-name">Schächte</span>
            <app-round-chart [curValue]="statistic?.inspManholeCountEKVO!" [hasCaret]="false" [isBigSize]="true"
              [isCount]="true" [cssClass]="'blue-orientation'" [mainValue]="statistic?.manholeCount!"
              [porcent]="(statistic?.inspManholeCountEKVO! * 100) / statistic?.manholeCount!"></app-round-chart>
          </div>
        </div>
      </div>
    </div>
    <!--4 row-->
    <div class="card purpule-default-color" style="margin-left:2rem;margin-right: 2rem;margin-top: 1.25rem;">
      <div class="card-body">
        <h5>Inspiziertes Netz mit Sanierungsvorplanung</h5>
        <div class="row mt-3">
          <!--   Main pipe  -->
          <div class="col-4 border-right">
            <span class="category-name">Haltungen</span>
            <app-round-chart [curValue]="statistic?.inspMainPipeLengthRehab!" [hasCaret]="false" [isBigSize]="true"
              [isCount]="false" [cssClass]="'purpule-orientation'" [mainValue]="statistic?.inspMainPipeLengthEKVO!"
              [porcent]="(statistic?.inspMainPipeLengthRehab! * 100) / statistic?.inspMainPipeLengthEKVO!"></app-round-chart>
            <app-round-chart [curValue]="statistic?.inspMainPipeCountRehab!" [hasCaret]="false" [isBigSize]="false"
              [isCount]="true" [cssClass]="'purpule-orientation'" [mainValue]="this.statistic?.inspMainPipeCountEKVO!"
              [porcent]="(statistic?.inspMainPipeCountRehab! * 100) / statistic?.inspMainPipeCountEKVO!"></app-round-chart>
          </div>
          <!-- Connection pipe  -->
          <div class="col-4 border-right" style="padding-left: 36px">
            <span class="category-name">Anschlussleitungen</span>
            <app-round-chart [curValue]="statistic?.inspConnectionPipeLengthRehab!" [hasCaret]="false"
              [isBigSize]="true" [isCount]="false" [cssClass]="'purpule-orientation'"
              [mainValue]="statistic?.inspConnectionPipeLengthEKVO!"
              [porcent]="(statistic?.inspConnectionPipeLengthRehab! * 100) / statistic?.inspConnectionPipeLengthEKVO!"></app-round-chart>
            <app-round-chart [curValue]="statistic?.inspConnectionPipeCountRehab!" [hasCaret]="false"
              [isBigSize]="false" [isCount]="true" [cssClass]="'purpule-orientation'"
              [mainValue]="this.statistic?.inspConnectionPipeCountEKVO!"
              [porcent]="(statistic?.inspConnectionPipeCountRehab! * 100) / statistic?.inspConnectionPipeCountEKVO!"></app-round-chart>
          </div>
          <!-- Manholes pipe  -->
          <div class="col-4" style="padding-left: 36px">
            <span class="category-name">Schächte</span>
            <app-round-chart [curValue]="statistic?.inspManholeCountRehab!" [hasCaret]="false" [isBigSize]="true"
              [isCount]="true" [cssClass]="'purpule-orientation'" [mainValue]="this.statistic?.inspManholeCountEKVO!"
              [porcent]="(statistic?.inspManholeCountRehab! * 100) / statistic?.inspManholeCountEKVO!"></app-round-chart>
          </div>
        </div>
      </div>
    </div>
    <!--5 row-->
    <div class="card" style="margin-left:2rem;margin-right: 2rem;margin-top: 1.25rem;">
      <div class="card-body">
        <h5>Neubau in den letzten 5 Jahre</h5>
        <table class="table table-striped mt-3">
          <thead>
            <tr class="position-relative">
              <th scope="col" class="ps-3 col-2">Abwassertechnische Anlage</th>
              <th scope="col" class="col-2">{{date.getFullYear()}}</th>
              <th scope="col" class="col-2">{{date.getFullYear() - 1}}</th>
              <th scope="col" class="col-2">{{date.getFullYear() - 2}}</th>
              <th scope="col" class="col-2">{{date.getFullYear() - 3}}</th>
              <th scope="col" class="col-2">{{date.getFullYear() - 4}}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="ps-3">Haltungen</td>
              <td *ngFor="let obj of statistic!.newObjects">{{obj.newMainPipeLength == null ? 0 : obj.newMainPipeLength!
                |
                mToKm}} km</td>
            </tr>
            <tr>
              <td class="ps-3">Schächte</td>
              <td *ngFor="let obj of statistic!.newObjects">{{obj.newManholeCount == null ? 0 : obj.newManholeCount |
                number:'1.0-0': 'de-DE'}}</td>
            </tr>
            <tr>
              <td class="ps-3">Anschlussleitungen</td>
              <td *ngFor="let obj of statistic!.newObjects">{{obj.newConnectionPipeLength == null ? 0 :
                obj.newConnectionPipeLength! | mToKm }} km</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!--6 row-->
    <div class="card" style="margin-left:2rem;margin-right: 2rem;margin-top: 1.25rem; padding-bottom: 3.25rem;">
      <div class="card-body">
        <h5>Zustand - ISYBAU</h5>
        <div class="row mt-3">
          <!--   Main pipe  -->
          <div class="col-4 border-right">
            <span class="category-name">Haltungen</span>
            <div>
              <ngx-charts-bar-vertical [results]="mainPipes" [gradient]="gradient" [xAxis]="showXAxis"
                [xAxisTickFormatting]="dateTickFormatting" [yAxisTickFormatting]="dateTickFormatting"
                [scheme]="colorScheme" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel" [yAxisTickFormatting]="yAxisTickFormattingM" style="top: 2rem;" class="position-relative">
              </ngx-charts-bar-vertical>
            </div>
          </div>
          <!-- Connection pipe  -->
          <div class="col-4 border-right" style="padding-left: 36px">
            <span class="category-name">Anschlussleitungen</span>
            <div>
              <ngx-charts-bar-vertical [results]="connectionPipes" [gradient]="gradient" [xAxis]="showXAxis"
                [scheme]="colorScheme" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel" [yAxisTickFormatting]="yAxisTickFormattingM" style="top: 2rem;" class="position-relative">
              </ngx-charts-bar-vertical>
            </div>
          </div>
          <!-- Manholes pipe  -->
          <div class="col-4" style="padding-left: 36px">
            <span class="category-name">Schächte</span>
            <div>
              <ngx-charts-bar-vertical [results]="manholesPipes" [gradient]="gradient" [xAxis]="showXAxis"
                [scheme]="colorScheme" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel" [yAxisTickFormatting]="yAxisTickFormattingSt" style="top: 2rem;" class="position-relative">
              </ngx-charts-bar-vertical>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>


<ng-template #noData>
  <p class="no-data">Für das ausgewählte Gebiet liegen keine Daten vor</p>
</ng-template>

<ng-template #template>
  <div style="padding: 40px">
    <div class="modal-header pb-0">
      <h4 class="modal-title pull-left">Bauwerke</h4>
      <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <ul>
        <li>
          <span class="category">Pumpwerke</span>
          <span class="float-end" style="font-weight: bold;">{{statistic?.pumpingStationCount | number:'1.0-0':
            'de-DE'}}</span>
        </li>
        <li>
          <span class="category">Becken</span>
          <span class="float-end" style="font-weight: bold;">{{statistic?.tankCount | number:'1.0-0': 'de-DE'}}</span>
        </li>
        <li>
          <span class="category">Behandlungsanlagen</span>
          <span class="float-end" style="font-weight: bold;">{{statistic?.treatmentFacilityCount | number:'1.0-0':
            'de-DE'}}</span>
        </li>
        <li>
          <span class="category">Kläranlagen</span>
          <span class="float-end" style="font-weight: bold;">{{statistic?.wastewaterTreatmentPlantCount |
            number:'1.0-0': 'de-DE'}}</span>
        </li>
        <li>
          <span class="category">Auslaufbauwerke</span>
          <span class="float-end" style="font-weight: bold;">{{statistic?.outletStructureCount | number:'1.0-0':
            'de-DE'}}</span>
        </li>
        <li>
          <span class="category">Pumpen</span>
          <span class="float-end" style="font-weight: bold;">{{statistic?.pumpCount | number:'1.0-0': 'de-DE'}}</span>
        </li>
        <li>
          <span class="category">Wehre/Überläufe</span>
          <span class="float-end" style="font-weight: bold;">{{statistic?.weirOverflowCount | number:'1.0-0':
            'de-DE'}}</span>
        </li>
        <li>
          <span class="category">Drosseln</span>
          <span class="float-end" style="font-weight: bold;">{{statistic?.reducerCount | number:'1.0-0':
            'de-DE'}}</span>
        </li>
        <li>
          <span class="category">Schieber</span>
          <span class="float-end" style="font-weight: bold;">{{statistic?.gateValveCount | number:'1.0-0':
            'de-DE'}}</span>
        </li>
        <li>
          <span class="category">Rechen</span>
          <span class="float-end" style="font-weight: bold;">{{statistic?.barScreenCount | number:'1.0-0':
            'de-DE'}}</span>
        </li>
        <li>
          <span class="category">Siebe</span>
          <span class="float-end" style="font-weight: bold;">{{statistic?.screenCount | number:'1.0-0': 'de-DE'}}</span>
        </li>
        <li>
          <span class="category">Versickerungsanlagen</span>
          <span class="float-end" style="font-weight: bold;">{{statistic?.seepagePlantCount | number:'1.0-0':
            'de-DE'}}</span>
        </li>
        <li>
          <span class="category">Regenwassernutzungsanlagen</span>
          <span class="float-end" style="font-weight: bold;">{{statistic?.rainwaterHarvestingSystemCount |
            number:'1.0-0': 'de-DE'}}</span>
        </li>
        <li>
          <span class="category">Einlaufbauwerke</span>
          <span class="float-end" style="font-weight: bold;">{{statistic?.inletStructureCount | number:'1.0-0':
            'de-DE'}}</span>
        </li>
      </ul>
    </div>
  </div>
</ng-template>
