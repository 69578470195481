import { Component, HostListener } from '@angular/core';
import { UploadFileService } from './upload/_services/upload-file.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private uploadFileService: UploadFileService){}

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler($event: any): boolean {
    if (this.uploadFileService.isFilesUploading$.value) {
      $event.returnValue = false;
      return false;
    }
    return true;
  }
}
