import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { TenantService } from 'src/app/_services/tenant.service';
import { AnswerEvaluation } from '../../models/answerEvaluation';
import { Evaluation } from '../../models/evaluation';
import { Tenant } from 'src/app/_models/tenant';
import {
  Subject,
  debounceTime,
  distinctUntilChanged,
  forkJoin,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { Router } from '@angular/router';
import { TokenService } from '../../../_services/token.service';
import { DamageAssessmentService } from '../../services/damage-assessment.service';
import { EvaluationType } from '../../models/evaluationType.model';

@Component({
  selector: 'app-damage-assessment',
  templateUrl: './damage-assessment.component.html',
  styleUrls: ['./damage-assessment.component.scss'],
})
export class DamageAssessmentComponent implements OnInit, AfterViewInit {
  @ViewChild('zoomWrapper') zoomWrapper!: ElementRef;
  @ViewChildren('zoomWrapper') zoomWrapperList!: QueryList<any>;
  @ViewChild('zoomWrapperContainer') zoomWrapperContainer!: ElementRef;
  @ViewChild('damageScale') damageScale?: ElementRef;
  readonly evaluationType = EvaluationType;
  hoverScaleMobile = '';
  answer: AnswerEvaluation | undefined;
  evaluation: Evaluation | undefined;
  tenant: Tenant | undefined;
  commentar = '';
  isPrevious = false;
  isLoading = true;
  isInvalid = false;
  isFinished = false;
  isNoData = false;
  symbolsRemains = 300;
  commentarValidation = new Subject<string>();
  selectedType?: number;
  toDoCount: number[] = [];
  damageTitle = '';
  damageDescription = '';
  ratio = 4 / 3;

  constructor(
    private damageAssessmentService: DamageAssessmentService,
    private tenantService: TenantService,
    private router: Router,
    private tokenStorageService: TokenService
  ) {
    this.commentarValidation
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((value) => {
        if (value.length > 280) {
          this.isInvalid = true;
          this.symbolsRemains = 300 - value.length;
        } else {
          this.isInvalid = false;
        }
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    if (this.zoomWrapper && this.ratio) {
      this.setUpZoomWrapperSize(this.ratio);
    }
  }

  ngOnInit(): void {
    this.getCountsByTypes();
  }

  ngAfterViewInit() {
    this.zoomWrapperList.changes.subscribe(() => {
      if (this.zoomWrapper && this.evaluation) {
        this.getImageDimensions(this.evaluation.imageUrl);
      }
    });
  }

  private getCountsByTypes(): void {
    this.isLoading = true;
    this.tenantService
      .GetTenantById()
      .pipe(
        tap((data) => {
          const curTenant = data.find(
            (t) => t.tenantName == this.tokenStorageService.GetTenant()
          );
          this.tenant = curTenant;
        }),
        switchMap(() => {
          return forkJoin([
            this.damageAssessmentService.getEvaluation(
              this.tenant!.tenantName,
              this.tenant!.serverName,
              this.evaluationType.Betriebssicherheit
            ),
            this.damageAssessmentService.getEvaluation(
              this.tenant!.tenantName,
              this.tenant!.serverName,
              this.evaluationType.Dichtheit
            ),
            this.damageAssessmentService.getEvaluation(
              this.tenant!.tenantName,
              this.tenant!.serverName,
              this.evaluationType.Standsicherheit
            ),
          ]);
        })
      )
      .subscribe({
        next: (res: Evaluation[]) => {
          this.toDoCount = res.map((item) => {
            return item ? item.maxOrder! - item.order! + 1 : 0;
          });
          this.isNoData = this.toDoCount.every((count) => !count);
          this.isLoading = false;
        },
        error: () => {
          this.isNoData = true;
          this.isLoading = false;
        },
      });
  }

  private getImageDimensions(imageUrl: string): void {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      const imageWidth = img.width;
      const imageHeight = img.height;
      this.ratio = imageWidth / imageHeight;
      if (this.ratio) {
        this.setUpZoomWrapperSize(this.ratio);
      }
    };
  }

  private setUpZoomWrapperSize(imageRatio: number): void {
    const containerMaxHeight = 448; // 28rem
    const containerMaxWidth =
      this.zoomWrapperContainer.nativeElement.clientWidth;
    const posibleHeight = containerMaxWidth / imageRatio;
    if (posibleHeight <= containerMaxHeight) {
      this.setUpZoomWrapperHeight(posibleHeight);
      this.setUpZoomWrapperWidth(containerMaxWidth);
    } else {
      this.setUpZoomWrapperHeight(containerMaxHeight);
      this.setUpZoomWrapperWidth(containerMaxHeight * imageRatio);
    }
  }

  private setUpZoomWrapperWidth(n: number): void {
    const nativeElement = this.zoomWrapper.nativeElement;
    nativeElement.style.width = `${n.toString()}px`;
  }

  private setUpZoomWrapperHeight(n: number): void {
    const nativeElement = this.zoomWrapper.nativeElement;
    nativeElement.style.height = `${n.toString()}px`;
  }

  getEvaluation(): void {
    this.isLoading = true;
    this.tenantService
      .GetTenantById()
      .pipe(
        switchMap((data) => {
          const curTenant = data.find(
            (t) => t.tenantName == this.tokenStorageService.GetTenant()
          );
          this.tenant = curTenant;
          return this.damageAssessmentService.getEvaluation(
            this.tenant!.tenantName,
            this.tenant!.serverName,
            this.selectedType!
          );
        })
      )
      .subscribe({
        next: (data) => {
          this.isNoData = false;
          if (data == null) {
            this.isFinished = true;
            this.isLoading = false;
            return;
          }
          this.evaluation = data;
          if (this.zoomWrapper && this.evaluation) {
            this.getImageDimensions(this.evaluation.imageUrl);
          }
          if (data.damage) {
            const damageDashIndex = data.damage.indexOf('-');
            if (damageDashIndex !== -1) {
              this.damageTitle = data.damage.slice(0, damageDashIndex + 1);
              this.damageDescription = data.damage.slice(damageDashIndex + 1);
            } else {
              this.damageDescription = data.damage;
            }
          }

          this.commentar = data.comment as string;
          this.isPrevious = data.order == 1;
          this.isLoading = false;
        },
        error: () => {
          this.isNoData = true;
          this.isLoading = false;
        },
      });
  }

  touchend(): void {
    if (this.hoverScaleMobile) {
      this.sendAnswer(+this.hoverScaleMobile);
    }
    this.finishTrackMobile();
  }

  finishTrackMobile() {
    if (this.hoverScaleMobile !== '') {
      this.hoverScaleMobile = '';
      for (let i = 0; i <= 5; i++) {
        this.damageScale?.nativeElement.classList.remove(`mobile-${i}`);
      }
    }
  }

  trackMobile(event: any) {
    const touch = event.changedTouches[0];
    const rect = this.damageScale?.nativeElement.getBoundingClientRect();
    if (
      touch.clientX < rect.left ||
      touch.clientX > rect.right ||
      touch.clientY < rect.top ||
      touch.clientY > rect.bottom
    ) {
      // Trigger your action here when touch leaves the child element
      this.finishTrackMobile();
    } else {
      const parentWidth = rect.right - rect.left;
      const childWidth = parentWidth / 6;
      const selectElement =
        Math.ceil((touch.clientX - rect.left) / childWidth) - 1;
      if (selectElement.toString() !== this.hoverScaleMobile) {
        this.hoverScaleMobile = selectElement.toString();
        for (let i = 0; i <= 5; i++) {
          this.damageScale?.nativeElement.classList.remove(`mobile-${i}`);
        }
        this.damageScale?.nativeElement.classList.add(
          `mobile-${this.hoverScaleMobile}`
        );
      }
    }
  }

  selectType(type: number): void {
    this.selectedType = type;
    this.getEvaluation();
  }

  goPrevious() {
    this.damageAssessmentService
      .getPrevious(
        this.tenant!.tenantName,
        this.tenant!.serverName,
        this.selectedType!
      )
      .subscribe((data) => {
        this.evaluation = data;
        if (this.zoomWrapper && this.evaluation) {
          this.getImageDimensions(this.evaluation.imageUrl);
        }
        this.commentar = data.comment as string;
        this.isPrevious = true;
        this.isInvalid = false;
      });
  }

  sendAnswer(answear: number) {
    const answer: any = {
      ID: this.evaluation?.id!,
      Source: this.tenant?.serverName!,
      Tenant: this.tenant?.tenantName!,
      Comment: this.commentar,
      Type: this.selectedType!,
      Value: answear,
    };
    this.isLoading = true;
    this.damageAssessmentService
      .sendEvaluation(answer)
      .subscribe((data: any) => {
        if (data == null) {
          this.isFinished = true;
        }
        this.isLoading = false;
        this.evaluation = data;
        if (this.zoomWrapper && this.evaluation) {
          this.getImageDimensions(this.evaluation.imageUrl);
        }
        if (data?.comment) {
          this.commentar = data.comment as string;
        }
        this.isPrevious = false;
        this.isInvalid = false;
      });
  }

  GoHome() {
    this.router.navigateByUrl('');
  }
}
