<div class="text-center">
  <img src="./assets/Images/Logo.png">
</div>
<div class="mobile-response">
  <div class="card mx-auto">
    <div class="card-body p-0">
      <h4>Neu hochladen für </h4>
      <div *ngIf="isError" style="margin-top: 1.75rem" class="card validation-card">
        <i class="icon-IconExclamation-Circle position-absolute"></i> <span style="margin-left:3rem; color:red;">Falsche Email oder falsches Passwort</span>
      </div>
      <form [formGroup]="loginForm" style="margin-top: 1.75rem" *ngIf="!notUploadedStructure.length">
        <ng-container *ngIf="!email">
          <label class="form-label w-100" >
            Name<i class="required">*</i>
            <input type="text" formControlName="name" class="form-control"
                   [ngClass]="isError || loginForm.get('name')!.hasError('required') && loginForm.get('name')!.touched ? 'invalid' : ''"
                   [style]="loginForm.get('name')!.hasError('required') && loginForm.get('name')!.touched ? 'padding-left: 2.25rem;' : '' "
            >
            <i *ngIf="loginForm.get('name')!.hasError('required') && loginForm.get('name')!.touched" class="icon-IconCancel"></i>
            <span *ngIf="loginForm.get('name')!.hasError('required') && loginForm.get('name')!.touched" style="color: red; font-size: 12px;" class="mb-3">Dieses Feld ist erforderlich.</span>
          </label>
          <label class="form-label w-100" >
            Typ<i class="required">*</i>
            <ngx-select formControlName="type" [noAutoComplete]="true" [items]="uploadFileTypeOptions"
              optionValueField="value" optionTextField="label"></ngx-select>
          </label>
          <label class="form-label w-100" >
            Kommentar
            <input type="text" formControlName="comment" class="form-control">
          </label>
          <app-drag-and-drop (addedFiles)="onFileReChange($event)"></app-drag-and-drop>
        </ng-container>
      </form>

      <div>
        <div class="containter-place mt-4 col-6 list-overflow">
          <ul class="position-relative ps-0">
            <div class="containter-place">
              <li *ngFor="let item of notUploadedStructure" class="mt-0 d-flex flex-column">
                <ng-container
                  [ngTemplateOutlet]="item.type == 'file' ? file : folder"
                  [ngTemplateOutletContext]="{item: item, parentPath: item.path}">
                </ng-container>
              </li>
            </div>
          </ul>
        </div>
      </div>

      <div class="card-footer pt-3 pb-0">
        <div class="d-flex justify-content-end w-100">
          <button class="btn btn-link btn-text-blue px-4" (click)="cancelUpload()">Abbrechen</button>
          <button class="btn btn-primary px-4" style="margin-left: 0.75rem" (click)="upload()" [disabled]="">Upload</button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #folder let-item='item'>
  <div>
    <span (click)="item.isOpen = !item.isOpen">
      <span class="pdf-small-icon" [ngClass]="item.isOpen ? 'icon-IconCaret-Down' : 'icon-IconCaret-Up'"></span>
      <span class="fa fa-folder-o pdf-small-icon position-relative">
        <span *ngIf="currentFiles.length" class="dot" [ngClass]="item.isAdded ? 'added' : 'unadded'"></span>
      </span>
      <span class="file-path ellipsis ml-1"><b>{{item.name}}</b></span>
    </span>
    <span class="file icon-IconRefresh" *ngIf="currentFiles.length && !item.isAdded">
      <input type="file" name="file" class="icon-input" (change)="onFileReChange($event, item.path)" webkitdirectory directory />
    </span>
  </div>
  <ng-container *ngIf="item.isOpen">
    <li *ngFor="let child of item.children" class="ml-4 mt-0 d-flex flex-column">
      <ng-container
        [ngTemplateOutlet]="child.type == 'file' ? file : folder"
        [ngTemplateOutletContext]="{item: child, parentPath: item.path}">
      </ng-container>
    </li>
  </ng-container>
</ng-template>

<ng-template #file let-item='item' let-parentPath='parentPath'>
  <div class="ml-3">
    <span class="{{item.name.split('.').pop() | iconByExtantion}} pdf-small-icon position-relative">
      <span *ngIf="currentFiles.length" class="dot" [ngClass]="item.isAdded ? 'added' : 'unadded'"></span>
    </span>
    <span class="file-path ellipsis ml-1"><b>{{item.name}}</b></span>
    <span *ngIf="currentFiles.length && !item.isAdded" class="gray">(Die Datei fehlt)</span>
    <span *ngIf="currentFiles.length && !item.isAdded" class="file icon-IconRefresh ml-1">
      <input type="file" name="file" class="icon-input" (change)="onFileReChange($event, parentPath)" />
    </span>
    <span *ngIf="item.isUploaded" class="icon-IconCheck"></span>
  </div>
</ng-template>

