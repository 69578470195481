import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {Tenant} from "../_models/tenant";
import {AssignTenantDTO} from "../_models/AssignTenantDTO";

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  header = {
    headers: new HttpHeaders()
      .set("ApiKey", environment.Tenant.ApiKey)
  }

  constructor(private http: HttpClient) { }

  GetTenantById(){
    return this.http.get<Tenant[]>(environment.Evaluation.TenantUrl);
  }

  GetAllTenants(){
    return this.http.get<Tenant[]>(environment.Tenant.AllTenantsUrl, this.header);
  }

  AssignTenants(userId:string, tenants: AssignTenantDTO[]){
    return this.http.post(environment.Tenant.AssignTenantUrl, {
      userId: userId,
      tenants: tenants
    }, this.header)
  }

  AssignDemoTenant(){
    return this.http.post(environment.DemoTenant.AssignDemoTenantUrl, {})
  }

}
