import { Upload } from "./Upload";

export interface UploadFile {
         id?: string,
         name: string,
         extension?: string,
         filePath?: string,
         status: Status,
         year?: string,
         uploads?: Upload[],
         uploadedPathId?: string,
         fileType?: string,
         sizeOfFile: number
}

export enum Status{
    Open, Partially, Completed, Error = 64, Uploading = 65
}
