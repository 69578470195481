import { Injectable } from '@angular/core';
import { HubConnection } from '@microsoft/signalr';
import * as signalR from '@microsoft/signalr';
import {TokenService} from "./token.service";
import jwt_decode from "jwt-decode";
import {Subject} from "rxjs";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private hubConnection: HubConnection | undefined;
  public notification: Subject<any> = new Subject<any>();
  constructor(private tokenService: TokenService) {
  }

  start() {
    const decoded: any = jwt_decode(this.tokenService.getToken()!);
    const userId = decoded.id;
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.apiSignalRUrl + userId)
      .withAutomaticReconnect()
      .build();

    this.hubConnection.start().catch(
      (err) => console.error(16, err.toString()))
      .then( () => {
        this.hubConnection?.invoke('GetConnectionId');
      });

    this.hubConnection.on('sendToUser', (data: any) => {
      this.notification.next(data);
    });
  }
}
