<h1 class="mt-4 mb-0 mobile-display-none" style="margin-left:2rem;">Sanierungsmaßnahmen</h1>
<div *ngIf="isLoading" class="loader-position">
    <span class="loader"></span>
</div>
<ng-container *ngIf="!isLoading">
    <ng-container *ngIf="!isNoData; else noData">
        <div *ngIf="!selectedPage" class="card card-width mt-4">
            <div class="text-center content-wrapper">
                <h2>Willkommen User!</h2>
                <p><b>{{(evaluation?.order || 1) - 1}} von {{evaluation?.maxOrder}}</b> Schäden sind schon bewertet</p>
                <div class="d-flex flex-column pt-4">
                    <button class="btn btn-primary mb-2 py-3 position-relative" (click)="selectPage(MeasurePage.Main)">
                        Bewertung starten
                        <span class="btn-count">{{evaluation ? (evaluation?.maxOrder! - evaluation?.order! + 1) : 0}}</span>
                    </button>
                </div>
            </div>
        </div>
        <ng-container *ngIf="selectedPage === MeasurePage.Main">
            <div class="card col-12 col-lg-6 mx-auto mt-4 p-0">
                <div *ngIf="!isFinished" class="card-body">
                    <div class="zoom-wrapper-container" #zoomWrapperContainer>
                        <div class="zoom-wrapper position-relative" #zoomWrapper>
                            <button *ngIf="!isPrevious" type="button" class="btn position-absolute button-back" (click)="goPrevious()"><i class="icon-IconArrow-Left"></i></button>
                            <lib-ngx-image-zoom class="w-100 img" [magnification]="1" [enableScrollZoom]="true" [enableLens]="true"
                                [lensWidth]="100000" [magnification]="1" [lensHeight]="1000" [thumbImage]="evaluation!.imageUrl"
                                [fullImage]="evaluation!.imageUrl"></lib-ngx-image-zoom>
                        </div>
                    </div>

                    <div class="mx-4" style="margin-top: 0.75rem;">
                        <div class="info-block">
                            <div style="display: inline-block; font-size: 15px;">
                                <b>Haltung: {{evaluation?.objectName}}</b>
                            </div>
                            <b class="float-end"
                                style="font-size: 14px;">{{evaluation?.order}}/{{evaluation?.maxOrder}}</b>
                            <br>
                            <div style="display: inline-block; font-size: 15px;" class="mt-2">
                                <span style="width: max-content;">{{evaluation?.damage}}</span>
                            </div>
                        </div>
                        <textarea [(ngModel)]="commentar" (ngModelChange)="this.commentarValidation.next($event)"
                            maxlength="300" class="mt-3 w-100 ps-3" rows="3" placeholder="Kommentar (optional)"
                            [ngClass]="isInvalid ? 'invalid':''"></textarea>
                        <span class="characters-return" *ngIf="isInvalid"> {{symbolsRemains}} Zeichen übrig</span>
                        <form [formGroup]="form">
                            <div class="grid" formArrayName="catalogFormArray">
                                <p class="m-0">Maßnahme:</p>
                                <p class="count-header m-0">Menge:<i class="required">*</i></p>
                                <ng-container *ngFor="let item of itemsFormArray.controls; let i = index" [formGroupName]="i">
                                    <select (change)="makeSelect(item.value.kuerzel, i)" formControlName="kuerzel">
                                        <optgroup label="Favoriten" *ngIf="catalogItemsFavorite.length"></optgroup>
                                        <ng-container *ngFor="let catItem of catalogItemsFavorite">
                                            <option [value]="catItem.kuerzel">{{catItem.description}}</option>
                                        </ng-container>
                                        <optgroup label="Alle" *ngIf="catalogItemsOther.length"></optgroup>
                                        <ng-container *ngFor="let catItem of catalogItemsOther">
                                            <option [value]="catItem.kuerzel">{{catItem.description}}</option>
                                        </ng-container>
                                    </select>
                                    <input type="text" formControlName="count" [placeholder]="!item.value.description ? 'Menge' : !item.value.quantityRequired ? 'Nicht notwendig' : item.value.unit" (keypress)="handleKeyPress($event, item.value)" (paste)="onPaste($event)">
                                    <i class="icon-IconDelete" (click)="removeItem(i)" [ngClass]="{'disabled': itemsFormArray.controls.length === 1}"></i>
                                </ng-container>
                            </div>
                            <div *ngIf="itemsFormArray.value.length <= 5" class="d-flex add-catalog-item">
                                <div class="d-flex" (click)="addItem()">
                                    <p class="blue-circle-border">+</p>
                                    <p>Option hinzufügen</p>
                                </div>
                            </div>
                        </form>
                        <div class="row mt-4">
                            <div class="col-4 px-1">
                                <button class="btn btn-primary w-100 d-flex justify-content-center"
                                    (click)="selectPage(MeasurePage.Catalog)">
                                    <span>Zum Katalog</span>
                                </button>
                            </div>
                            <div class="col-4 px-1">
                                <button type="button" class="btn w-100 d-flex justify-content-center"
                                    (click)="skip()"
                                    style="background: rgba(0, 94, 204, 0.1); color: var(--main-blue);">
                                    <span class="d-none d-md-inline">Nicht eindeutig</span>
                                    <i class="icon-IconQuestion-O d-md-none"></i>
                                </button>
                            </div>
                            <div class="col-4 px-1">
                                <button type="button" class="btn w-100 d-flex justify-content-center" style="background: #009B51; color: white;" (click)="save()" [disabled]="form.invalid">
                                  Speichern
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="isFinished" class="card-body" style="height: 80vh">
                    <div class="text-center" style="margin-top: 25%;">
                        <img style="width: 100px;" src="./assets/Images/Done.png">
                        <h3 style="margin-top: 1.75rem; margin-left: -2rem;">Glückwunsch</h3>
                        <small>Es gibt keine neuen Fälle zu prüfen!</small>
                        <br />
                        <button type="button" class="btn btn-primary w-50" style="margin-top: 1.75rem;"
                            (click)="goHome()">Zurück
                            zur Startseite</button>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="selectedPage === MeasurePage.Catalog">
            <div *ngIf="catalogItems.length; else noData" class="card card-width card-height p-4 mt-4">
                <h2 class="text-center">Maßnahmenkatalog</h2>
                <p class="text-center my-0">Ein Klick auf den Stern markiert die Maßnahme als Favorit bzw. entfernt die Markierung.</p>
                <ul class="catalog-list mt-4">
                    <li *ngFor="let item of catalogItems" class="border-bottom">
                        <div class="d-flex p-2">
                            <div class="catalog-item-text">
                                <h6 class="m-0">{{item.kuerzel}}</h6>
                                <div class="d-flex flex-wrap catalog-item-description">
                                    <p class="m-0">{{item.description}}</p>
                                </div>
                            </div>
                            <i class="my-auto mx-2" [ngClass]="item.isFavorte ? 'icon-IconStar-Filled' : 'icon-IconStar-Outlined'" (click)="toggleFavorite(item.id)"></i>
                        </div>
                    </li>
                </ul>
                <button class="btn btn-primary w-100" (click)="selectPage(MeasurePage.Main)">Zurück zur Bewertung</button>
            </div>
        </ng-container>
    </ng-container>
    <ng-template #noData>
        <p class="no-data">Für das ausgewählte Gebiet liegen keine Daten vor</p>
    </ng-template>
</ng-container>
