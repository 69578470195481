import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WakeLockService {
  playPseudoSceenLockVideo$ = new Subject<boolean>();

  requestWakeLock() {
    this.playPseudoSceenLockVideo$.next(true);
  }

  releaseWakeLock() {
    this.playPseudoSceenLockVideo$.next(false);
  }
}
