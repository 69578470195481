<div class="text-center">
  <img class="logo" src="./assets/Images/Logo.png">
</div>
<div *ngIf="!isFormSend" class="card col-lg-3 col-11 mx-auto">
  <div class="card-body">
    <h4>Registrierung</h4>
    <form [formGroup]="registerForm" style="margin-top: 1.75rem" (ngSubmit)="onSubmit()">
      <label class="form-label w-100">
        Email
        <input type="text" formControlName="email"  (input)="onSearchQueryInput($event)"
               [style]=" isEmailExist || (registerForm.get('email')!.hasError('required') || registerForm.get('email')!.hasError('pattern')) && registerForm.get('email')!.touched ? 'padding-left: 2.25rem;' : '' "
               [ngClass]="isEmailExist || (registerForm.get('email')!.hasError('required') || registerForm.get('email')!.hasError('pattern')) && registerForm.get('email')!.touched ? 'invalid' : '' "
               class="form-control" placeholder="Ihre Email">
        <i *ngIf="isEmailExist || (registerForm.get('email')!.hasError('required') || registerForm.get('email')!.hasError('pattern')) && registerForm.get('email')!.touched" class="icon-IconCancel"></i>
        <span *ngIf="isEmailExist" style="color: red; font-size: 12px; font-weight: 600;">Diese E-Mail ist bereits vorhanden</span>
        <span *ngIf="registerForm.get('email')!.hasError('required') && registerForm.get('email')!.touched" style="color: red; font-size: 12px; font-weight: 600;">Dieses Feld ist erforderlich.</span>
        <span *ngIf="registerForm.get('email')!.hasError('pattern') && registerForm.get('email')!.touched" style="color: red; font-size: 12px; font-weight: 600;">Falsche E-Mail.</span>
      </label>
      <label class="form-label w-100" style="margin-top: 0.75rem">
        Vorname
        <input type="text" formControlName="firstName" class="form-control" placeholder="Ihre Vorname" maxlength="40"
               [style]="(registerForm.get('firstName')!.hasError('required') || registerForm.get('firstName')!.hasError('pattern')) && registerForm.get('firstName')!.touched ? 'padding-left: 2.25rem;' : '' "
               [ngClass]="(registerForm.get('firstName')!.hasError('required') || registerForm.get('firstName')!.hasError('pattern')) && registerForm.get('firstName')!.touched ? 'invalid' : '' "
        >
        <i *ngIf="(registerForm.get('firstName')!.hasError('required') || registerForm.get('firstName')!.hasError('pattern')) && registerForm.get('firstName')!.touched" class="icon-IconCancel"></i>
        <span *ngIf="registerForm.get('firstName')!.hasError('required') && registerForm.get('firstName')!.touched" style="color: red; font-size: 12px; font-weight: 600;">Dieses Feld ist erforderlich.</span>
        <span *ngIf="registerForm.get('firstName')!.hasError('pattern') && registerForm.get('firstName')!.touched" style="color: red; font-size: 12px; font-weight: 600;">Sie können keine bestimmten Symbole im Feld vorname verwenden</span>
      </label>
      <label class="form-label w-100" style="margin-top: 0.75rem">
        Nachname
        <input type="text" formControlName="lastName" class="form-control" placeholder="Ihre Nachname" maxlength="40"
               [style]="(registerForm.get('lastName')!.hasError('required') || registerForm.get('lastName')!.hasError('pattern')) && registerForm.get('lastName')!.touched ? 'padding-left: 2.25rem;' : '' "
               [ngClass]="(registerForm.get('lastName')!.hasError('required') || registerForm.get('lastName')!.hasError('pattern') )&& registerForm.get('lastName')!.touched ? 'invalid' : '' "
        >
        <i *ngIf="(registerForm.get('lastName')!.hasError('required') || registerForm.get('lastName')!.hasError('pattern')) && registerForm.get('lastName')!.touched" class="icon-IconCancel"></i>
        <span *ngIf="registerForm.get('lastName')!.hasError('required')  && registerForm.get('lastName')!.touched" style="color: red; font-size: 12px; font-weight: 600;">Dieses Feld ist erforderlich.</span>
        <span *ngIf="registerForm.get('lastName')!.hasError('pattern') && registerForm.get('lastName')!.touched" style="color: red; font-size: 12px; font-weight: 600;">Sie können keine bestimmten Symbole im Feld nachname verwenden</span>
      </label>
      <label class="form-label w-100" style="margin-top: 0.75rem">
        Passwort
        <input type="password" formControlName="password" class="form-control" placeholder="*****"
               [style]="((registerForm.errors?.['passwordMatching'] && registerForm.get('passwordConfirm')!.touched ) || registerForm.get('password')!.hasError('required')
                || registerForm.get('password')!.hasError('minlength') || registerForm.get('password')!.hasError('requiresSpecialChars'))
               && registerForm.get('password')!.touched ? 'padding-left: 2.25rem;' : '' "
               [ngClass]="((registerForm.errors?.['passwordMatching'] && registerForm.get('passwordConfirm')!.touched) || registerForm.get('password')!.hasError('required')
                || registerForm.get('password')!.hasError('minlength') || registerForm.get('password')!.hasError('requiresSpecialChars'))
               && registerForm.get('password')!.touched ? 'invalid' : '' " minlength="10">
        <i *ngIf="((registerForm.errors?.['passwordMatching'] && registerForm.get('passwordConfirm')!.touched) || registerForm.get('password')!.hasError('required')
                || registerForm.get('password')!.hasError('minlength') || registerForm.get('password')!.hasError('requiresSpecialChars'))
               && (registerForm.get('password')!.touched || registerForm.get('passwordConfirm')!.touched)" class="icon-IconCancel"></i>
        <span *ngIf="registerForm.errors?.['passwordMatching'] && registerForm.get('passwordConfirm')!.touched
         && !registerForm.get('password')!.hasError('minlength') && !registerForm.get('password')!.hasError('minlength')
        && !registerForm.get('password')!.hasError('requiresSpecialChars')" style="color: red; font-size: 12px; font-weight: 600;">Die Passwörter sollten gleich sein</span>
        <span *ngIf="registerForm.get('password')!.hasError('required') && registerForm.get('password')!.touched" style="color: red; font-size: 12px; font-weight: 600;">Dieses Feld ist erforderlich.</span>
        <span *ngIf="registerForm.get('password')!.hasError('minlength') && registerForm.get('password')!.touched" style="color: red; font-size: 12px; font-weight: 600;">Ein Passwort muss aus mindestens zehn Zeichen bestehen.</span>
        <span *ngIf="registerForm.get('password')!.hasError('requiresSpecialChars') && registerForm.get('password')!.touched && !registerForm.get('password')!.hasError('minlength')" style="color: red; font-size: 12px; font-weight: 600;">Das Passwort sollte mindestens ein Sonderzeichen enthalten.</span>
      </label>
      <label class="form-label w-100" style="margin-top: 0.75rem">
        Passwort bestätigen
        <input type="password" formControlName="passwordConfirm" class="form-control" placeholder="*****"
               [style]=" registerForm.errors?.['passwordMatching'] && registerForm.get('passwordConfirm')!.touched ? 'padding-left: 2.25rem;' : '' "
               [ngClass]="registerForm.errors?.['passwordMatching'] && registerForm.get('passwordConfirm')!.touched ? 'invalid' : '' ">
        <i *ngIf="registerForm.errors?.['passwordMatching'] && registerForm.get('passwordConfirm')!.touched" class="icon-IconCancel"></i>
        <span *ngIf="registerForm.errors?.['passwordMatching'] && registerForm.get('passwordConfirm')!.touched" style="color: red; font-size: 12px; font-weight: 600;">Die Passwörter sollten gleich sein</span>
      </label>
      <button type="submit" class="btn btn-primary w-100" style="margin-top: 2rem; height: 2.25rem; line-height: 20px;" [disabled]="registerForm.invalid || isEmailExist">Registrieren</button>
    </form>
  </div>
</div>
<div *ngIf="isFormSend" style="margin-top: 8rem;" class="card col-lg-3 col-11 mx-auto">
  <div class="card-body">
    <div class="text-center">
      <h4>Registrierung fast abgeschlossen</h4>
     <div class="card check-email-message" style="margin-top: 1.75rem">
       Wir haben Ihnen eine E-Mail mit dem Aktivierungslink für Ihr Konto geschickt, bitte überprüfen Sie Ihr Postfach.
     </div>
    </div>
  </div>
</div>
<div *ngIf="!isFormSend" class="text-center mt-md-4 link">
  <span class="link-btn" (click)="GoToForgotPassword()">Passwort vergessen </span> |
  <span class="link-btn" (click)="GoToLogin()"> Anmeldung</span>
</div>
<div *ngIf="isFormSend" class="text-center mt-md-4 link">
  <span class="link-btn" (click)="GoToLogin()">Zurück zum Login</span>
</div>
<footer>
  <a [href]="'https://www.ing-plus.de/datenschutz'" target="_blank" style="text-decoration: none" class="link-btn">Datenschutzerklärung   </a> |
  <a [href]="'https://www.ing-plus.de/impressum'" target="_blank" style="text-decoration: none"  class="link-btn">   Impressum </a>
</footer>

