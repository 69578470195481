import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Evaluation } from '../models/evaluation';
import { AnswerEvaluation } from '../models/answerEvaluation';
import { Observable } from 'rxjs';
import { EvaluationCatalog } from '../models/evaluationCatalog';

@Injectable({
  providedIn: 'root',
})
export class RemediationMeasuresService {
  constructor(private http: HttpClient) {}

  getEvaluation(tenantName: string, serverName: string) {
    return this.http.get<Evaluation>(
      `${environment.EvaluationV2.EvaluationUrl}next?tenant=${tenantName}&source=${serverName}`
    );
  }

  getPrevious(tenantName: string, serverName: string) {
    return this.http.get<Evaluation>(
      `${environment.EvaluationV2.EvaluationUrl}last?tenant=${tenantName}&source=${serverName}`
    );
  }

  sendEvaluation(answer: AnswerEvaluation) {
    return this.http.post<Evaluation>(
      `${environment.EvaluationV2.EvaluationUrl}evaluate`,
      answer
    );
  }

  getAvailableMeasures(tenantName: string, serverName: string) {
    return this.http.get<Evaluation>(
      `${environment.EvaluationV2.EvaluationUrl}next?tenant=${tenantName}&source=${serverName}&measures=true`
    );
  }

  toggleFavoriteMeasure(id: string): Observable<boolean> {
    return this.http.get<boolean>(
      `${environment.EvaluationV2.EvaluationUrl}favorite?id=${id}`
    );
  }

  getMeasure(
    tenantName: string,
    serverName: string,
    objectId: string
  ): Observable<EvaluationCatalog[]> {
    return this.http.get<EvaluationCatalog[]>(
      `${environment.EvaluationV2.EvaluationUrl}measures?tenant=${tenantName}&source=${serverName}&id=${objectId}`
    );
  }
}
