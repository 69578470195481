import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Evaluation } from '../models/evaluation';
import { AnswerEvaluation } from '../models/answerEvaluation';

@Injectable({
  providedIn: 'root'
})
export class SewerTinderService {

  constructor(private http: HttpClient) { }

  GetEvaluation(tenantName: string, serverName: string){
    return this.http.get<Evaluation>(environment.Evaluation.EvaluationUrl + tenantName + '&source=' + serverName);
  }

  GetPrevious(tenantName: string, serverName: string){
    return this.http.get<Evaluation>(environment.Evaluation.EvaluationPreviousUrl + tenantName + '&source=' + serverName);
  }

  SendYesEvaluation(answer: AnswerEvaluation){
    return this.http.post<Evaluation>(environment.Evaluation.PositiveEvaluation, answer);
  }

  SendNoEvaluation(answer: AnswerEvaluation){
    return this.http.post<Evaluation>(environment.Evaluation.NegativeEvaluation, answer);
  }


  SendNotClearEvaluation(answer: AnswerEvaluation){
    return this.http.post<Evaluation>(environment.Evaluation.UnclearEvaluation, answer);
  }
}
