<div class="text-center">
  <img src="./assets/Images/Logo.png">
</div>
<div class="mobile-responsive">
<div *ngIf="!isFormSend" class="card col-lg-3 col-11 mx-auto">
  <div class="card-body">
    <h4>Passwort wiederherstellen</h4>
    <p>Geben Sie hier die Emailadresse ein, mit der Sie sich registriert haben, und wir senden Ihnen einen Link zum Zurücksetzen des Passworts.</p>
    <form [formGroup]="recoveryForm" style="margin-top: 1.75rem" (ngSubmit)="onSubmit()">
      <label class="form-label w-100">
        Email
        <input type="text" formControlName="email" class="form-control" placeholder="Ihre Email"
               [style]="(recoveryForm.get('email')!.hasError('required') || recoveryForm.get('email')!.hasError('pattern')) && recoveryForm.get('email')!.touched ? 'padding-left: 2.25rem;' : '' "
               [ngClass]="(recoveryForm.get('email')!.hasError('required') || recoveryForm.get('email')!.hasError('pattern')) && recoveryForm.get('email')!.touched ? 'invalid' : '' "
        >
        <i *ngIf="(recoveryForm.get('email')!.hasError('required') || recoveryForm.get('email')!.hasError('pattern')) && recoveryForm.get('email')!.touched" class="icon-IconCancel"></i>
        <span *ngIf="recoveryForm.get('email')!.hasError('required') && recoveryForm.get('email')!.touched" style="color: red; font-size: 12px; font-weight: 600;">Dieses Feld ist erforderlich.</span>
        <span *ngIf="recoveryForm.get('email')!.hasError('pattern') && recoveryForm.get('email')!.touched" style="color: red; font-size: 12px; font-weight: 600;">Falsche E-Mail.</span>
      </label>
      <button type="submit" class="btn btn-primary w-100" style="margin-top: 2rem; height: 2.25rem;"  [disabled]="recoveryForm.invalid">Passwort zurücksetzen</button>
    </form>
  </div>
</div>
<div *ngIf="isFormSend" style="margin-top: 8rem;" class="card col-lg-3 col-11 mx-auto">
  <div class="card-body">
      <h4>Passwort wiederherstellen</h4>
      <div class="card check-email-message" style="margin-top: 1.75rem">
        Wenn der Benutzer "{{email}}" exisitert, wurde an diese Emailadresse ein Link zum zurücksetzen des Passworts geschickt.
      </div>
  </div>
</div>
<div class="text-center mt-md-4 link">
  <span class="link-btn" (click)="GoToLogin()">Zurück zum Login</span>
</div>
</div>
  <footer>
  <a [href]="'https://www.ing-plus.de/datenschutz'" target="_blank" style="text-decoration: none" class="link-btn">Datenschutzerklärung   </a> |
  <a [href]="'https://www.ing-plus.de/impressum'" target="_blank" style="text-decoration: none"  class="link-btn">   Impressum </a>
</footer>

