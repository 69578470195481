import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize',
  standalone: true
})
export class FileSizePipeStandalone implements PipeTransform {
  kb = 1024;
  mb = this.kb * 1024;
  gb = this.mb * 1024;

  transform(value: number | null): any {
    if (!value) {
      return `0 B`
    }
    if (value > this.gb) {
      return `${Math.round(value / this.gb * 100) / 100} GB`;
    } else if (value > this.mb) {
      return `${Math.round(value / this.mb * 100) / 100} MB`;
    } else if (value > this.kb) {
      return `${Math.round(value / this.kb * 100) / 100} KB`;
    } else {
      return `${value} B`
    }
  }

}
