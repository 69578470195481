<div class="card">
  <div class="card-body mobile-display-none">
    <div class="row">
      <div class="col-xl-12 col-md-6 card-border">
    <p class="tablet-margin-top-0" style="margin-top: 0.75rem; font-weight: 600;">Prüfprotokoll-Kontingent</p>
    <p class="mt-1"> <span style="font-size: 40px">{{subscription?.availableUploads}}</span> von {{subscription?.totalUploads}} übrig
    </p>
    <div class="progress">
      <div class="progress-bar" role="progressbar" [ngStyle]="{'width': currentUploadPersent + '%'}" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
    <span class="d-block" style="margin-top: 1.25rem; font-size: 14px;">Ihr Prüfprotokoll-Kontingent erneuert sich am</span>
    <span class="d-block" style="font-weight: 700;">{{subscription?.uploadsResetTime | date :'dd.MM.yyyy - HH:mm' }} Uhr</span>
      </div>
    <hr />
      <div class="col-xl-12 col-md-6">
    <p style="font-weight: 600;">Korrektur-Guthaben</p>
    <p class="mt-1"> <span style="font-size: 40px">{{subscription?.availableCredits}}</span> von {{subscription?.totalCredits}} übrig
    </p>
    <div class="progress" style="margin-bottom: 1.25rem;">
      <div class="progress-bar" role="progressbar" [ngStyle]="{'width': availableCreditPersent + '%'}" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
      </div>
  </div>
  </div>
  <div class="card-body mobile-view">
    <div class="row">
      <div class="col-6">
        <p class="mt-1"> <span style="font-size: 26px">{{subscription?.availableUploads}}</span> / {{subscription?.totalUploads}} übrig
        </p>
        <div class="progress">
          <div class="progress-bar" role="progressbar" [ngStyle]="{'width': currentUploadPersent + '%'}" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
      <div class="col-6">
        <p class="mt-1"> <span style="font-size: 26px">{{subscription?.availableCredits}}</span> / {{subscription?.totalCredits}} übrig
        </p>
        <div class="progress" style="margin-bottom: 1.25rem;">
          <div class="progress-bar" role="progressbar" [ngStyle]="{'width': availableCreditPersent + '%'}" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
    </div>
</div>
</div>
