export enum ErrorFixingStatus {
  Unknown,
  NoFixRequired,
  NoFixAvailable,
  FixAvailable,
  FixInProgress,
  FixComplete,
  FixFailed,
  OfferRequestAvailable,
  OfferRequestInReview,
  OfferReceived
}
