<div class="d-flex">
  <div class="modal-body" style="padding: 40px">
    <div class="d-flex">
      <i [class]="icon + ' my-auto'"></i>
      <div style="margin-left: 1rem;">
        <h5>{{title}}</h5>
        <span style="font-size: 14px;">{{description}}</span>
      </div>
    </div>
    <div class="float-end w-100 mt-4">
      <button type="button" class="btn btn-link btn-secondary ws-3" style="width: 48%;" (click)="onCancel()">{{denyButton}}</button>
      <button type="button" class="btn btn-primary ml-3 ws-3" style="width: 48%; margin-left: 0.75rem;" (click)="onConfirm()">{{acceptButton}}</button>
    </div>
  </div>
</div>