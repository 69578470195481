import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginDTO } from '../../_models/Login';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthUploadUserService } from 'src/app/_services/auth-upload-user.service';
import { UploadFilesType } from 'src/app/upload/models/Upload';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
})
export class OtpComponent {
  loginForm: FormGroup;
  isError: boolean = false;
  model: LoginDTO = {
    email: '',
    password: '',
  };
  email = '';
  code = '';
  emailError = false;
  passwordError = false;
  currentFiles: File[] = [];
  MAX_SIZE_OF_FILES_POOL = 10 * 1024 * 1024;

  uploadFileTypeOptions = [
    {
      label: 'Reguläre Untersuchung',
      value: UploadFilesType.GeneralInspection,
    },
    { label: 'Voruntersuchung', value: UploadFilesType.Presurvey },
    { label: 'Nachuntersuchung', value: UploadFilesType.FollowUp },
    {
      label: 'Abnahmebefahrung Baustelle',
      value: UploadFilesType.AcceptanceOfConstruction,
    },
    {
      label: 'Abnahmebefahrung Sanierung',
      value: UploadFilesType.AcceptanceOfRenovation,
    },
  ];

  notUploadedStructure: any[] = [];

  constructor(
    private fb: FormBuilder,
    private authUploadUserService: AuthUploadUserService,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {
    this.loginForm = this.fb.group({
      name: [this.model.email, Validators.required],
      type: [this.model.password, Validators.required],
      comment: [''],
    });
    this.code = this.route.snapshot.paramMap.get('id') ?? '';
  }

  checkEmail() {
    const body = {
      email: this.loginForm.get('email')!.value,
      parameter: this.code,
    };
    this.authUploadUserService.checkCode(body).subscribe({
      next: () => (this.email = body.email),
      error: () => (this.emailError = true),
    });
  }

  checkPassword() {
    const body = {
      email: this.loginForm.get('email')!.value,
      parameter: this.loginForm.get('password')!.value,
    };
    this.authUploadUserService.loginByCode(body).subscribe({
      next: () => console.log(57, 'success'),
      error: () => (this.passwordError = true),
    });
  }

  cancelUpload() {}

  upload() {}

  onFileReChange(newFiles: any, folderPath?: string) {
    // const newFiles = Array.from(event.target.files);
    this.currentFiles = this.currentFiles.concat(
      newFiles.map((item: any) => {
        const file = folderPath
          ? {
              lastModifiedDate: item.lastModifiedDate,
              name: item.name,
              size: item.size,
              type: item.type,
              webkitRelativePath: `${folderPath}${item.name}`,
            }
          : item;
        this.checkAddedFilesStructure(
          file.webkitRelativePath,
          this.notUploadedStructure
        );
        this.checkAddedFolderStructure(this.notUploadedStructure);
        return file;
      })
    );
    const sum = this.currentFiles.reduce(
      (partialSum, a) => partialSum + a.size,
      0
    );
    if (sum > this.MAX_SIZE_OF_FILES_POOL) {
      this.currentFiles = [];
      this.toastr.error(
        'ES dürfen keine Dateien mit mehr als 300 GB hochladen.',
        'Fehler'
      );
      return;
    }
    this.currentFiles = this.currentFiles.filter(
      (x) => x.name.split('.').pop() !== 'DS_Store'
    );
    if (!newFiles.length) {
      this.toastr.error(
        'Bitte lesen Sie die Upload-Anweisungen und versuchen Sie es noch einmal',
        'Sie haben falsche Dateien hochgeladen'
      );
    } else {
      this.toastr.success(
        'Du kannst die gewählten Dateien jetzt hochladen!',
        'Erfolgreich hinzugefügt'
      );
    }
    this.notUploadedStructure = this.divideFolderStructure(
      this.currentFiles.map((file) => file.name)
    );
    console.log(134, this.currentFiles);
  }

  divideFolderStructure(list: string[]) {
    const ar: any[] = [
      {
        name: 'Root Folder',
        type: 'folder',
        isOpen: true,
        path: '',
        children: [],
      },
    ];
    list.forEach((item) => this.divideFolder(item, item, ar[0].children));
    return ar;
  }

  private divideFolder(name: string, path: string, ar: any[]) {
    const index = name.indexOf('/');
    if (index === -1) {
      ar.push({ name, path, type: 'file', isAdded: false, isUploaded: false });
      return;
    } else {
      const nameFolder = name.slice(0, index);
      let folder = ar.find((item) => item.name === nameFolder);
      if (!folder) {
        ar.push({
          name: nameFolder,
          type: 'folder',
          isOpen: true,
          path: path.slice(0, -name.slice(index + 1).length),
          isAdded: false,
          children: [],
        });
        folder = ar[ar.length - 1];
      }
      this.divideFolder(name.slice(index + 1), path, folder.children);
    }
  }

  checkAddedFilesStructure(
    path: string,
    structure: any[],
    isRemove = false
  ): boolean {
    return structure.some((item) => {
      if (item.path === path) {
        item.isAdded = !isRemove;
        return true;
      }
      if (item.type === 'folder') {
        return this.checkAddedFilesStructure(path, item.children, isRemove);
      }
      return false;
    });
  }

  checkAddedFolderStructure(structure: any[]): boolean {
    return structure.every((item) => {
      if (item.type === 'folder') {
        let foldersChildren = item.children.filter(
          (child: any) => child.type === 'folder'
        );
        this.checkAddedFolderStructure(foldersChildren);
        item.isAdded = item.children.every((child: any) => child.isAdded);
      }
      return item.isAdded;
    });
  }
}
