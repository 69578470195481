import {inject} from "@angular/core";
import {TokenService} from "../_services/token.service";
import {Router} from "@angular/router";

export const TokenGuard = () => {
  const service = inject(TokenService);
  const router = inject(Router);
  const token = service.getToken();
  if(token != null){
    router.navigateByUrl('/');
    return false;
  }
  return true;
};
