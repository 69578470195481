import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {EventDTO} from "../models/EventDTO";
import {PagedEventList} from "../models/PagedEventList";

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(private http: HttpClient) { }

  CreateUpdateEvent(event: EventDTO){
    return this.http.post<EventDTO>(environment.apiUrl + 'userEvent', event);
  }

  GetEvents( pageNumber: number, pageSize = 10, search: string, orderBy: string = 'creatingDate', isAsc: boolean = true){
    return this.http.get<PagedEventList>(environment.apiUrl + 'userEvents?pageNumber=' + pageNumber +'&PageSize='
      + pageSize + '&Search=' + search + '&OrderBy=' + orderBy + '&IsAsc=' + isAsc);
  }

  RemoveEvent(eventId: string){
    return this.http.delete<any>(environment.apiUrl + 'userEvent/' + eventId);
  }

  GetEventById(eventId: string){
    return this.http.get<EventDTO>(environment.apiUrl + 'userEvent/' + eventId);
  }

  GetLastsEvents(){
    return this.http.get<EventDTO[]>(environment.apiUrl + 'lastUserEvents');
  }
}
