import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginDTO } from '../../_models/Login';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthUploadUserService } from 'src/app/_services/auth-upload-user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login-otp',
  templateUrl: './login-otp.component.html',
  styleUrls: ['./login-otp.component.scss'],
})
export class LoginOtpComponent {
  loginForm: FormGroup;
  isError: boolean = false;
  model: LoginDTO = {
    email: '',
    password: '',
  };
  email = '';
  code = '';
  tenantName = '';
  emailError = false;
  passwordError = false;
  isEmailError = false;
  isOtp = false;
  emailRegExp =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(
    private fb: FormBuilder,
    private authUploadUserService: AuthUploadUserService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.loginForm = this.fb.group({
      email: [
        this.model.email,
        [Validators.required, Validators.pattern(this.emailRegExp)],
      ],
      password: [this.model.password, Validators.required],
    });
    this.code = this.route.snapshot.paramMap.get('id') ?? '';
    this.tenantName = this.route.snapshot.queryParams['tenant'] ?? '';
    console.log(41, this.route.snapshot.queryParams);
  }

  getNewPassword() {
    this.toastr.success(
      'Prüfen Sie Ihre E-Mail auf ein neues Passwort',
      'Neues Passwort wurde gesendet'
    );
    this.checkEmail();
  }

  checkEmail() {
    const body = {
      email: this.loginForm.get('email')!.value,
      parameter: this.code,
    };
    this.authUploadUserService.checkCode(body).subscribe({
      next: (res) => {
        this.isOtp = res;
        this.email = body.email;
      },
      error: () => (this.isEmailError = true),
    });
  }

  checkPassword() {
    const body = {
      email: this.loginForm.get('email')!.value,
      parameter: this.loginForm.get('password')!.value,
    };
    this.authUploadUserService.loginByCode(body).subscribe({
      next: (res) => {
        localStorage.setItem('otp-id', this.code);
        localStorage.setItem('otp-code', res);
        this.router.navigate(['otp/upload']);
      },
      error: () => (this.passwordError = true),
    });
  }
}
