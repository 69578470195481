import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { AppRoutingModule } from './app-routing.module';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { AuthService } from './_services/auth.service';
import { TenantService } from './_services/tenant.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ConfirmmodalComponent } from './components/confirmmodal/confirmmodal.component';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { ErrorCatchingInterceptor } from './_helpers/error-catching.interceptor';
import { authInterceptorProviders } from './_helpers/auth.interceptor';
import { RecoveryPasswordComponent } from './pages/recovery-password/recovery-password.component';
import { CompliteRegistrationComponent } from './pages/complite-registration/complite-registration.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AutosizeModule } from 'ngx-autosize';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { AnnouncementWidgetComponent } from './components/announcement-widget/announcement-widget.component';
import { EventsWidgetComponent } from './components/events-widget/events-widget.component';
import { WeatherWidgetComponent } from './components/weather-widget/weather-widget.component';
import { FeedbackWidgetComponent } from './components/feedback-widget/feedback-widget.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { TimePastPipe } from './upload/_pipes/time-past.pipe';
import { NotificationsDashboardComponent } from './components/notifications-dashboard/notifications-dashboard.component';
import { FileSizePipeStandalone } from './upload/_pipes/file-size.pipe';
import { BlockUIModule } from 'ng-block-ui';
import { LoginOtpComponent } from './pages/login-otp/login-otp.component';
import { OtpComponent } from './pages/otp/otp.component';
import { NgxSelectModule } from 'ngx-select-ex';
import { DragAndDropComponent } from './pages/drag-and-drop/drag-and-drop.component';
import { DndDirective } from './directives/dnd.directive';
import { IconByExtantionPipe } from './upload/_pipes/icon-by-extantion.pipe';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    DashboardComponent,
    ConfirmmodalComponent,
    LoginComponent,
    HomeComponent,
    SignUpComponent,
    RecoveryPasswordComponent,
    CompliteRegistrationComponent,
    ChangePasswordComponent,
    AnnouncementWidgetComponent,
    EventsWidgetComponent,
    WeatherWidgetComponent,
    FeedbackWidgetComponent,
    ErrorPageComponent,
    NotificationsComponent,
    TimePastPipe,
    NotificationsDashboardComponent,
    LoginOtpComponent,
    OtpComponent,
    DragAndDropComponent,
    DndDirective,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    PopoverModule.forRoot(),
    NgxPaginationModule,
    BsDropdownModule.forRoot(),
    AutosizeModule,
    CarouselModule.forRoot(),
    ClickOutsideModule,
    NgxChartsModule,
    TooltipModule.forRoot(),
    FileSizePipeStandalone,
    BlockUIModule.forRoot(),
    NgxSelectModule,
    IconByExtantionPipe,
  ],
  providers: [
    AuthService,
    TenantService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorCatchingInterceptor,
      multi: true,
    },
    authInterceptorProviders,
    { provide: LOCALE_ID, useValue: 'de-DE' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
