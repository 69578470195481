<h1 class="mt-4 mb-0 mobile-display-none" style="margin-left:2rem;">Sofortmaßnahmen</h1>
<div *ngIf="isLoading" class="loader-position">
    <span class="loader"></span>
</div>
<ng-container *ngIf="!isLoading">
    <div *ngIf="!isNoData; else noData" class="card col-12 col-lg-8 mx-auto mt-4 p-0">
        <div *ngIf="!isFinished" class="card-body">
            <div class="zoom-wrapper-container" #zoomWrapperContainer>
                <div class="zoom-wrapper position-relative" #zoomWrapper>
                    <button *ngIf="!isPrevious" type="button" class="btn position-absolute button-back"
                        (click)="goPrevious()"><i class="icon-IconArrow-Left"></i></button>
                    <lib-ngx-image-zoom class="w-100 img" [magnification]="1" [enableScrollZoom]="true" [enableLens]="true"
                        [lensWidth]="100000" [magnification]="1" [lensHeight]="1000" [thumbImage]="evaluation!.imageUrl"
                        [fullImage]="evaluation!.imageUrl"></lib-ngx-image-zoom>
                </div>
            </div>
            <div class="mx-4" style="margin-top: 0.75rem;">
                <div class="info-block">
                    <div style="display: inline-block; font-size: 15px;" class="position-relative">
                        <span class="label">Haltung: </span>
                        <b style="width: max-content;">{{evaluation?.objectName}}</b>
                    </div>
                    <span class="float-end label"
                        style="font-size: 14px;">{{evaluation?.order}}/{{evaluation?.maxOrder}}</span>
                    <br>
                    <div style="display: inline-block; font-size: 15px;">
                        <p class="m-0" style="width: max-content;">{{evaluation?.damage}}</p>
                    </div>
                </div>
                <h2 style="margin-top: 1.75rem;" class="text-center">Sofortmaßnahme erforderlich?</h2>
                <textarea [(ngModel)]="commentar" (ngModelChange)="this.commentarValidation.next($event)"
                    maxlength="300" class="mt-3 w-100 ps-3" rows="3" placeholder="Kommentar (optional)"
                    [ngClass]="isInvalid ? 'invalid':''"></textarea>
                <span class="characters-return" *ngIf="isInvalid"> {{symbolsRemains}} Zeichen übrig</span>
                <div class="row mt-4">
                    <div class="col-sm-4 col-5 px-1">
                        <button type="button" class="btn w-100" style="background: #E00303; color: white;"
                            (click)="NoAnswer()">
                            <i class="icon-IconCancel-O"></i>
                            <span> Nein</span>
                        </button>
                    </div>
                    <div class="col-sm-4 col-2 px-1">
                        <button type="button" class="btn w-100"
                            style="background: rgba(0, 94, 204, 0.1); color: var(--main-blue);" (click)="MaybeAnswer()">
                            <i class="icon-IconQuestion-O"></i>
                            <span class="mobile-display-none"> Nicht eindeutig</span>
                        </button>
                    </div>
                    <div class="col-sm-4 col-5 px-1">
                        <button type="button" class="btn w-100" style="background: #009B51; color: white;"
                            (click)="YesAnswer()">
                            <i class="icon-IconCheck-O"></i>
                            <span> Ja</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="isFinished" class="card-body" style="height: 80vh">
            <div class="text-center" style="margin-top: 25%;">
                <img style="width: 100px;" src="./assets/Images/Done.png">
                <h3 style="margin-top: 1.75rem; margin-left: -2rem;">Glückwunsch</h3>
                <small>Es gibt keine neuen Fälle zu prüfen!</small>
                <br />
                <button type="button" class="btn btn-primary w-50" style="margin-top: 1.75rem;"
                    (click)="GoHome()">Zurück
                    zur Startseite</button>
            </div>
        </div>
    </div>
    <ng-template #noData>
        <p class="no-data">Für das ausgewählte Gebiet liegen keine Daten vor</p>
    </ng-template>
</ng-container>