<div class="card" style="padding: 20px; border-radius: 12px; background: linear-gradient(216deg, #F8FAFB 0%, #89B6E4 75.21%); border-color: transparent;">
  <div style="margin: auto">
    <span class="city">{{weather?.location?.name}}</span>
    <div class="row">
      <div class="col-6">
        <img src="{{weather?.forecast?.forecastday?.[0]?.day?.condition?.icon}}" style="width: 120px;"></div>
      <div class="col-6">
        <h6 class="weather-header">Heute</h6>
        <span style="color: white; display: inline-block; margin-top: -1rem;"><span style="font-size: 50px">{{weather?.forecast?.forecastday?.[0]?.day?.maxtemp_c | number : '1.0-0'}}&deg;</span><span style="font-size: 24px;">/{{weather?.forecast?.forecastday?.[0]?.day?.mintemp_c | number : '1.0-0'}}&deg;</span>
               </span>
      </div>
    </div>
  </div>
  <div class="row">
    <div *ngFor="let day of weather?.forecast?.forecastday! | slice: 1" class="col-4 text-center">
      <img src="{{day?.day?.condition?.icon}}" style="width: 50px;">
      <div class="day-header">{{getDayName(day!.date)}}</div>
      <div style="color: white; display: inline-block; margin-top: -1rem;">
        <span style="font-size: 24px">{{day?.day?.maxtemp_c | number : '1.0-0'}}&deg;</span><span style="font-size: 17px;">/{{day?.day?.mintemp_c | number : '1.0-0'}}&deg;</span>
      </div>
    </div>
  </div>
</div>
