import {ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import { Subscription } from 'rxjs';
import { TokenService } from '../../_services/token.service';
import { EventBusService } from '../../_services/event-bus.service';
import { NavigationEnd, Router } from '@angular/router';
import { Tenant } from '../../_models/tenant';
import { DeviceDetectorService } from 'ngx-device-detector';
import jwt_decode from 'jwt-decode';
import { environment } from '../../../environments/environment';
import { SignalRService } from '../../_services/signalR.service';
import { WakeLockService } from 'src/app/_services/wake-lock.service';
import { TenantInnerService } from 'src/app/_services/tenant-inner.service';
import { ToastrService } from 'ngx-toastr';
import Hotjar from '@hotjar/browser';

export const CURRENT_SESSION_TENANT = 'CurrentSessionTenant';
export const URL_AFTER_RELOAD = 'UrlAfterReload';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  @ViewChild('pseudoVideo') pseudoVideo: any;
  sidebarExpanded = true;
  eventBusSub?: Subscription;
  isLoggedIn = false;
  username = '';
  userEmail = '';
  isMobile = false;
  isTablet = false;
  isDashboard = false;

  constructor(
    private tokenStorageService: TokenService,
    private eventBusService: EventBusService,
    public router: Router,
    private deviceService: DeviceDetectorService,
    private signalRService: SignalRService,
    private wakeLockService: WakeLockService,
    public tenantInnerService: TenantInnerService,
    private toastr: ToastrService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    const token = this.tokenStorageService.getToken();
    this.isLoggedIn = !!token;
    if (!this.isLoggedIn) {
      this.router.navigateByUrl('login');
    }
    this.eventBusSub = this.eventBusService.on('logout', () => {
      this.logout();
    });
    const decoded: any = jwt_decode(token!);
    const siteId = 3869893;
    const hotjarVersion = 6;

    Hotjar.init(siteId, hotjarVersion);
    // @ts-ignore
    window.intercomSettings = {
      api_base: environment.Intercom.Api,
      app_id: environment.Intercom.ApiId,
      name: this.tokenStorageService.getUserName(), // Full name
      email: decoded[environment.usernameKey], // Email address
      created_at: 1312182000, // Signup date as a Unix timestamp
    };
    if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
      this.sidebarExpanded = false;
    }
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.setUserTenant();
    let initNav = false;
    this.router.events.subscribe((event) => {
      if (this.deviceService.isMobile()) {
        this.sidebarExpanded = false;
      }
      if (event instanceof NavigationEnd) {
        this.isDashboard = event.url === '/' || event.url === '/admin';
      } else if (!initNav) {
        initNav = true;
        this.isDashboard =
          this.router.url === '/' || this.router.url === '/admin';
      }
    });
    this.tokenStorageService.userNameSubject.subscribe(data => {
      this.username = data;
    });
    this.changeDetectorRef.detectChanges();
    this.userEmail = this.tokenStorageService.getUserEmail();
    this.signalRService.start();
    this.wakeLockService.playPseudoSceenLockVideo$.subscribe((res) => {
      if (res) {
        this.pseudoVideo.nativeElement.play();
      } else {
        this.pseudoVideo.nativeElement.pause();
      }
    });
    if (sessionStorage.getItem(URL_AFTER_RELOAD)) {
      const url = sessionStorage.getItem(URL_AFTER_RELOAD);
      sessionStorage.removeItem(URL_AFTER_RELOAD);
      this.toastr.success(
        `Sie haben zu Mieter ${this.tokenStorageService.GetTenantDisplayName()} gewechselt`
      );
      this.router.navigate([url]);
    }
  }

  logout(): void {
    this.tokenStorageService.signOut();
    this.router.navigateByUrl('login').then(() => {
      window.location.reload();
    });
  }

  manageAccount() {
    this.router.navigateByUrl('settings/profile');
  }

  setUserTenant(): void {
    this.tenantInnerService.setUserTenant();
  }

  switchTenant(tenant: Tenant): void {
    this.tenantInnerService.switchTenant(tenant);
  }
}
