import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {Tenant} from "../_models/tenant";
import {AssignTenantDTO} from "../_models/AssignTenantDTO";
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient) { }

  getNotifications(skip: number, pageSize: number, onlyUnread: boolean){
    return this.http.get<any>(`${environment.apiUrl}Notification?SkipItems=${skip}&PageSize=${pageSize}&IsUnReadOnly=${onlyUnread}`);
  }

  readNotifications(notificationId: string, isAll: boolean) {
    return this.http.post<any>(`${environment.apiUrl}ReadNotifications`, {notificationId, isAll});
  }

  deleteNotifications(NotificationId: string, IsAll: boolean) {
    return this.http.delete<any>(`${environment.apiUrl}Notifications?NotificationId=${NotificationId}&IsAll=${IsAll}`);
  }
}
