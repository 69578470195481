import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mToKm'
})
export class MToKmPipe implements PipeTransform {

  transform(value: number): any {
    let result = Number((value / 1000).toFixed(2));
    return result.toLocaleString('de-DE');
  }

}
