import { Injectable } from '@angular/core';
import { Upload } from '../models/Upload';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PaginatedFilesList } from '../models/PaginatedFilesList';
import { UploadFile } from '../models/FileOfUpload';
import { CloudUploadFileDetails } from '../models/CloudUploadFileDetails';
import { TokenService } from '../../_services/token.service';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(private http: HttpClient, private tokenService: TokenService) {}

  CreateUpdateUpload(upload: Upload) {
    const decoded: any = jwt_decode(this.tokenService.getToken()!);
    upload.userId = decoded.id;
    upload.source = this.tokenService.GetSource();
    upload.tenant = this.tokenService.GetTenant();
    return this.http.post<Upload>(environment.apiUrl + 'uploadXml', upload);
  }

  UploadFile(file: UploadFile) {
    return this.http.post(environment.apiUrl + 'uploadFile', file);
  }

  UploadFiles(files: UploadFile[]) {
    return this.http.post(environment.apiUrl + 'uploadFiles', files);
  }

  RemoveFile(fileId: string) {
    return this.http.delete(environment.apiUrl + 'uploadFile/' + fileId);
  }

  GetXmlUploadFiles(
    pageNumber: number,
    pageSize = 10,
    searchField: string,
    orderBy = 'uploadDate',
    filterType: number,
    status: number
  ) {
    return this.http.post<PaginatedFilesList>(
      environment.apiUrl + 'FilesByTenant',
      {
        pageNumber: pageNumber,
        pageSize: pageSize,
        search: searchField,
        orderBy: orderBy,
        filterType: filterType,
        status: status,
        tenant: this.tokenService.GetTenant(),
        source: this.tokenService.GetSource(),
      }
    );
  }

  GetUploadById(uploadId: string) {
    return this.http.get<Upload>(
      environment.apiUrl + 'uploadXmlById/' + uploadId
    );
  }

  GetFilesDetails(fileId: string) {
    return this.http.get<CloudUploadFileDetails>(
      environment.upload.FileDetailUrl + fileId
    );
  }

  GetStatusOfDownload(uploadId: string) {
    let HTTPOptions: Object = {
      responseType: 'text',
    };
    return this.http.get<string>(
      environment.apiUrl + 'GetStatusOfDownload/' + uploadId,
      HTTPOptions
    );
  }

  DownloadFiles(uploadId: string) {
    let HTTPOptions: Object = {
      responseType: 'text',
    };
    return this.http.get<string>(
      environment.apiUrl + 'DownloadFiles/' + uploadId,
      HTTPOptions
    );
  }

  ChangeStatusFiles(files: UploadFile[]) {
    return this.http.put<string>(
      environment.apiUrl + 'ChangeStatusFiles',
      files
    );
  }

  SendFileToAI(fileId: string) {
    const data = {
      FileId: fileId,
      Tenant: this.tokenService.GetTenant(),
      Source: this.tokenService.GetSource(),
    };
    return this.http.post<string>(environment.upload.AIUrl, data);
  }

  SendReport(
    userName: string,
    name: string,
    reportLink: string,
    email: string
  ) {
    const data = {
      Username: userName,
      Name: name,
      ReportLink: reportLink,
      Email: email,
    };
    return this.http.post<string>(environment.apiUrl + 'SendReport', data);
  }

  GetUploadPartCount(uploadPatchId: string) {
    let HTTPOptions: Object = {
      responseType: 'number',
    };
    return this.http.get<number>(
      environment.apiUrl + 'GetCountOfUploadsPart/' + uploadPatchId,
      HTTPOptions
    );
  }

  RemoveUploads(ids: string[]){
    return this.http.post(environment.apiUrl + 'DeleteUploads', ids);
  }

  CheckUploadName(tenant: string, name: string = '') {
    return this.http.get<boolean>(environment.apiUrl + 'CheckUploadName/' + tenant + '/' + name);
  }
}
