import { Component } from '@angular/core';
import {EventService} from "../../_admin/events/service/event.service";
import {BsModalService} from "ngx-bootstrap/modal";
import {EventDTO} from "../../_admin/events/models/EventDTO";
import {
  EventPreviewModalComponent
} from "../../_admin/events/components/event-preview-modal/event-preview-modal.component";

@Component({
  selector: 'app-events-widget',
  templateUrl: './events-widget.component.html',
  styleUrls: ['./events-widget.component.scss']
})
export class EventsWidgetComponent {

  userEvents: EventDTO[] | undefined = [];
  isLoading = true;

constructor(private userEventService: EventService, private modalService: BsModalService) {
  this.userEventService.GetLastsEvents().subscribe(data => {
    this.userEvents = data;
    this.isLoading = false;
  });
}

  PreviewEvent(userEvent: EventDTO){
    const initialState = {
      name: userEvent.name,
      description: userEvent.description,
      startDate: userEvent.startDate,
      endDate: userEvent.endDate,
      image: userEvent.image
    }
    this.modalService.show(EventPreviewModalComponent, {class: 'modal-xl', initialState: initialState});
  }

  checkDate(startDate: Date, endDate: Date){
   return !( new Date(startDate).getHours() == new Date(endDate).getHours() && new Date(startDate).getMinutes() == new Date(endDate).getMinutes())
  }
}
