import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {TokenService} from "../../_services/token.service";
import {Statistic} from "../models/statistic";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class StatisticService {

  constructor(private http: HttpClient, private tokenService: TokenService) { }
  GetStatistic(){
    return this.http.get<Statistic>(environment.StatisticUrl + 'tenant=' + this.tokenService.GetTenant() + '&source=' + this.tokenService.GetSource());
  }

}
