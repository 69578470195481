import { Component } from '@angular/core';
import {TokenService} from "../../_services/token.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  currentDate = '';
  userName: string = '';
  maxLengthWidget = 40;

  constructor( private tokenService: TokenService) {
    this.userName = this.tokenService.getUserName();
    this.getCurrentDate();
  }

  getCurrentDate(){
    const date = new Date();
    this.currentDate = date.toLocaleDateString('de-DE', {weekday: 'long', day:'2-digit', month:'long'});
  }
}
