<h1 class="mt-4 mb-0 mobile-display-none" style="margin-left:2rem;">Admin</h1>
<div *ngIf="isLoading" class="loader-position">
  <span class="loader"></span>
</div>
<div *ngIf="!isLoading" class="card mt-4 user-management">
  <div class="card-body">
    <!-- Header page -->
    <div class="row">
      <div class="col-6">
        <h5>Users</h5>
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-6 d-flex flex-row">
            <span class="float-lg-end float-md-end float-start me-2 mt-2">Filter: </span>
            <ngx-select class="float-end tablet-filter-size m-0" style="margin-right: 1.25rem; width: 12vw"
              placeholder="All" [noAutoComplete]="true" [items]="filterRoles" optionValueField="value"
              optionTextField="label" (select)="filterByType($event)"></ngx-select>
          </div>
          <div class="col-6">
            <div class="row h-100">
              <div class="col-md-12 h-100">
                <div class="input-group h-100">
                  <span class="input-group-append">
                    <div class="input-group-text bg-transparent"><i class="fa fa-search"></i></div>
                  </span>
                  <input class="form-control h-100 m-0" type="text" [(ngModel)]="searchField"
                    (ngModelChange)="search.next($event)" placeholder="Tap to search">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Table -->
    <table *ngIf="users!.users.length > 0" class="table" style="margin-top: 1.25rem;">
      <thead>
        <tr>
          <th scope="col" class="col-2">User</th>
          <th scope="col" class="col-5">Tenants</th>
          <th scope="col" class="col-5">Roles</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of users!.users | paginate
                : {
                    itemsPerPage: 10,
                    currentPage: pagination,
                    totalItems: allItems
                  }">
          <td>
            <div style="font-size: 14px">{{user.firstName}} {{user.lastName}}</div>
            <div style="font-size: 13px; color: #AFAFB2">{{user.email}}</div>
          </td>
          <td style="vertical-align: middle;">
            <span style="font-size: 13px;font-weight: 600;" class="ms-1"
              *ngFor="let isLast=last let tenant of user.userTenants | sortByOrder:'tenantName'">
              {{tenant.displayName}}{{isLast ? '' : ', '}}
            </span>
          </td>
          <td style="vertical-align: middle;">
            <span class="role-block" *ngFor="let role of user.roles">
              {{(role | roleName).Role}}
            </span>
            <button type="button" class="btn btn-default btn-secondary user-action float-end"
              [popover]="userActionTemplate" placement="bottom" #pop="bs-popover" [outsideClick]="true"
              (click)="populateDropdownList(pop, user)">
              <i class="icon-IconDots"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <pagination-controls (pageChange)="renderPage($event)" previousLabel="" nextLabel=""
      class="pagination-style text-center" style="margin-top: 1.25rem;"
      *ngIf="users!.users.length > 0"></pagination-controls>
  </div>

  <div *ngIf="users!.users.length == 0" class="text-center mt-5">No Matching Users Found.</div>
</div>

<ng-template #userActionTemplate>
  <ul class="mb-0">
    <li style="cursor: pointer;font-weight: 400; color:#4F4F50" (click)="resetPassword(templateResetPassword);">Reset
      Password</li>
    <li style="cursor: pointer; font-weight: 400; color:#4F4F50" (click)="selectTenant(selectTenantTemplate)">Edit
      Tenants</li>
    <li style="cursor: pointer; font-weight: 400; color:#4F4F50" (click)="selectRole(selectRoleTemplate)">Edit Roles
    </li>
  </ul>
</ng-template>

<ng-template #selectTenantTemplate>
  <div class="modal-body" style="padding: 40px;">
    <i class="icon-IconClose" (click)="onCancel()"></i>
    <h5>Select tenants</h5>
    <span style="font-size: 14px;">The roles available for user on the next step can depend on selected tenant. Also if
      you exclude some tenant it will also exclude roles available in these tenants.</span>
    <div class="row mt-4">
      <div class="col-6" style="font-size: 14px;" *ngFor="let t of tenants | sortByOrder:'tenantName'">
        <div class="form-check">
          <input class="check-style" type="checkbox" (change)="checkSelectionTenant($event.target, t)"
            [checked]="checkUserTenant(t.tenantName)">
          <label class="form-check-label">
            {{t.displayName}}
          </label>
        </div>
      </div>
    </div>
    <div class="mt-4 col-6 float-end row">
      <div class="col-6">
        <button type="button" class="btn btn-link w-100" style="text-decoration: none;"
          (click)="onCancel()">Cancel</button>
      </div>
      <div class="col-6">
        <button class="btn btn-primary w-100" [disabled]="selectedUser!.userTenants.length == 0" (click)="onConfirm()">
          Save
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #selectRoleTemplate>
  <div class="modal-body" style="padding: 40px;">
    <i class="icon-IconClose" (click)="onCancel()"></i>
    <h5>Select roles</h5>
    <span style="font-size: 14px;">The roles available for user on the previous step can depend on selected tenant. Also
      if you exclude some tenant it will also exclude roles available in these tenants.</span>
    <div class="row mt-4">
      <div class="col-6">
        <div class="col-12" style="font-size: 14px;" >
          <div class="form-check">
            <input class="check-style" type="checkbox" [checked]="checkWasteWaterRoles()"
            (change)="checkSelectionWasteWaterRoles($event.target)">
            <label class="form-check-label">
              {{groupWasteWater}}
            </label>
          </div>
        </div>
      <div *ngFor="let role of rolesForView">
      <div class="col-12 ml-3" style="font-size: 14px;" *ngIf="role.group == groupWasteWater" >
        <div class="form-check">
          <input class="check-style" type="checkbox" (change)="checkSelectionRole($event.target, role.value)"
            [checked]="checkRole(role.value)">
          <label class="form-check-label">
            {{role.label }}
          </label>
        </div>
      </div>
    </div>
    </div>
      <div class="col-6">
      <div class="col-12" style="font-size: 14px;" >
        <div class="form-check">
          <input class="check-style" type="checkbox" (change)="checkSelectionMapRole($event.target)"
                 [checked]="checkMapsRoles()">
          <label class="form-check-label">
            {{groupMaps}}
          </label>
        </div>
      </div>
      <div *ngFor="let role of rolesForView">
        <div class="col-12 ml-3" style="font-size: 14px;" *ngIf="role.group == groupMaps" >
          <div class="form-check">
            <input class="check-style" type="checkbox" (change)="checkSelectionRole($event.target, role.value)"
                   [checked]="checkRole(role.value)">
            <label class="form-check-label">
              {{role.label}}
            </label>
          </div>
        </div>
      </div>
        <div *ngFor="let role of rolesForView">
          <div class="col-12" style="font-size: 14px;" *ngIf="role.group !== groupWasteWater && role.group !== groupMaps ">
            <div class="form-check">
              <input class="check-style" type="checkbox" (change)="checkSelectionRole($event.target, role.value)"
                     [checked]="checkRole(role.value)">
              <label class="form-check-label">
                {{role.label}}
              </label>
            </div>
          </div>
        </div>
    </div>
    </div>
    <div class="mt-4 col-6 float-end row">
      <div class="col-6">
        <button type="button" class="btn btn-link w-100" style="text-decoration: none;"
          (click)="onCancel()">Cancel</button>
      </div>
      <div class="col-6">
        <button class="btn btn-primary w-100" [disabled]="selectedUser!.roles.length == 0" (click)="onConfirm()">
          Save
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #templateResetPassword>
  <div class="modal-body" style="padding: 40px">
    <h5>Send Password Reset Email</h5>
    <div class="float-end w-100 mt-4">
      <button type="button" class="btn btn-link btn-secondary" style="width: 48%;" (click)="onCancel()">No</button>
      <button type="button" class="btn btn-primary ml-3" style="width: 48%; margin-left: 0.75rem;"
        (click)="onConfirm()">Yes</button>
    </div>
  </div>
</ng-template>
