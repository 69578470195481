<div class="text-center">
  <img src="./assets/Images/Logo.png">
</div>
<div class="mobile-responsive">
<div style="margin-top: 8rem;" class="card col-lg-3 col-11 mx-auto">
  <div class="card-body">
    <div class="text-center">
      <img class="done-img" src="./assets/Images/Done.png">
    <h4 style="margin-top: 2.5rem;">Registrierung abgeschlossen</h4>
    <small>
      Ihr Konto wurde erfolgreich erstellt. Jetzt können Sie sich mit Ihrem neuen Konto anmelden.
    </small>
  </div>
    <button type="button" class="btn btn-primary w-100" style="margin-top: 2rem; height: 2.25rem;" (click)="GoToLogin()">Anmeldung</button>
  </div>
</div>
</div>
<footer>
  <a [href]="'https://www.ing-plus.de/datenschutz'" target="_blank" style="text-decoration: none" class="link-btn">Datenschutzerklärung   </a> |
  <a [href]="'https://www.ing-plus.de/impressum'" target="_blank" style="text-decoration: none"  class="link-btn">   Impressum </a>
</footer>

