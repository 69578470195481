<div class="text-center">
  <img src="./assets/Images/Logo.png">
</div>
<div class="mobile-response">
  <div class="card mx-auto">
    <div class="card-body p-0">
      <h4>Authentifizierung zum Daten-Upload für {{tenantName}}</h4>
      <form [formGroup]="loginForm" style="margin-top: 1.75rem">
        <ng-container *ngIf="!email">
          <div *ngIf="isEmailError" style="margin-top: 1.75rem" class="validation-card">
            <i class="icon-IconExclamation-Circle"></i>
            <div>
              <p class="mb-0 error-text"><b>Das Formular ist abgelaufen.</b></p>
              <p class="mb-0 error-text">Der Zugriff auf das Formular ist abgelaufen.</p>
            </div>
          </div>
          <label class="form-label w-100" >
            Email<i class="required">*</i>
            <input type="text" formControlName="email" class="form-control" placeholder="Ihre Email"
                   [ngClass]="isError || loginForm.get('email')!.invalid && loginForm.get('email')!.touched ? 'invalid' : ''"
                   [style]="loginForm.get('email')!.invalid && loginForm.get('email')!.touched ? 'padding-left: 2.25rem;' : '' "
            >
            <i *ngIf="loginForm.get('email')!.invalid && loginForm.get('email')!.touched" class="icon-IconCancel"></i>
            <span *ngIf="loginForm.get('email')!.hasError('pattern') && loginForm.get('email')!.touched" class="mb-3 error-text-form">Bitte geben Sie ein gültiges E-Mail-Adressformat ein.</span>
            <span *ngIf="loginForm.get('email')!.hasError('required') && loginForm.get('email')!.touched" class="mb-3 error-text-form">Dieses Feld ist erforderlich.</span>
            <span *ngIf="isEmailError" class="mb-3 error-text-form">Bitte geben Sie eine gültige E-Mail Adresse an.</span>
          </label>
          <button class="btn btn-primary w-100" style="margin-top: 2rem; height: 2.25rem;" [disabled]="loginForm.get('email')!.invalid" (click)="checkEmail()">Authentifizieren</button>
        </ng-container>
        <ng-container *ngIf="email">
          <p *ngIf="isOtp">Ein einmaliges Passwort wurde an Ihre E-Mail-Adresse gesendet. <span class="new-pass" (click)="getNewPassword()">Fordern Sie einen neuen Code an.</span> </p>
          <label class="form-label w-100 mt-3">
            Passwort<i class="required">*</i>
            <input type="password" formControlName="password" class="form-control" [placeholder]="!isOtp ? 'Bitte geben Sie Ihr Passwort ein' : '*****'"
                   [ngClass]="passwordError || (loginForm.get('password')!.hasError('required') && loginForm.get('password')!.touched) ? 'invalid' : ''"
                   [style]="loginForm.get('password')!.hasError('required') && loginForm.get('password')!.touched ? 'padding-left: 2.25rem;' : '' ">
            <i *ngIf="loginForm.get('password')!.hasError('required') && loginForm.get('password')!.touched" class="icon-IconCancel"></i>
            <span *ngIf="loginForm.get('password')!.hasError('required') && loginForm.get('password')!.touched" class="mb-3 error-text-form">Passwort erforderlich.</span>
            <span *ngIf="passwordError" class="mb-3 error-text-form">Falsches Passwort. Bitte versuchen Sie es erneut oder fordern Sie ein neues Passwort an.</span>
          </label>
          <button class="btn btn-primary w-100" style="margin-top: 2rem; height: 2.25rem;" [disabled]="loginForm.get('password')!.invalid" (click)="checkPassword()">Authentifizieren</button>
        </ng-container>
      </form>
    </div>
  </div>
</div>
<footer>
  <a [href]="'https://www.ing-plus.de/datenschutz'" target="_blank" style="text-decoration: none" class="link-btn">Datenschutzerklärung   </a> |
  <a [href]="'https://www.ing-plus.de/impressum'" target="_blank" style="text-decoration: none"  class="link-btn">   Impressum </a>
</footer>

