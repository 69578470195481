import { Component } from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-event-preview-modal',
  templateUrl: './event-preview-modal.component.html',
  styleUrls: ['./event-preview-modal.component.scss']
})
export class EventPreviewModalComponent {
  name?: string;
  description?: string;
  image?:string;
  startDate?: Date;
  endDate?: Date;
  withTime: boolean = true;
  isOneDay = false;
  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit(){
    if( new Date(this.startDate!).getHours() == new Date(this.endDate!).getHours() && new Date(this.startDate!).getMinutes() == new Date(this.endDate!).getMinutes()){
      this.withTime = false;
    }
    if(new Date(this.startDate!).getDay() == new Date(this.endDate!).getDay()){
      this.isOneDay = true;
    }
  }

  decline(): void {
    this.bsModalRef?.hide();
  }
}
