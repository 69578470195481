import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {LoginDTO} from "../_models/Login";
import {SignUpDTO} from "../_models/signUpDTO";
import {AuthInfo} from "../_models/AuthInfo";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  Register(model: SignUpDTO){
    return this.http.post(environment.apiUrl + 'register', model);
  }

  Login(model: LoginDTO){
    return this.http.post<AuthInfo>(environment.apiUrl + 'SignIn', model);
  }

  RefreshToken(accessToken: string, refreshToken: string) {
    return this.http.post(environment.apiUrl + 'refreshtoken', {
      accessToken: accessToken,
      refreshToken: refreshToken
    });
  }

  CheckEmail(email: string) {
    return this.http.get<boolean>(environment.apiUrl + 'CheckEmail/' + email);
  }

  ConfirmRegistration(code: string, userId: string){
    return this.http.post(environment.apiUrl + 'ConfirmEmail', {
      code: code,
      userId: userId
    });
  }

  ForgotPassword(email:string){
    return this.http.get<boolean>(environment.apiUrl + 'ForgotPassword/' + email);
  }

  ChangePassword(code: string, userId: string, password:string){
    return this.http.post(environment.apiUrl + 'ChangePassword', {
      code: code,
      userId: userId,
      password: password
    });
  }

  ResetPasswordByAdmin(userId: string) {
    return this.http.get(environment.apiUrl + 'ResetPasswordByAdmin/' + userId);
  }

  changeName(firstName: string, lastName: string){
    return this.http.post(environment.apiUrl + 'ChangeName', {
      firstName: firstName,
      lastName: lastName
    });
  }

  changePasswordByUser(oldPassword: string, password: string){
    return this.http.post(environment.apiUrl + 'ChangePasswordByUser', {
      OldPassword: oldPassword,
      NewPassword: password
    });
  }
}
