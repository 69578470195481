import {Component, EventEmitter, Input} from '@angular/core';
import {TestProtocolService} from "../../services/test-protocol.service";
import {SubscriptionDetails} from "../../models/Subscription-details";
import {Observable} from "rxjs";

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss']
})
export class CreditCardComponent {
  subscription: SubscriptionDetails | undefined;
  currentUploadPersent: number = 0;
  availableCreditPersent: number = 0;

  @Input() eventEmitter: EventEmitter<string> | undefined;
  constructor( private service: TestProtocolService) {
    this.fetchData();
  }

  fetchData(){
    this.service.GetSubscriptionDetails().subscribe( data => {
      this.subscription = data;
      this.currentUploadPersent = this.GetPersentFromTotal(this.subscription!.availableUploads, this.subscription!.totalCredits);
      this.availableCreditPersent = this.GetPersentFromTotal(this.subscription!.availableCredits, this.subscription!.totalCredits);
    });

  }

ngOnInit(){
  this.eventEmitter?.subscribe((data: string) => {
   this.fetchData();
  });
}

  GetPersentFromTotal(current: number, total:number){
  return Math.trunc(current * 100 / total);
  }
}
