import {inject} from "@angular/core";
import {TokenService} from "../_services/token.service";
import {Router} from "@angular/router";
import jwt_decode from "jwt-decode";
import {environment} from "../../environments/environment";
import {ADMIN_ROLE, DATA_EXPORT_ROLE} from "./Constants";

export const dataExportGuard = () => {
  const service = inject(TokenService);
  const router = inject(Router);
  const token = service.getToken();
  if (token != null) {
    const decoded: any = jwt_decode(token);
    if (
      decoded[environment.roleKey].includes(ADMIN_ROLE) ||
      decoded[environment.roleKey].includes(DATA_EXPORT_ROLE)
    ) {
      return true;
    }
  }
  router.navigateByUrl('/');
  return false;
};
