<h1 class="mt-4 mb-0 mobile-display-none" style="margin-left:2rem;">Datenprüfung</h1>
<div *ngIf="isLoading" class="loader-position">
  <span class="loader"></span>
</div>
<div class="row mobile-margin-horizon-0" *ngIf="!isLoading" style="margin-left:2rem;">
  <div *ngIf="popupShow" class="card mobile-card-none mobile-view mt-3 col-12">
    <div class="card" style="padding: 8px 12px;">
      <div class="card-body">
        <div class="row">
          <div class="col-1"><i class="icon-IconInfo position-absolute mt-1" style="font-size: 20px"></i></div>
          <div class="col-10">
            <span style="font-size: 14px; color:var(--main-blue)">Beschränkte Funktionen auf Mobilgeräten:
    Um auf unsere volle Auswahl an Features zurückzugreifen, empfehlen wir Ihnen, die Website auf einem Desktop-PC zu öffnen.</span>
          </div>
          <div class="col-1"><i class="icon-IconClose position-absolute mt-1" style="font-size: 20px; margin-left: -1rem;" (click)="closePopup();"></i></div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 mobile-view mobile-card-none mt-3">
    <app-credit-card [eventEmitter]="parentEmitter"></app-credit-card>
  </div>
<div class="card mobile-card-none col-12 col-xl-9 col-md-11 mt-lg-4 mt-md-4 mt-3 order-xl-1 order-md-2">
  <div class="card-body">
    <h5 class="mobile-display-none">Datenprüfung</h5>
    <p style="font-weight: bold; margin-top: 0.75rem;" class="mb-0 mobile-display-none">Prüfprotokoll eines Kanalnetzes auf Basis einer ISYBAU- oder DWA-Datei mit Ergebnis- und Fehlerbericht</p>
    <a style="font-size: 1rem;" class="mobile-display-none" target="_blank" href="https://www.ing-plus.de/isybau-korrekturservice">Mehr erfahren</a>
    <div class="mobile-margin-top-0" style="margin-top:20px;">
      <button class="btn btn-primary mobile-display-none" style="padding: 8px 16px" (click)="createNewUpload()">Neues Protokoll erstellen</button>
        <div class="input-group float-end search">
                  <span class="input-group-append">
                    <div class="input-group-text bg-transparent"><i class="fa fa-search"></i></div>
                </span>
          <input class="form-control" type="text" style="height: 36px;" placeholder="Tippen um zu suchen" [(ngModel)]="searchField" (ngModelChange)="search.next($event)">
        </div>
      <ngx-select class="float-end w-25 tablet-filter-size" style="margin-right: 1.25rem;margin-top: 0.25rem;" placeholder="Alle" [noAutoComplete]="true" [items]="filterOptions"  optionValueField="value"  optionTextField="label" (select)="filterByType($event)"></ngx-select>
      <span class="float-lg-end float-md-end float-start me-2 mt-2">Filter: </span>
      </div>
  </div>
  <div *ngIf="files!.items.length == 0" class="text-center mt-lg-0 mt-3">Keine Ergebnisse gefunden</div>
  <div class="card mt-3" *ngFor="let file of files!.items | paginate
                : {
                    itemsPerPage: 10,
                    currentPage: pagination,
                    totalItems: allItems
                  }"
       [style]="file?.manaualFix?.status == ErrorFixingStatus.FixComplete
       || (file?.manaualFix?.status == ErrorFixingStatus.NoFixRequired && file?.automaticFix?.status == ErrorFixingStatus.NoFixRequired)
       || file?.automaticFix?.status == ErrorFixingStatus.FixComplete ? 'background: rgba(0, 155, 81, 0.07);border-color:rgba(0, 155, 81, 0.15);' : ''"
  >
    <div>
      <span style="font-weight: 700">{{file?.label == null || file?.label == '' ? file?.fileName : file?.label}}</span>
      <i *ngIf="file?.manaualFix?.status == ErrorFixingStatus.FixComplete
        || (file?.manaualFix?.status == ErrorFixingStatus.NoFixRequired && file?.automaticFix?.status == ErrorFixingStatus.NoFixRequired)
        || file?.automaticFix?.status == ErrorFixingStatus.FixComplete" class="icon-IconCheck-Circle position-absolute"></i>
      <span [style]="file?.manaualFix?.status == ErrorFixingStatus.FixComplete
       || (file?.manaualFix?.status == ErrorFixingStatus.NoFixRequired && file?.automaticFix?.status == ErrorFixingStatus.NoFixRequired)
       || file?.automaticFix?.status == ErrorFixingStatus.FixComplete? 'font-size: 13px; margin-left: 1.75rem;' : 'font-size: 13px; margin-left: 0.75rem;'">{{file?.uploadTime | date : 'dd.MM.yyyy - HH:mm'}}</span>
    <div class="float-end mobile-display-none">
      <i class="icon-IconEdit" (click)="changeLabel(editLabel, file)"></i>
      <i class="icon-IconDelete" style="margin-left: 0.75rem" (click)="removeFile(templateRemoveFile, file)"></i>
    </div>
    </div>
    <div class="row" style="margin-top: 0.75rem;" *ngIf="!failedFileStatus.includes(file!.fileConditionLevel)">
      <!--1 column-->
      <div class="col-12 col-md-4 col-lg-4">
        <small>Prüfbericht</small>
        <span *ngIf="file?.report?.status == ReportStatus.Open" style="margin-top: 0.75rem; font-size: 13px; color:#4F4F50;" class="d-block">Bericht wurde noch nicht erstellt</span>
        <span *ngIf="file?.report?.status == ReportStatus.InProgress" style="margin-top: 0.75rem; font-size: 13px; color:#4F4F50;" class="d-block">Bericht wird derzeit erstellt</span>
        <span *ngIf="file?.report?.status == ReportStatus.Finished
          || file?.manaualFix?.status == ErrorFixingStatus.FixComplete
          || (file?.manaualFix?.status == ErrorFixingStatus.NoFixRequired &&  file?.automaticFix?.status == ErrorFixingStatus.NoFixRequired)
          || file?.automaticFix?.status == ErrorFixingStatus.FixAvailable" style="margin-top: 0.75rem; font-size: 13px; color:#4F4F50;" class="d-block">
          <span *ngIf="file?.automaticFix?.status == ErrorFixingStatus.FixComplete
              || file?.manaualFix?.status == ErrorFixingStatus.FixComplete
              || (file?.manaualFix?.status == ErrorFixingStatus.NoFixRequired &&  file?.automaticFix?.status == ErrorFixingStatus.NoFixRequired)" style="font-size: 13px; color:#4F4F50;">Abgeschlossen</span>
          {{file?.report?.creationDate | date : 'dd.MM.yyyy - HH:mm'}}</span>
        <a *ngIf="file?.report?.status == ReportStatus.Finished
        || file?.manaualFix?.status == ErrorFixingStatus.FixComplete
        || (file?.manaualFix?.status == ErrorFixingStatus.NoFixRequired && file?.automaticFix?.status == ErrorFixingStatus.NoFixRequired)
        || file?.automaticFix?.status == ErrorFixingStatus.FixAvailable" [href]="file?.report?.downloadLinkOverview">Bericht</a>
        <a *ngIf="file?.report?.status == ReportStatus.Finished
        || file?.manaualFix?.status == ErrorFixingStatus.FixComplete
        || (file?.manaualFix?.status == ErrorFixingStatus.NoFixRequired && file?.automaticFix?.status == ErrorFixingStatus.NoFixRequired)
        || file?.automaticFix?.status == ErrorFixingStatus.FixAvailable" [href]="file?.report?.downloadLinkDetail" style="margin-left: 0.75rem;">Detaillierte Auflistungen</a>
        <hr />
      </div>
      <!--2 column-->
      <div *ngIf="(file?.report?.status == ReportStatus.Open
       || file?.report?.status == ReportStatus.InProgress
       || (file?.manaualFix?.status == ErrorFixingStatus.NoFixRequired && file?.automaticFix?.status == ErrorFixingStatus.NoFixRequired)
       || file?.automaticFix?.status == ErrorFixingStatus.Unknown)
       && file?.automaticFix?.status != ErrorFixingStatus.NoFixAvailable"  class="col-12 col-lg-4 col-md-4">
        <small>Fehlerkorrektur</small>
        <span *ngIf="file?.manaualFix?.status == ErrorFixingStatus.Unknown && file?.automaticFix?.status == ErrorFixingStatus.Unknown" style="margin-top: 0.75rem; font-size: 13px; color:#4F4F50;" class="d-block">Sobald die Fehleranalyse durchgeführt wurde, finden Sie hier die Korrekturangebote.</span>
        <span *ngIf="file?.automaticFix?.status == ErrorFixingStatus.NoFixRequired && file?.manaualFix?.status == ErrorFixingStatus.NoFixRequired" style="margin-top: 0.75rem; font-size: 13px; color:#4F4F50;" class="d-block">Die Datei ist fehlerfrei</span>
        <span *ngIf="file?.automaticFix?.status == ErrorFixingStatus.Unknown && file?.manaualFix?.status != ErrorFixingStatus.Unknown" style="margin-top: 0.75rem; font-size: 13px; color:#4F4F50;" class="d-block">Ihr Korrektur-Auftrag wird zur Zeit bearbeitet.</span>
        <hr />
      </div>
      <div *ngIf="((file?.report?.status == ReportStatus.Finished || file?.report?.status == ReportStatus.InProgress)
       && ( file?.automaticFix?.status == ErrorFixingStatus.FixAvailable
       || file?.automaticFix?.status == ErrorFixingStatus.FixFailed))
       || ( file?.manaualFix?.status != ErrorFixingStatus.NoFixRequired
       || file?.automaticFix?.status == ErrorFixingStatus.NoFixAvailable
       && file?.automaticFix?.status != ErrorFixingStatus.NoFixRequired)
       && file?.automaticFix?.status != ErrorFixingStatus.Unknown" class="col-lg-4 col-md-4 col-12">
        <small>Automatische Korrektur</small>
        <span *ngIf="file?.automaticFix?.status == ErrorFixingStatus.FixAvailable" class="{{getErrorLevelClass(file!.automaticFix!.errorLevel!)}} ms-2">{{getErrorLevelName(file!.automaticFix!.errorLevel!)}}</span>
        <span *ngIf="file?.automaticFix?.status == ErrorFixingStatus.FixAvailable" class="d-block" style="margin-top: 0.75rem; font-size: 13px">
          <span style="font-weight: bold;">{{file?.automaticFix?.errorCount}} </span> Anzahl sofort korrigierbarer Fehler
        </span>
        <span *ngIf="file?.automaticFix?.status == ErrorFixingStatus.FixFailed" class="d-block" style="margin-top: 0.75rem; font-size: 13px">Die automatische Korrektur ist fehlgeschlagen. Unser Team ist bereits informiert und wird Sie kontaktieren wenn die Korrektur abgeschlossen ist</span>
        <span *ngIf="file?.automaticFix?.status == ErrorFixingStatus.FixInProgress" class="d-block" style="margin-top: 0.75rem; font-size: 13px">Ihr Korrektur-Auftrag wird zur Zeit bearbeitet.</span>
        <span *ngIf="file?.automaticFix?.status == ErrorFixingStatus.FixComplete" class="d-block" style="margin-top: 0.75rem; font-size: 13px">Die Datei wurde automatisch korrigiert -</span>
        <span *ngIf="file?.automaticFix?.status == ErrorFixingStatus.NoFixAvailable" style="margin-top: 0.75rem; font-size: 13px; color:#4F4F50;" class="d-block">Die Datei enthält keine automatisch korrigierbaren Fehler</span>
        <a *ngIf="file?.automaticFix?.status == ErrorFixingStatus.FixComplete" [href]="file?.automaticFix?.dowloadLinkCorrectedFile" >Korrigierte Datei herunterladen</a>
        <button *ngIf="file?.automaticFix?.status == ErrorFixingStatus.FixAvailable" class="btn btn-success mt-2 tablet-w-100" style="padding: 8px 16px; overflow: hidden; text-overflow: ellipsis;" (click)="sendAutoCorrection(autoCorrection, file)">
          <i class="icon-IconCart position-absolute"></i>
         <span style="margin-left: 1.75rem;">{{file?.automaticFix?.price}} Korrektur-Guthaben</span>
        </button>
        <hr />
      </div>
      <!--3 column-->
      <div *ngIf="file?.report?.status == ReportStatus.Finished
      && ( file?.manaualFix?.status != ErrorFixingStatus.Unknown && file?.automaticFix?.status != ErrorFixingStatus.Unknown)
      && (  (file?.manaualFix?.status != ErrorFixingStatus.NoFixRequired && file?.automaticFix?.status != ErrorFixingStatus.NoFixRequired)
       || file?.manaualFix?.status == ErrorFixingStatus.FixInProgress
       || file?.manaualFix?.status == ErrorFixingStatus.FixComplete
       || file?.manaualFix?.status == ErrorFixingStatus.OfferRequestInReview)" class="col-lg-4 col-md-4 col-12">
        <small>Manuelle Korrektur</small>
        <span *ngIf="file?.manaualFix?.status == ErrorFixingStatus.OfferReceived " class="d-block" style="margin-top: 0.75rem; font-size: 13px">
          <span style="font-weight: bold;">{{file?.manaualFix?.errorCount}} </span> Sie können die manuelle Korrektur zum genannten Guthaben beauftragen
        </span>
        <span *ngIf="file?.manaualFix?.status == ErrorFixingStatus.OfferRequestAvailable" class="d-block" style="margin-top: 0.75rem; font-size: 13px">
         <span style="font-weight: bold;">{{file?.manaualFix?.errorCount}} </span> Fehler können nicht sofort behoben werden. Das nötige Guthaben zur manuellen Korrektur kann über "Angebot anfordern" eingeholt werden.
        </span>
        <span *ngIf="file?.manaualFix?.status == ErrorFixingStatus.FixComplete" style="margin-top: 0.75rem; font-size: 13px; color:#4F4F50;" class="d-block">Ihre korrigierte Datei steht Ihnen ab sofort zur Verfügung</span>
        <span *ngIf="file?.manaualFix?.status == ErrorFixingStatus.NoFixAvailable" style="margin-top: 0.75rem; font-size: 13px; color:#4F4F50;" class="d-block">Die Datei enthält keine manuell korrigierbaren Fehler</span>
        <a *ngIf="file?.manaualFix?.status == ErrorFixingStatus.FixComplete" [href]="file?.manaualFix?.dowloadLinkCorrectedFile" >Korrigierte Datei herunterladen</a>
        <span *ngIf="file?.manaualFix?.status == ErrorFixingStatus.OfferRequestInReview" style="margin-top: 0.75rem; font-size: 13px; color:#4F4F50;" class="d-block">Ihre Korrekturanfrage wird bearbeitet.</span>
        <span *ngIf="file?.manaualFix?.status == ErrorFixingStatus.FixInProgress" class="d-block" style="margin-top: 0.75rem; font-size: 13px">Die Datei wird korrigiert</span>
        <button *ngIf="file?.manaualFix?.status == ErrorFixingStatus.OfferRequestAvailable"
                (click)="sendManualCorrection(manualCorrection, file)"
                class="btn btn-primary mt-2" style="padding: 8px 16px">Angebot anfordern</button>
        <button *ngIf="file?.manaualFix?.status == ErrorFixingStatus.OfferReceived" class="btn btn-success mt-2" style="padding: 8px 16px" (click)="sendManualCorrection(manualCorrection, file)">
          <i class="icon-IconCart position-absolute"></i>
          <span style="margin-left: 1.75rem;">{{file?.manaualFix?.price}} Korrektur-Guthaben</span>
        </button>
      </div>
    </div>
    <div *ngIf="failedFileStatus.includes(file!.fileConditionLevel)" style="margin-top: 0.75rem;">
      <div class="error-block">
      <i class="icon-IconExclamation-Circle position-absolute"></i><span class="ms-4">Diese Datei ist keine gültige Isybau-Datei.</span>
      </div>
    </div>
  </div>
  <pagination-controls
    (pageChange)="renderPage($event)"
    previousLabel=""
    nextLabel=""
    class="pagination-style text-center"
    style="margin-top: 1.25rem;"
  ></pagination-controls>
</div>
  <div class="col-xl-3 col-md-11 mt-4 mobile-display-none order-xl-2 order-md-1 tablet-padding-horizontal-0" style="padding-right: 2rem; padding-left: 1.25rem;">
    <app-credit-card [eventEmitter]="parentEmitter"></app-credit-card>
  </div>
</div>

<ng-template #manualCorrection>
  <div class="modal-body" style="padding: 40px;">
    <h5>Sollen wir die Datei vollständig für Sie korrigieren?</h5>
    <span style="font-size: 14px;"> <b>{{correctionModel.errors}}</b> Fehler können nicht automatisch korrigiert werden.</span>
    <p style="font-size: 14px;">Nicht alle Fehler können automatisch korrigiert werden. Falls du die Fehler nicht selbst beheben kannst oder keine Zeit dafür hast, übernehmen wir das gerne.</p>
    <p style="font-size: 14px;">Dazu kannst du dir ein unverbindliches Angebot einholen, unser Team sieht sich die Datei an und meldet sich bei dir mit einer Kostenschätzung. Die meisten Dateien können wir in 1-2 Stunden vollständig von Fehlern befreien.</p>
    <div class="mt-4 row">
      <div class="col-6">
        <button type="button" class="btn btn-link btn-secondary w-100" style="text-decoration: none;" (click)="onCancel()">Abbrechen</button>
      </div>
      <div class="col-6 p-0">
        <button type="button" class="btn btn-primary w-100" (click)="onConfirm()">Angebot anfordern</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #autoCorrection>
  <div class="modal-body" style="padding: 40px;">
    <h5>Automatische Korrektur bestellen?</h5>
    <span style="font-size: 14px;">Anzahl sofort korrigierbarer Fehler: <b>{{correctionModel.errors}}</b></span>
    <br />
    <span style="font-size: 14px;">Hier können Sie die Korrektur der Datei in Auftrag geben. Experten würden für eine manuelle Bereinigung der Fehler <b>21,5</b> Minuten benötigen</span>
    <div class="mt-4 row">
      <div class="col-6">
        <button type="button" class="btn btn-link btn-secondary w-100" style="text-decoration: none;" (click)="onCancel()">Abbrechen</button>
      </div>
      <div class="col-6">
        <button class="btn btn-success w-100" style="overflow: hidden; text-overflow: ellipsis;" (click)="onConfirm()">
          <i class="icon-IconCart position-absolute" style="font-size: 20px;"></i>
          <span style="margin-left: 1.75rem;">{{correctionModel.costs}} Korrektur-Guthaben</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editLabel>
  <div class="modal-body" style="padding: 40px">
    <h5>Projektbezeichnung ändern</h5>
    <i class="icon-IconClose" (click)="onCancel()"></i>
    <label class="form-label w-100 mt-4" style="font-size: 12px;">
      Projektbezeichnung
      <input type="text"  class="form-control" [(ngModel)]="label" placeholder="Projektname eingeben" maxlength="128">
    </label>
    <div class="float-end mt-4 tablet-w-100 w-50">
      <button type="button" class="btn btn-link btn-secondary" style="text-decoration: none;width: 47%;" (click)="onCancel()">Abbrechen</button>
      <button type="button" class="btn btn-primary ms-3" style="padding: 8px 24px; width: 48%;" (click)="onConfirm()">Speichern</button>
    </div>
  </div>
</ng-template>

<ng-template #templateRemoveFile>
  <div class="modal-body" style="padding: 40px">
    <i class="icon-IconDelete"></i>
    <div style="margin-left: 3rem;">
    <h5>Projekt löschen?</h5>
    <span style="font-size: 14px;">Sind Sie sicher, dass Sie dieses Projekt löschen möchten?</span>
    </div>
    <div class="float-end w-100 mt-4">
      <button type="button" class="btn btn-link btn-secondary" style="width: 48%;" (click)="onCancel()">Nein</button>
      <button type="button" class="btn btn-primary ml-3" style="width: 48%; margin-left: 0.75rem;" (click)="onConfirm()">Ja</button>
    </div>
  </div>
</ng-template>
