import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { UploadDashboardComponent } from './upload/pages/upload-dashboard/upload-dashboard.component';
import { SewerTinderComponent } from './sewer-tinder/pages/sewer-tinder/sewer-tinder.component';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { RecoveryPasswordComponent } from './pages/recovery-password/recovery-password.component';
import { CompliteRegistrationComponent } from './pages/complite-registration/complite-registration.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { TestProtocolPageComponent } from './test-protocol/pages/test-protocol-page/test-protocol-page.component';
import { DashboardAnnoncementComponent } from './_admin/announcements/pages/dashboard-annoncement/dashboard-annoncement.component';
import { AdminDashboardComponent } from './_admin/pages/admin-dashboard/admin-dashboard.component';
import { adminGuard } from './_helpers/Admin.guard';
import { UploadGuard } from './_helpers/Upload.guard';
import { SewerTinderGuard } from './_helpers/SewerTinder.guard';
import { DashboardEventComponent } from './_admin/events/pages/dashboard-event/dashboard-event.component';
import { UserManagementPageComponent } from './_admin/user-managment/pages/user-managment-page/user-management-page.component';
import { StatisticPageComponent } from './dashboard-statistic/pages/statistic-page/statistic-page.component';
import { SewerTinderDashboardGuard } from './_helpers/SewerTinderDashboardGuard';
import { questionnaireGuard } from './_helpers/Questionnaire.guard';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { MapsGuard } from './_helpers/Maps.guard';
import { DamageAssessmentComponent } from './damage-assessment/pages/damage-assessment/damage-assessment.component';
import { RemediationMeasuresComponent } from './remediation-measures/pages/remediation-measures/remediation-measures.component';
import { dataExportGuard } from './_helpers/DataExport.guard';
import { TokenGuard } from './_helpers/Token.guard';
import { SubscriptionManagementComponent } from './_admin/subscriptions/pages/subscription-management/subscription-management.component';
import { SewerTinderV2Guard } from './_helpers/SewerTinderV2.guard';
import { SewerTinderV3Guard } from './_helpers/SewerTinderV3.guard';
import { LoginOtpComponent } from './pages/login-otp/login-otp.component';
import { OtpComponent } from './pages/otp/otp.component';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: '',
        component: DashboardComponent,
        pathMatch: 'full',
      },
      {
        path: 'admin',
        component: AdminDashboardComponent,
        canActivate: [adminGuard],
      },
      //{ path: 'error', component: ErrorPageComponent },
      {
        path: 'wastewater/upload-content',
        component: UploadDashboardComponent,
        loadChildren: () =>
          import('./upload/upload.module').then((m) => m.UploadModule),
        canActivate: [UploadGuard],
      },
      {
        path: 'wastewater/immediate-actions',
        component: SewerTinderComponent,
        loadChildren: () =>
          import('./sewer-tinder/sewer-tinder.module').then(
            (m) => m.SewerTinderModule
          ),
        canActivate: [SewerTinderGuard],
      },
      {
        path: 'wastewater/damage-assessment',
        component: DamageAssessmentComponent,
        loadChildren: () =>
          import('./damage-assessment/damage-assessment.module').then(
            (m) => m.DamageAssessmentModule
          ),
        canActivate: [SewerTinderV3Guard],
      },
      {
        path: 'wastewater/remediation-measures',
        component: RemediationMeasuresComponent,
        loadChildren: () =>
          import('./remediation-measures/remediation-measures.module').then(
            (m) => m.RemediationMeasuresModule
          ),
        canActivate: [SewerTinderV2Guard],
      },
      {
        path: 'wastewater/dashboard',
        component: StatisticPageComponent,
        loadChildren: () =>
          import('./dashboard-statistic/dashboard-statistic.module').then(
            (m) => m.DashboardStatisticModule
          ),
        canActivate: [SewerTinderDashboardGuard],
      },
      {
        path: 'wastewater/data-check',
        component: TestProtocolPageComponent,
        loadChildren: () =>
          import('./test-protocol/test-protocol.module').then(
            (m) => m.TestProtocolModule
          ),
      },
      {
        path: 'construction',
        loadChildren: () =>
          import('./construction-site/construction-site.module').then(
            (m) => m.ConstructionSiteModule
          ),
      },
      {
        path: 'questionnaire-user',
        loadChildren: () =>
          import('./questionnaire-user/questionnaire-user.module').then(
            (m) => m.QuestionnaireUserModule
          ),
        canMatch: [questionnaireGuard],
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./pages/settings/settings.module').then(
            (m) => m.SettingsModule
          ),
      },
      {
        path: 'maps',
        loadChildren: () =>
          import('./maps/maps.module').then((m) => m.MapsModule),
        canMatch: [MapsGuard],
      },
      {
        path: 'admin/maps',
        loadChildren: () =>
          import('./maps/maps.module').then((m) => m.MapsModule),
        canMatch: [adminGuard],
      },
      {
        path: 'admin/marketing/announcements',
        component: DashboardAnnoncementComponent,
        loadChildren: () =>
          import('./_admin/announcements/announcements.module').then(
            (m) => m.AnnouncementsModule
          ),
        canActivate: [adminGuard],
      },
      {
        path: 'admin/marketing/events',
        component: DashboardEventComponent,
        loadChildren: () =>
          import('./_admin/events/event.module').then((m) => m.EventModule),
        canActivate: [adminGuard],
      },
      {
        path: 'admin/user-management',
        component: UserManagementPageComponent,
        loadChildren: () =>
          import('./_admin/user-managment/user-management.module').then(
            (m) => m.UserManagementModule
          ),
        canActivate: [adminGuard],
      },
      {
        path: 'admin/subscriptions',
        loadChildren: () =>
          import('./_admin/subscriptions/subscriptions.module').then(
            (m) => m.SubscriptionsModule
          ),
        canActivate: [adminGuard],
      },
      {
        path: 'admin/apps/questionnaires',
        loadChildren: () =>
          import('./_admin/questionnaires/questionnaires.module').then(
            (m) => m.QuestionnairesModule
          ),
        canActivate: [adminGuard],
      },
      {
        path: 'wastewater/data-export',
        loadChildren: () =>
          import('./data-export/data-export.module').then(
            (m) => m.DataExportModule
          ),
        canActivate: [dataExportGuard],
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [TokenGuard],
  },
  {
    path: 'signup',
    component: SignUpComponent,
  },
  {
    path: 'recovery-password',
    component: RecoveryPasswordComponent,
  },
  { path: 'confirm-email', component: CompliteRegistrationComponent },
  { path: 'reset-password', component: ChangePasswordComponent },
  { path: 'otp/login/:id', component: LoginOtpComponent },
  { path: 'otp/upload', component: OtpComponent },
  { path: '**', redirectTo: '' },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
