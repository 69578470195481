<h1 class="mt-4 mb-0 mobile-display-none" style="margin-left:2rem;">Schadensbewertung</h1>
<div *ngIf="isLoading" class="loader-position">
    <span class="loader"></span>
</div>
<ng-container *ngIf="!isLoading">
    <ng-container *ngIf="!isNoData; else noData">
        <div *ngIf="selectedType === undefined || selectedType === null" class="card card-width mt-4">
            <div class="text-center content-wrapper">
                <h2>Willkommen User!</h2>
                <p>Wähle unten eine der drei Anforderungen aus, um mit der Bewertung anzufangen.</p>
                <div class="d-flex flex-column pt-4">
                    <button class="btn btn-primary mb-3 py-3 btn-after position-relative" (click)="selectType(evaluationType.Betriebssicherheit)">
                        Betriebssicherheitsbewertung
                        <span *ngIf="toDoCount[evaluationType.Betriebssicherheit]" class="btn-count">{{toDoCount[evaluationType.Betriebssicherheit]}}</span>
                    </button>
                    <button class="btn btn-primary mb-3 py-3 btn-after position-relative" (click)="selectType(evaluationType.Dichtheit)">
                        Dichtheitsbewertung
                        <span *ngIf="toDoCount[evaluationType.Dichtheit]" class="btn-count">{{toDoCount[evaluationType.Dichtheit]}}</span>
                    </button>
                    <button class="btn btn-primary mb-3 py-3 btn-after position-relative" (click)="selectType(evaluationType.Standsicherheit)">
                        Standsicherheitsbewertung
                        <span *ngIf="toDoCount[evaluationType.Standsicherheit]" class="btn-count">{{toDoCount[evaluationType.Standsicherheit]}}</span>
                    </button>
                </div>
            </div>
        </div>
        <ng-container *ngIf="selectedType !== undefined && selectedType !== null">
            <div class="card col-12 col-lg-6 mx-auto mt-4 p-0">
                <div *ngIf="!isFinished" class="card-body">
                    <div class="zoom-wrapper-container" #zoomWrapperContainer>
                        <div class="zoom-wrapper position-relative" #zoomWrapper>
                            <button *ngIf="!isPrevious" type="button" class="btn position-absolute button-back" (click)="goPrevious()"><i class="icon-IconArrow-Left"></i></button>
                            <lib-ngx-image-zoom *ngIf="evaluation; else noImage" class="w-100 img" [magnification]="1" [enableScrollZoom]="true" [enableLens]="true"
                                [lensWidth]="100000" [magnification]="1" [lensHeight]="1000" [thumbImage]="evaluation!.imageUrl"
                                [fullImage]="evaluation!.imageUrl"></lib-ngx-image-zoom>
                            <ng-template #noImage>
                                <div class="w-100 img"></div>
                            </ng-template>
                        </div>
                    </div>
        
                    <div class="mx-4" style="margin-top: 0.75rem;">
                        <div class="info-block">
                            <div style="display: inline-block; font-size: 15px;" class="position-relative">
                                <span class="label">Haltung: </span>
                                <b style="width: max-content;">{{evaluation?.objectName}}</b>
                            </div>
                            <span class="float-end label"
                                style="font-size: 14px;">{{evaluation?.order}}/{{evaluation?.maxOrder}}</span>
                            <br>
                            <div style="display: inline-block; font-size: 15px;">
                                <span class="label">{{damageTitle}}</span>
                                <span style="width: max-content;"> {{damageDescription}}</span>
                            </div>
                        </div>
                        <h2 style="margin-top: 1.75rem;" class="text-center">{{evaluationType[selectedType]}}</h2>
                        <textarea [(ngModel)]="commentar" (ngModelChange)="this.commentarValidation.next($event)"
                            maxlength="300" class="mt-3 w-100 ps-3" rows="3" placeholder="Kommentar (optional)"
                            [ngClass]="isInvalid ? 'invalid':''"></textarea>
                        <span class="characters-return" *ngIf="isInvalid"> {{symbolsRemains}} Zeichen übrig</span>
                        <div class="row mt-4">
                            <div class="damage-scale p-0" (touchmove)="trackMobile($event)" (touchend)="touchend()" #damageScale>
                                <div *ngFor="let item of [0, 1, 2, 3, 4, 5]" class="damage-scale-item damage-scale-item-{{item}}" 
                                (mouseup)="sendAnswer(item)" (click)="sendAnswer(item)"
                                >
                                    <p  class="tooltip-custom tooltip-custom-{{item}}" [ngClass]="{'visible': item.toString() === hoverScaleMobile}">{{item}}</p>
                                    <p class="m-0">{{item}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="isFinished" class="card-body" style="height: 80vh">
                    <div class="text-center" style="margin-top: 25%;">
                        <img style="width: 100px;" src="./assets/Images/Done.png">
                        <h3 style="margin-top: 1.75rem; margin-left: -2rem;">Glückwunsch</h3>
                        <small>Es gibt keine neuen Fälle zu prüfen!</small>
                        <br />
                        <button type="button" class="btn btn-primary w-50" style="margin-top: 1.75rem;"
                            (click)="GoHome()">Zurück
                            zur Startseite</button>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
    <ng-template #noData>
        <p class="no-data">Für das ausgewählte Gebiet liegen keine Daten vor</p>
    </ng-template>
</ng-container>