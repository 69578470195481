import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import {Subject} from "rxjs";

const TOKEN_KEY = 'auth-token';
const REFRESHTOKEN_KEY = 'auth-refreshtoken';
const USER_ID = 'auth-user-id';
const USER_NAME = 'username';
const USER_EMAIL = 'userEmail';
const TENANT = 'tenant';
const SOURCE = 'source';
const LOCATION = 'location';
const TENANT_DISPLAY_NAME = 'tenant-display-name';

@Injectable({
  providedIn: 'root',
})
export class TokenService {

  public userNameSubject = new Subject<string>();

  signOut(): void {
    window.localStorage.clear();
  }

  public saveToken(token: string): void {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.setItem(TOKEN_KEY, token);
    const userId = this.getUser();
    const userName = this.getUserName();
    if (userId) {
      this.saveUser(userId, userName);
    }
  }
  public getToken(): string | null {
    return window.localStorage.getItem(TOKEN_KEY);
  }

  public saveRefreshToken(token: string): void {
    window.localStorage.removeItem(REFRESHTOKEN_KEY);
    window.localStorage.setItem(REFRESHTOKEN_KEY, token);
  }

  public getRefreshToken(): string | null {
    return window.localStorage.getItem(REFRESHTOKEN_KEY);
  }

  public saveUser(userId: string, username: string): void {
    window.localStorage.removeItem(USER_ID);
    window.localStorage.setItem(USER_ID, userId);
    window.localStorage.removeItem(USER_NAME);
    window.localStorage.setItem(USER_NAME, username);
    this.userNameSubject.next(username);
  }

  public SetTenant(
    tenant: string,
    source: string,
    location: string,
    displayName: string
  ): void {
    window.localStorage.removeItem(TENANT);
    window.localStorage.setItem(TENANT, tenant);
    window.localStorage.removeItem(SOURCE);
    window.localStorage.setItem(SOURCE, source);
    window.localStorage.removeItem(LOCATION);
    window.localStorage.setItem(LOCATION, location);
    window.localStorage.removeItem(TENANT_DISPLAY_NAME);
    window.localStorage.setItem(TENANT_DISPLAY_NAME, displayName);
  }

  public GetTenant(): any {
    const tenant = window.localStorage.getItem(TENANT);
    if (tenant) {
      return tenant;
    }
    return null;
  }

  public GetTenantDisplayName(): any {
    const name = window.localStorage.getItem(TENANT_DISPLAY_NAME);
    if (name) {
      return name;
    }
    return null;
  }

  public GetSource(): any {
    const source = window.localStorage.getItem(SOURCE);
    if (source) {
      return source;
    }
    return null;
  }

  public GetTenantLocation(): any {
    const location = window.localStorage.getItem(LOCATION);
    if (location) {
      return location;
    }
    return null;
  }

  public getUser(): any {
    const userId = window.localStorage.getItem(USER_ID);
    if (userId) {
      return userId;
    }
    const decode: any = jwt_decode(localStorage.getItem(TOKEN_KEY)!);
    return decode?.id ? decode.id : {};
  }

  public getUserName(): any {
    const username = window.localStorage.getItem(USER_NAME);
    this.userNameSubject.next(username!);
    if (username) {
      return username;
    }

    return {};
  }

  getUserEmail(): string {
    const userEmail = window.localStorage.getItem(USER_EMAIL);
    return userEmail ? userEmail : '';
  }
}
