import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { TokenService } from '../../_services/token.service';
import { environment } from '../../../environments/environment';
import {
  ADMIN_ROLE,
  CONSTRUCTION_SITE_ROLE,
  DATA_EXPORT_ROLE,
  MAPS_ROLE,
  QUESTIONNAIRE_ROLE,
  SEWER_DASHBOARD_ROLE,
  SEWER_TINDER_ROLE,
  SEWER_TINDER_v2_ROLE,
  SEWER_TINDER_v3_ROLE,
  UPLOAD_ROLE,
} from '../../_helpers/Constants';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Tenant } from '../../_models/tenant';
import { TenantService } from '../../_services/tenant.service';
import { CURRENT_SESSION_TENANT } from '../../pages/home/home.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  @Input() isExpanded: boolean = false;
  @Output() toggleSidebar: EventEmitter<boolean> = new EventEmitter<boolean>();

  handleSidebarToggle = () => this.toggleSidebar.emit(!this.isExpanded);
  isAdminView = false;
  isAdminRole = false;
  isUploadsRole = false;
  isSewerDashboardRole = false;
  isSewerTinderRole = false;
  isSewerTinderV2Role = false;
  isSewerTinderV3Role = false;
  isQuestionnairedRole = false;
  isConstructiondRole = false;
  isDataExportRole = false;
  isMapsRole = false;
  tenants: Tenant[] = [];
  curTenant: Tenant | undefined;
  isLoading = true;
  isMobile = false;
  isTablet = false;
  userName = '';
  userEmail = '';
  pop: any;

  constructor(
    public router: Router,
    private tokenService: TokenService,
    private deviceService: DeviceDetectorService,
    private tenantService: TenantService
  ) {}

  ngOnInit() {
    const token = this.tokenService.getToken();
    if (token != null) {
      const decoded: any = jwt_decode(token);
      const roles = decoded[environment.roleKey];
      if (roles) {
        this.isAdminRole = roles.includes(ADMIN_ROLE);
        this.isUploadsRole = roles.includes(UPLOAD_ROLE);
        this.isSewerTinderRole = roles.includes(SEWER_TINDER_ROLE);
        this.isSewerTinderV2Role = roles.includes(SEWER_TINDER_v2_ROLE);
        this.isSewerTinderV3Role = roles.includes(SEWER_TINDER_v3_ROLE);
        this.isSewerDashboardRole = roles.includes(SEWER_DASHBOARD_ROLE);
        this.isQuestionnairedRole = roles.includes(QUESTIONNAIRE_ROLE);
        this.isConstructiondRole = roles.includes(CONSTRUCTION_SITE_ROLE);
        this.isMapsRole = roles.includes(MAPS_ROLE);
        this.isDataExportRole = roles.includes(DATA_EXPORT_ROLE);
      }
    }
    this.isAdminView = this.router.url.includes('/admin');
    this.tenantService.GetTenantById().subscribe((data) => {
      this.tenants = data.sort( (a, b) => {
        const displayNameA = a.displayName.toUpperCase();
        const displayNameB = b.displayName.toUpperCase();
        if (displayNameA < displayNameB) {
          return -1;
        }
        if (displayNameA > displayNameB) {
          return 1;
        }
        return 0;
      });
      const sessionTenant = window.sessionStorage.getItem(
        CURRENT_SESSION_TENANT
      );
      const savedTenant = this.tokenService.GetTenant();
      if (
        sessionTenant != null &&
        data.some((t) => t.tenantName == sessionTenant)
      ) {
        this.curTenant = data.find((t) => t.tenantName == sessionTenant);
        this.tokenService.SetTenant(
          this.curTenant!.tenantName,
          this.curTenant!.serverName,
          this.curTenant!.location,
          this.curTenant!.displayName
        );
      } else {
        if (savedTenant == null) {
          this.curTenant = data[0];
          this.tokenService.SetTenant(
            this.curTenant.tenantName,
            data[0].serverName,
            data[0].location,
            data[0].displayName
          );
        } else {
          this.curTenant = data.find((t) => t.tenantName == savedTenant);
          this.tokenService.SetTenant(
            this.curTenant!.tenantName,
            this.curTenant!.serverName,
            this.curTenant!.location,
            this.curTenant!.displayName
          );
        }
      }
      this.isLoading = false;
    });
    this.tokenService.userNameSubject.subscribe((data) => {
      this.userName = data;
    });
    this.userEmail = this.tokenService.getUserEmail();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
  }

  SetTenant(tenant: Tenant) {
    this.curTenant = tenant;
    const result = this.tenants.find((t) => t.tenantName == tenant.tenantName);
    this.tokenService.SetTenant(
      this.curTenant.tenantName,
      result!.serverName,
      result!.location,
      result!.displayName
    );
    window.sessionStorage.setItem(
      CURRENT_SESSION_TENANT,
      this.curTenant!.tenantName
    );
    window.location.reload();
  }

  GoToAdminDashboard() {
    this.isAdminView = !this.isAdminView;
    this.router.navigateByUrl(this.isAdminView ? '/admin' : '/');
  }

  goHome() {
    this.router.navigateByUrl('');
  }
  closeMenu() {
    if (this.isExpanded && this.deviceService.isMobile()) {
      this.handleSidebarToggle();
    }
  }
  logout(): void {
    this.tokenService.signOut();
    this.router.navigateByUrl('login').then(() => {
      window.location.reload();
    });
  }

  manageAccount() {
    this.router.navigateByUrl('settings/profile');
  }
}
