import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { NotificationIconsType, NotificationNavigateType, NotificationType } from 'src/app/_models/notification.enum';
import {TenantInnerService} from "../../_services/tenant-inner.service";
import {TokenService} from "../../_services/token.service";

@Component({
  selector: 'app-notifications-dashboard',
  templateUrl: './notifications-dashboard.component.html',
  styleUrls: ['./notifications-dashboard.component.scss'],
})
export class NotificationsDashboardComponent {
  totalUnRead = 0;
  notifications: any[] = [];
  notificationType = NotificationType;
  notificationIconsType = NotificationIconsType;

  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private tenantInnerService: TenantInnerService,
    private tokenStorageService: TokenService
  ) {
    this.getNotifications();
  }

  getNotifications(): void {
    const notificationsSize = 6;
    this.notificationService.getNotifications(0, notificationsSize, true).subscribe(res => {
      this.totalUnRead = res.totalUnreadCount;
      this.notifications = res.notifications;
    });
  }

  navigate(notification: any): void {
    this.readNotifications(notification.id);
    if (notification.linkToUrl != null && notification.linkToUrl != "") {
      const sourceAndTenant = notification.linkToUrl.split(' - ');
      this.tenantInnerService.switchTenantByName(
        sourceAndTenant[1],
        sourceAndTenant[0],
        NotificationNavigateType[notification.type]
      );
    } else {
      this.router.navigate([NotificationNavigateType[notification.type]]);
    }
  }

  readNotifications(id: string, e?: MouseEvent): void {
    this.notificationService.readNotifications(id, false).subscribe(() => {
      if (e) {
        this.getNotifications();
      }
    });
    e?.stopPropagation();
  }

  isCurrentTenant(link: string){
    if(link == null || link == ''){
      return false;
    }
    const sourceAndTenant = link.split(' - ');
    const curTenant = this.tokenStorageService.GetTenant();
    const curServer = this.tokenStorageService.GetSource();
    return !(sourceAndTenant[0] === curServer && sourceAndTenant[1] === curTenant);
  }

  getTenantByLink(link: string){
    const sourceAndTenant = link.split(' - ');
    return sourceAndTenant[1];
  }
}
